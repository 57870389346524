import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'types'
import { Helmet } from 'react-helmet'

export const Head = () => {
    const theme = useSelector((state: AppState) => state.theme)
    const favicon = theme === 'proudNerds' ? 'favicon-proud-nerds.png' : 'favicon-ajat.png'
    const title = theme === 'proudNerds' ? 'Manager' : 'AJAT manager'

    return (
        <Helmet>
            <title>{title}</title>
            <link key="title" rel="icon" href={favicon} />
        </Helmet>
    )
}
