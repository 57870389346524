import {
    Box,
    Divider,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material'
import { FieldProps } from '@rjsf/utils/dist'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslate } from 'react-admin'
import CheckboxTree, { Node } from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { allowedChildren } from 'utils/Schema'
import MentionsField from 'resources/personalizationRule/common/MentionsField'
import { formatPath } from './utils'
import { PersonalizationAvailableMentionsTypes } from 'apollo/types.generated'

export interface PathSelectorProps {
    parsedConfiguratorModelTreeStructure: Node[] | null
    checked: string[]
    setChecked: Dispatch<SetStateAction<string[]>>
    setCheckedAttributes: Dispatch<SetStateAction<string[]>>
    props: FieldProps
    modelNodeType: string | undefined
    setModelNodeType: Dispatch<SetStateAction<string | undefined>>
    activeTab: number
    setActiveTab: Dispatch<SetStateAction<number>>
}

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            key={index}
            {...other}
        >
            {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
        </div>
    )
}

export const PathSelector: React.FC<PathSelectorProps> = ({
    parsedConfiguratorModelTreeStructure,
    checked,
    setChecked,
    setCheckedAttributes,
    props,
    modelNodeType,
    setModelNodeType,
    activeTab,
    setActiveTab
}) => {
    const translate = useTranslate()
    const [expanded, setExpanded] = useState<string[]>([])

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }

    const handleChange = (value: string) => {
        setChecked(new Array(value))
        props.onChange(value)
        setCheckedAttributes([])
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setModelNodeType(event.target.value as string)
    }

    const onCheck = (value: string) => {
        setChecked(new Array(value))
        props.onChange(value)
        setCheckedAttributes([])
    }

    const onExpand = (expanded) => {
        setExpanded(expanded)
    }

    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h6">
                    {translate('manager.resources.rule.actions_tab.path_tree.node_path_title')}
                </Typography>

                <Divider sx={{ mt: 1 }} />

                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab
                        label={translate(
                            'manager.resources.rule.actions_tab.path_tree.tab_title_tree'
                        )}
                    />
                    <Tab
                        label={translate(
                            'manager.resources.rule.actions_tab.path_tree.tab_title_input'
                        )}
                    />
                </Tabs>

                <TabPanel value={activeTab} index={0}>
                    <CheckboxTree
                        showNodeIcon={false}
                        showExpandAll={true}
                        noCascade={true}
                        checkModel="all"
                        nodes={parsedConfiguratorModelTreeStructure ?? []}
                        checked={checked}
                        expanded={expanded}
                        onCheck={(_, node) => onCheck(node?.value)}
                        onExpand={onExpand}
                    />
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                    <Stack direction="column">
                        <MentionsField
                            {...props}
                            value={formatPath(props.value)?.[0]}
                            onChange={handleChange}
                            placeholder={translate(
                                'manager.resources.rule.actions_tab.path_tree.path_hint'
                            )}
                            variables={{
                                type: PersonalizationAvailableMentionsTypes.All,
                                withEntity: true
                            }}
                        />

                        <Stack sx={{ width: '100%', my: 3 }}>
                            <InputLabel id="type-select" sx={{ width: '100%', mb: 1 }}>
                                Type
                            </InputLabel>
                            <Select
                                id="type-select"
                                value={modelNodeType}
                                label="Type"
                                onChange={handleSelectChange}
                            >
                                {Object.keys(allowedChildren)?.map((option) => (
                                    <MenuItem value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </Stack>
                </TabPanel>
            </Box>

            {/* PATH SUMMARY */}
            <Box sx={{ mt: 2 }}>
                <Divider />

                <Typography variant="caption">
                    <strong>
                        {translate('manager.resources.rule.actions_tab.path_tree.path_hint')}:
                    </strong>

                    {checked[checked.length - 1]}
                </Typography>
            </Box>
        </>
    )
}
