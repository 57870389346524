import { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { Node } from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { FieldProps } from '@rjsf/utils/dist'
import { Grid, LinearProgress, Alert, Typography, Divider } from '@mui/material'
import { FormSpy, useForm } from 'react-final-form'

import { useConfiguratorModelTreeStructureLazyQuery } from 'apollo/package_manager/queries/ConfiguratorModelTreeStructure.generated'
import { useConfiguratorGetAttributesTreeByModelNodeLazyQuery } from 'apollo/package_manager/queries/ConfiguratorGetAttributesTreeByModelNode.generated'
import { useConfiguratorGetAttributesTreeByModelNodeTypeLazyQuery } from 'apollo/package_manager/queries/ConfiguratorGetAttributesTreeByModelNodeType.generated'

import { PathSelector } from './PathSelector'
import { AttributeSelector } from './AttributeSelector'
import { formatAttribute, formatPath } from './utils'

const PathTree = (props: FieldProps) => {
    const translate = useTranslate()
    const { getState } = useForm()
    const [selectedRootModelCode, setSelectedRootModelCode] = useState<string>('')
    const [selectedPresetCode, setSelectedPresetCode] = useState<string>('')
    const [checked, setChecked] = useState<string[]>([props.value ?? '$'])
    const [checkedAttributes, setCheckedAttributes] = useState<string[]>([])
    const [modelNodeType, setModelNodeType] = useState<string | undefined>()
    const [activeTab, setActiveTab] = useState(0)
    const [expandedAttributes, setExpandedAttributes] = useState<string[]>([])
    const [parsedConfiguratorModelTreeStructure, setParsedConfiguratorModelTreeStructure] =
        useState<Node[] | null>([])
    const [parsedAttributesTreeDataTreeStructure, setParsedAttributesTreeDataTreeStructure] =
        useState<Node[]>([])
    const [
        parsedAttributesTreeDataTreeStructureByType,
        setParsedAttributesTreeDataTreeStructureByType
    ] = useState<Node[]>([])

    /*
     * Query's
     */
    const [getConfiguratorModelTreeStructure, { loading: isTreeStructureLoading }] =
        useConfiguratorModelTreeStructureLazyQuery({
            onCompleted: (data) => {
                if (data?.configuratorModelTreeStructure)
                    setParsedConfiguratorModelTreeStructure(
                        JSON.parse(data.configuratorModelTreeStructure)
                    )
            },
            onError: () => {
                setParsedConfiguratorModelTreeStructure([])
            }
        })

    const [getConfiguratorGetAttributesTreeByModelNode] =
        useConfiguratorGetAttributesTreeByModelNodeLazyQuery({
            onCompleted: (data) => {
                if (data?.configuratorGetAttributesTreeByModelNode)
                    setParsedAttributesTreeDataTreeStructure(
                        JSON.parse(data?.configuratorGetAttributesTreeByModelNode)
                    )
            },
            onError: () => {
                setParsedAttributesTreeDataTreeStructure([])
            }
        })

    const [getConfiguratorGetAttributesTreeByModelNodeType] =
        useConfiguratorGetAttributesTreeByModelNodeTypeLazyQuery({
            onCompleted: (data) => {
                if (data?.configuratorGetAttributesTreeByModelNodeType)
                    setParsedAttributesTreeDataTreeStructureByType(
                        JSON.parse(data?.configuratorGetAttributesTreeByModelNodeType)
                    )
            },
            onError: () => {
                setParsedAttributesTreeDataTreeStructureByType([])
            }
        })

    /*
     * UseEffect
     */
    useEffect(() => {
        if (props.value) {
            const path = formatPath(props.value)
            setChecked(path)

            const attribute = formatAttribute(props.value)

            if (attribute) {
                const attributesPath = '$' + attribute.replaceAll('.', ':')
                setCheckedAttributes(new Array(attributesPath))
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!!selectedRootModelCode) {
            getConfiguratorModelTreeStructure({
                variables: { code: selectedRootModelCode }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRootModelCode])

    useEffect(() => {
        if (!!selectedPresetCode || !!selectedRootModelCode) {
            getConfiguratorGetAttributesTreeByModelNode({
                variables: {
                    model: selectedPresetCode ?? selectedRootModelCode,
                    modelNode: checked[checked.length - 1]
                }
            })
        }

        if (modelNodeType) {
            getConfiguratorGetAttributesTreeByModelNodeType({
                variables: {
                    modelNodeType
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked, modelNodeType])

    const onCheckAttributes = (_, targetNode) => {
        const formValue = checked
            ? `${checked?.[0]}${formatAttributeOld(targetNode.value)}`
            : '$' + formatAttributeOld(targetNode.value)
        props.onChange(formValue)
        setCheckedAttributes(new Array(targetNode.value))
    }

    const onExpandAttributes = (expanded) => {
        setExpandedAttributes(expanded)
    }

    const formatAttributeOld = (attributesPath: string) =>
        attributesPath.substring(1).replaceAll(':', '.')

    const handleChange = () => {
        let actionBlock: number = 0
        const actions = JSON.parse(getState().values.actions).actions

        actions.forEach((action, index) => {
            action?.variable_replace?.overrides?.forEach((override) => {
                if (override.path === props.value) actionBlock = index
            })
        })

        if (actions?.[actionBlock] && actions?.[actionBlock]?.variable_replace?.presets) {
            const field = actions[actionBlock].variable_replace.presets[0]
            !!field ? setSelectedRootModelCode(field.item.model) : setSelectedRootModelCode('')
            !!field && field.item && field.item.presets
                ? setSelectedPresetCode(field.item.presets[0])
                : setSelectedPresetCode('')
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                {/* PATH SELECTOR */}
                <Grid
                    item
                    xl={6}
                    xs={12}
                    justifyContent="space-between"
                    direction="column"
                    display="flex"
                >
                    {isTreeStructureLoading ? (
                        <LinearProgress />
                    ) : selectedRootModelCode ? (
                        <PathSelector
                            parsedConfiguratorModelTreeStructure={
                                parsedConfiguratorModelTreeStructure
                            }
                            checked={checked}
                            setChecked={setChecked}
                            setCheckedAttributes={setCheckedAttributes}
                            props={props}
                            modelNodeType={modelNodeType}
                            setModelNodeType={setModelNodeType}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                    ) : (
                        <Alert severity="error">
                            {translate(
                                'manager.resources.rule.actions_tab.path_tree.node_path_alert'
                            )}
                        </Alert>
                    )}
                </Grid>

                {/* ATTRIBUTE SELECTOR */}
                <Grid
                    item
                    xl={6}
                    xs={12}
                    justifyContent="space-between"
                    direction="column"
                    display="flex"
                >
                    {selectedRootModelCode ? (
                        <AttributeSelector
                            parsedAttributesTreeDataTreeStructure={
                                activeTab === 0
                                    ? parsedAttributesTreeDataTreeStructure
                                    : activeTab === 1
                                    ? parsedAttributesTreeDataTreeStructureByType
                                    : []
                            }
                            checkedAttributes={checkedAttributes}
                            expandedAttributes={expandedAttributes}
                            onCheckAttributes={onCheckAttributes}
                            onExpandAttributes={onExpandAttributes}
                        />
                    ) : (
                        <Alert severity="error">
                            {translate(
                                'manager.resources.rule.actions_tab.path_tree.attribute_path_alert'
                            )}
                        </Alert>
                    )}
                </Grid>
            </Grid>

            <FormSpy onChange={handleChange} />

            <Grid
                item
                xs={12}
                justifyContent="space-between"
                direction="column"
                display="flex"
                sx={{ mt: 6 }}
            >
                <Typography variant="h6">
                    {translate('manager.resources.rule.actions_tab.path_tree.value_override_title')}
                </Typography>

                <Divider sx={{ mt: 1 }} />
            </Grid>
        </>
    )
}

export default PathTree
