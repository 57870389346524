import { useState } from 'react'
import { Create, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import PresetForm from './PresetForm'

const PresetCreate = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <div>
            <BackFromEditorButton isPristineForm={isPristineForm} />
            <Create title={translate('manager.resources.preset.create_a_preset')} {...props}>
                <PresetForm
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Create>
        </div>
    )
}

export default PresetCreate
