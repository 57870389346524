import { useState } from 'react'
import { Edit, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import SchoolForm from './SchoolForm'

const SchoolEdit = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} backToRouteURL="/school" />
            <Edit title={translate('manager.resources.school.edit')} {...props}>
                <SchoolForm
                    isEdit
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                    {...props}
                />
            </Edit>
        </>
    )
}

export default SchoolEdit
