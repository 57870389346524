import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    TextField,
    useTranslate,
    CreateButton,
    ExportButton,
    FilterButton,
    TopToolbar,
    Button,
    sanitizeListRestProps,
    Pagination
} from 'react-admin'
import { useState } from 'react'
import { Record, useNotify } from 'ra-core'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import styled from 'styled-components'

import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globals'
import datagridStyles from '../../layout/styles/datagridStyles'

import SchoolClassRepresentativeListFilter from './SchoolClassRepresentativeListFilter'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import BulkActionButtons from 'components/common/BulkActionButtons'
import UploadDialog from 'components/common/UploadDialog'
import { useSchoolsImportSchoolClassRepresentativesMutation } from '../../apollo/upload/mutations/SchoolsImportSchoolClassRepresentatives.generated'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'

const useStyles = makeStyles({
    chip: { margin: '2px' }
})

const ListActions = (props) => {
    const t = useTranslate()
    const { className, maxResults, forceUpdate, ...rest } = props
    const [openDialog, setOpenDialog] = useState(false)
    const [uploadSchoolClassRepresentatives] = useSchoolsImportSchoolClassRepresentativesMutation()
    const notify = useNotify()

    const handleUpload = (files) => {
        if (files?.[0]) {
            uploadSchoolClassRepresentatives({
                variables: {
                    file: files?.[0]
                }
            })
                .then(() => {
                    setOpenDialog(false)
                    forceUpdate()
                })
                .catch((error) => notify(error.message, 'error'))
        }
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />
            <CreateButton />
            <ExportButton />
            <Button
                onClick={() => {
                    setOpenDialog(true)
                }}
                label={t('manager.resources.schoolClassRepresentatives.upload')}
            >
                <CloudUploadIcon />
            </Button>
            <UploadDialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                acceptedFiles={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ]}
                handleSave={handleUpload}
                filesLimit={1}
            />
        </TopToolbar>
    )
}
const SchoolClassRepresentativeList = (props: ListProps) => {
    const translate = useTranslate()
    const classes = useStyles()
    const datagridClasses = datagridStyles()
    const RESOURCE = 'SchoolClassRepresentative'

    const forceUpdate = () => window.location.reload()

    return (
        <ListWrapper>
            <List
                basePath={`/${RESOURCE}`}
                resource={RESOURCE}
                sort={{ field: 'name', order: 'ASC' }}
                empty={false}
                perPage={DEFAULT_PER_PAGE}
                bulkActionButtons={<BulkActionButtons />}
                filters={SchoolClassRepresentativeListFilter}
                actions={<ListActions forceUpdate={forceUpdate} />}
                pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
            >
                <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                    <TextField
                        source="name"
                        label={translate('manager.resources.contactPersons.name')}
                    />
                    <TextField
                        source="email"
                        label={translate('manager.resources.contactPersons.email')}
                    />
                    <TextField
                        source="ssn"
                        label={translate('manager.resources.contactPersons.ssn')}
                    />
                    <TextField
                        source="telephone"
                        label={translate('manager.resources.contactPersons.phone')}
                    />
                    <FunctionField
                        label={translate('manager.resources.contactPersons.schools')}
                        render={(record?: Record | undefined) => {
                            return record?.schools && record.schools.length > 0 ? (
                                record.schools.map((school) => (
                                    <Chip
                                        size="small"
                                        label={school.name}
                                        className={classes.chip}
                                    />
                                ))
                            ) : (
                                <> — </>
                            )
                        }}
                    />
                    <FunctionField
                        label={translate('manager.resources.contactPersons.classes')}
                        render={(record?: Record | undefined) => {
                            return record?.classes && record.classes.length > 0 ? (
                                record.classes.map((_classes) => (
                                    <Chip
                                        size="small"
                                        label={_classes.name}
                                        className={classes.chip}
                                    />
                                ))
                            ) : (
                                <> — </>
                            )
                        }}
                    />
                    <TextField
                        source="discount_amount"
                        label={translate('manager.resources.contactPersons.discount_amount')}
                    />
                    <TextField
                        source="discount_percentage"
                        label={translate('manager.resources.contactPersons.discount_percentage')}
                    />
                    <TextField
                        source="discount_used"
                        label={translate('manager.resources.school.representative_discount_used')}
                    />
                    <DateFieldCustom
                        label={translate('manager.resources.general.last_updated')}
                        source="updated_at"
                        showTime
                        transform={(value) => formatDateString(value, true)}
                    />
                </Datagrid>
            </List>
        </ListWrapper>
    )
}

const ListWrapper = styled.section`
    & .MuiToolbar-root {
        > form {
            flex-wrap: initial;
        }
        > div.MuiToolbar-root {
            width: auto;
        }
    }
`
export default SchoolClassRepresentativeList
