import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'

import { ChoiceType } from 'types'
import TagsAutocompleteInput from 'components/formfields/TagsAutocompleteInput'
import { useCreateSchoolTagMutation } from 'apollo/school_manager/mutations/CreateSchoolTag.generated'
import { useSchoolsSchoolQuery } from 'apollo/school_manager/queries/SchoolsSchool.generated'
import { useSchoolsSchoolTagsQuery } from 'apollo/school_manager/queries/SchoolsSchoolTags.generated'

/*
 * Wrapper component to provide the actual autocomplete input with data and functionality \
 */
const SchoolTagsInput = ({ schoolId }) => {
    const translate = useTranslate()
    const [initialValues, setInitialValues] = useState<ChoiceType[]>([])
    const [initialChoices, setInitialChoices] = useState<ChoiceType[]>([])
    const [createSchoolTag] = useCreateSchoolTagMutation()

    // Retreive all tags related to this schoolId
    const { data: schoolTags } = useSchoolsSchoolQuery({
        variables: {
            schoolId: schoolId
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network'
    })

    // Retrieve every possible tag to populate choices in the autocomplete
    const { data: { schoolsSchoolTags } = {} } = useSchoolsSchoolTagsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network'
    })

    // Format and set choices
    useEffect(() => {
        if (schoolsSchoolTags) {
            setInitialChoices(
                schoolsSchoolTags.data.map(
                    (item): ChoiceType => ({
                        id: item.id,
                        name: item.tag
                    })
                )
            )
        }
    }, [schoolsSchoolTags])

    // Format and set values
    useEffect(() => {
        if (schoolTags?.schoolsSchool?.tags) {
            setInitialValues(
                schoolTags.schoolsSchool.tags.map(
                    (item): ChoiceType => ({
                        id: item.id,
                        name: item.tag
                    })
                )
            )
        }
    }, [schoolTags])

    // Prompt user for new tag
    const handleCreate = async () => {
        const newTag = prompt(translate('manager.resources.school.enter_new_tag'))
        if (!newTag) return // Early out

        const found = initialChoices.find((item) => item.name === newTag)
        if (found) return found // Return existing tag

        // Save and return new tag
        const tag = await save(newTag)
        initialChoices && tag && initialChoices.push(tag)
        return tag
    }

    // Save new tag in db
    const save = async (newTag: string) => {
        return await createSchoolTag({
            variables: { tag: newTag }
        })
            .then((res) => {
                return {
                    id: res?.data?.createSchoolTag?.id ?? '',
                    name: res?.data?.createSchoolTag?.tag ?? ''
                }
            })
            .catch((error) => console.error('createSchoolTag:', error))
    }

    return (
        <TagsAutocompleteInput
            source="tags"
            initialValues={initialValues}
            initialChoices={initialChoices}
            onCreate={handleCreate}
        />
    )
}
export default SchoolTagsInput
