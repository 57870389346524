import { BooleanInput, SearchInput } from 'react-admin'

const SchoolClassRepresentativeListFilter = [
    <SearchInput variant="outlined" placeholder="Search name" source="name" alwaysOn />,
    <SearchInput variant="outlined" placeholder="Search email" source="email" />,
    <SearchInput variant="outlined" placeholder="Search ssn" source="ssn" />,
    <SearchInput variant="outlined" placeholder="Search telephone" source="telephone" />,
    <BooleanInput
        variant="outlined"
        placeholder="Discount used?"
        source="discount_used"
        defaultValue={false}
    />
]

export default SchoolClassRepresentativeListFilter
