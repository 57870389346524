import { DatagridBody, DatagridRow } from 'react-admin'
import { linkToRecord } from 'ra-core'

// Handle ctrl click to open new window
const handleClick = (basePath, id) => (e) => {
    const recordPath = linkToRecord(basePath, id)
    if (e.ctrlKey) {
        e.preventDefault()
        window.open(`#${recordPath}`)
    } else {
        window.open(`#${recordPath}`, '_self')
    }
}

const CustomDatagridRow = (props) => (
    <DatagridRow onClick={handleClick(props.basePath, props.id)} {...props} />
)

export const CustomDatagridBody = (props) => <DatagridBody row={<CustomDatagridRow />} {...props} />
