import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type NodeOverrideSchemaQueryVariables = Types.Exact<{
  model: Types.Scalars['String'];
  nodeType: Types.ConfiguratorNodeType;
}>;


export type NodeOverrideSchemaQuery = { __typename?: 'Query', configuratorNodeOverrideSchema?: string | null | undefined };


export const NodeOverrideSchemaDocument = gql`
    query NodeOverrideSchema($model: String!, $nodeType: configuratorNodeType!) {
  configuratorNodeOverrideSchema(model: $model, node_type: $nodeType)
}
    `;

/**
 * __useNodeOverrideSchemaQuery__
 *
 * To run a query within a React component, call `useNodeOverrideSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeOverrideSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeOverrideSchemaQuery({
 *   variables: {
 *      model: // value for 'model'
 *      nodeType: // value for 'nodeType'
 *   },
 * });
 */
export function useNodeOverrideSchemaQuery(baseOptions: Apollo.QueryHookOptions<NodeOverrideSchemaQuery, NodeOverrideSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeOverrideSchemaQuery, NodeOverrideSchemaQueryVariables>(NodeOverrideSchemaDocument, options);
      }
export function useNodeOverrideSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeOverrideSchemaQuery, NodeOverrideSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeOverrideSchemaQuery, NodeOverrideSchemaQueryVariables>(NodeOverrideSchemaDocument, options);
        }
export type NodeOverrideSchemaQueryHookResult = ReturnType<typeof useNodeOverrideSchemaQuery>;
export type NodeOverrideSchemaLazyQueryHookResult = ReturnType<typeof useNodeOverrideSchemaLazyQuery>;
export type NodeOverrideSchemaQueryResult = Apollo.QueryResult<NodeOverrideSchemaQuery, NodeOverrideSchemaQueryVariables>;