import { useState } from 'react'
import { Edit, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'
import PersonalizationRuleForm from './PersonalizationRuleForm'

const PersonalizationRuleEdit = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} backToRouteURL="/rule" />
            <Edit
                title={translate(`manager.resources.${props.resource.toLowerCase()}.edit`)}
                undoable={false}
                {...props}
            >
                <PersonalizationRuleForm
                    isEdit
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Edit>
        </>
    )
}

export default PersonalizationRuleEdit
