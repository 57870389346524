import TextField from '@material-ui/core/TextField'
import { useInput, useTranslate } from 'react-admin'

const TextAreaInput = (props) => {
    const translate = useTranslate()
    const {
        input,
        meta: { touched, error }
    } = useInput(props)

    return (
        <TextField
            {...input}
            style={{ width: '100%' }}
            label={props.label}
            variant="outlined"
            multiline={props.multiline}
            rows={props.rows}
            error={!!(touched && error)}
            helperText={touched && error && translate('manager.required')}
            required={props.required}
        />
    )
}

export default TextAreaInput
