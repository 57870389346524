import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import EducationList from './EducationList'
import EducationEdit from './EducationEdit'
import EducationCreate from './EducationCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: EducationList,
    edit: EducationEdit,
    create: EducationCreate,
    icon: LibraryBooksIcon
}
