import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PackageSchemaQueryVariables = Types.Exact<{
  type: Types.PackagesPackageType;
}>;


export type PackageSchemaQuery = { __typename?: 'Query', packageSchema?: string | null | undefined };


export const PackageSchemaDocument = gql`
    query PackageSchema($type: packagesPackageType!) {
  packageSchema: packagesPackageSchema(type: $type)
}
    `;

/**
 * __usePackageSchemaQuery__
 *
 * To run a query within a React component, call `usePackageSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageSchemaQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function usePackageSchemaQuery(baseOptions: Apollo.QueryHookOptions<PackageSchemaQuery, PackageSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackageSchemaQuery, PackageSchemaQueryVariables>(PackageSchemaDocument, options);
      }
export function usePackageSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackageSchemaQuery, PackageSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackageSchemaQuery, PackageSchemaQueryVariables>(PackageSchemaDocument, options);
        }
export type PackageSchemaQueryHookResult = ReturnType<typeof usePackageSchemaQuery>;
export type PackageSchemaLazyQueryHookResult = ReturnType<typeof usePackageSchemaLazyQuery>;
export type PackageSchemaQueryResult = Apollo.QueryResult<PackageSchemaQuery, PackageSchemaQueryVariables>;