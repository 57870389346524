import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useRolesLazyQuery } from 'apollo/auth0/queries/Roles.generated'
import { CRUD, ResourceInterface, roleDefinition } from './roles'
import _ from 'underscore'

interface ContextProps {
    roles: string[] | null
    permissions: ResourceInterface | null
    isLoading: boolean
}

const ContextDefaults: ContextProps = {
    permissions: null,
    roles: null,
    isLoading: false
}

const Context = React.createContext<ContextProps>(ContextDefaults)

export interface ContextProviderProps {
    children?: React.ReactNode
}

function ContextProvider({ children }: ContextProviderProps) {
    const [roles, setRoles] = useState<string[] | null>(null)
    const [permissions, setPermissions] = useState<ResourceInterface | null>(null)
    const { getAccessTokenSilently, user, isLoading: isAuth0Loading } = useAuth0()

    const [getRoles, { loading: isRolesLoading }] = useRolesLazyQuery({
        onCompleted: ({ roles: rawRoles }) => {
            if (rawRoles) {
                const roleArray = rawRoles?.map((role) => role.name)

                if (roleArray) {
                    setRoles(roleArray)
                    let newPermissions = {}

                    roleArray.map((role) => {
                        for (const resource in roleDefinition?.[role || '']) {
                            let resourcePermissions: CRUD[] | null = null

                            if (newPermissions?.[resource]) {
                                //Merge duplicate resource permissions when a user has multiple roles assigned abd deduplicate permissions
                                resourcePermissions = _.union(
                                    newPermissions?.[resource],
                                    roleDefinition?.[role || ''][resource]
                                )
                            } else {
                                resourcePermissions = roleDefinition?.[role || ''][resource]
                            }

                            newPermissions = {
                                ...newPermissions,
                                [resource]: resourcePermissions
                            }
                        }

                        return true
                    })

                    newPermissions && setPermissions(newPermissions)
                }
            }
        }
    })

    useEffect(() => {
        const getTokens = async (isAuthenticated: boolean) => {
            if (isAuthenticated) {
                const token = await getAccessTokenSilently()
                localStorage.setItem('token', token)
                getRoles({ variables: { userId: user?.sub || '' } })
            }
        }

        if (user) {
            getTokens(!!user)
        } else {
            localStorage.removeItem('token')

            if (!window?.location?.hash?.includes('login')) {
                window.location.hash = '/login'
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const isLoading = isRolesLoading || isAuth0Loading

    return <Context.Provider value={{ roles, isLoading, permissions }}>{children}</Context.Provider>
}

const useContext = () => React.useContext(Context)

export { ContextProvider as AuthProvider, useContext as useAuthProvider }
