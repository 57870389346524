import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PresetNodeQueryVariables = Types.Exact<{
  configuratorPresetNodeId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type PresetNodeQuery = { __typename?: 'Query', configuratorPresetNode?: { __typename?: 'configuratorPresetNode', definition: string, id: string, parent_node_id: number, preset_id: number, removed: boolean } | null | undefined };


export const PresetNodeDocument = gql`
    query PresetNode($configuratorPresetNodeId: ID) {
  configuratorPresetNode(id: $configuratorPresetNodeId) {
    definition
    id
    parent_node_id
    preset_id
    removed
  }
}
    `;

/**
 * __usePresetNodeQuery__
 *
 * To run a query within a React component, call `usePresetNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresetNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresetNodeQuery({
 *   variables: {
 *      configuratorPresetNodeId: // value for 'configuratorPresetNodeId'
 *   },
 * });
 */
export function usePresetNodeQuery(baseOptions?: Apollo.QueryHookOptions<PresetNodeQuery, PresetNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PresetNodeQuery, PresetNodeQueryVariables>(PresetNodeDocument, options);
      }
export function usePresetNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresetNodeQuery, PresetNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PresetNodeQuery, PresetNodeQueryVariables>(PresetNodeDocument, options);
        }
export type PresetNodeQueryHookResult = ReturnType<typeof usePresetNodeQuery>;
export type PresetNodeLazyQueryHookResult = ReturnType<typeof usePresetNodeLazyQuery>;
export type PresetNodeQueryResult = Apollo.QueryResult<PresetNodeQuery, PresetNodeQueryVariables>;