import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateSchoolRepresentativeMutationVariables = Types.Exact<{
  representativeId: Types.Scalars['Int'];
  schoolId: Types.Scalars['Int'];
}>;


export type CreateSchoolRepresentativeMutation = { __typename?: 'Mutation', schoolsCreateSchoolSchoolClassRepresentative?: { __typename?: 'schoolsSchoolSchoolClassRepresentative', id: string, representative_id: number, school_id: number } | null | undefined };


export const CreateSchoolRepresentativeDocument = gql`
    mutation CreateSchoolRepresentative($representativeId: Int!, $schoolId: Int!) {
  schoolsCreateSchoolSchoolClassRepresentative(
    representative_id: $representativeId
    school_id: $schoolId
  ) {
    id
    representative_id
    school_id
  }
}
    `;
export type CreateSchoolRepresentativeMutationFn = Apollo.MutationFunction<CreateSchoolRepresentativeMutation, CreateSchoolRepresentativeMutationVariables>;

/**
 * __useCreateSchoolRepresentativeMutation__
 *
 * To run a mutation, you first call `useCreateSchoolRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolRepresentativeMutation, { data, loading, error }] = useCreateSchoolRepresentativeMutation({
 *   variables: {
 *      representativeId: // value for 'representativeId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useCreateSchoolRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolRepresentativeMutation, CreateSchoolRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolRepresentativeMutation, CreateSchoolRepresentativeMutationVariables>(CreateSchoolRepresentativeDocument, options);
      }
export type CreateSchoolRepresentativeMutationHookResult = ReturnType<typeof useCreateSchoolRepresentativeMutation>;
export type CreateSchoolRepresentativeMutationResult = Apollo.MutationResult<CreateSchoolRepresentativeMutation>;
export type CreateSchoolRepresentativeMutationOptions = Apollo.BaseMutationOptions<CreateSchoolRepresentativeMutation, CreateSchoolRepresentativeMutationVariables>;