import { useState } from 'react'
import { BulkDeleteButton, Button, useTranslate, useGetMany } from 'react-admin'
import {
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core'

// Icons
import IconButton from '@mui/material/IconButton'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'

// GrapQL
import { usePresetExportModelMutation } from 'apollo/configurator/mutations/PresetExportModel.generated'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'
interface PresetList {
    internalName: string
    status: boolean
}

// Bulk delete with Confirm
const PresetBulkActionButtons = (props) => {
    const translate = useTranslate()
    const [presetExportModelMutation] = usePresetExportModelMutation()
    const [dialogKey, setDialogKey] = useState<number>()
    const [open, setOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [list, setList] = useState<PresetList[]>()
    const { selectedIds } = props

    const [flushActionsCache] = useFlushActionsCacheMutation()

    // Fetch all presets based on selectedIds in bulkAction
    const { data: selectedPresets } = useGetMany('Preset', selectedIds)

    // Close dialog and reset
    const handleClose = () => {
        setOpen(false)
        setList([])
        setProcessing(false)
    }

    // To handle deprecated disableBackdropClick on <Dialog />
    const handleDialogClose = (_event: {}, reason: string) => {
        // Disable backdropClick
        if (reason === 'backdropClick') return false
        handleClose()
    }

    const handleExport = async () => {
        setOpen(true)
        setProcessing(true)
        const exportListResult: PresetList[] = []

        // Loop selected presets
        selectedPresets.forEach((preset, index) => {
            const presetId = parseInt(`${preset.id}`)
            const configuratorExportModelId = preset.parent_model_id

            if (configuratorExportModelId && presetId) {
                presetExportModelMutation({
                    variables: {
                        configuratorExportModelId: configuratorExportModelId,
                        preset: presetId
                    }
                }).then((res) => {
                    exportListResult.push({
                        internalName: preset?.internal_name,
                        status: !!res?.data?.configuratorExportModel?.code
                    })
                    setList(exportListResult)
                    setDialogKey(index) // For state update
                })
            }
        })
    }

    // Dialog
    const ExportResultDialog = () => {
        return (
            <Dialog
                onClose={(event, reason) => handleDialogClose(event, reason)}
                open={open}
                key={dialogKey}
                disableEscapeKeyDown
            >
                <IconButton
                    disabled={processing}
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <List>
                        <ListItem dense>
                            <ListItemText primary={'Status'} />
                        </ListItem>
                        <Divider />
                        {processing && <CircularProgress />}
                        {!processing &&
                            list &&
                            list.map((preset) => {
                                return (
                                    <ListItem dense>
                                        <ListItemIcon>
                                            {preset.status ? (
                                                <CheckBoxIcon color="success" />
                                            ) : (
                                                <ClearIcon color="error" />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText primary={preset?.internalName} />
                                    </ListItem>
                                )
                            })}
                    </List>
                </DialogContent>
            </Dialog>
        )
    }

    const handlePublishButtonClick = () => {
        handleExport().then(() => {
            setProcessing(false)
        })
        if (props.isWithActionsFlush) flushActionsCache()
    }

    const handleDeleteButtonClick = () =>
        props.isWithActionsFlush ? flushActionsCache() : undefined

    return (
        <>
            <Button
                onClick={handlePublishButtonClick}
                label={translate('manager.resources.preset.publish')}
            >
                <CloudUploadIcon />
            </Button>
            <BulkDeleteButton undoable={false} onClick={handleDeleteButtonClick} {...props} />

            <ExportResultDialog />
        </>
    )
}
export default PresetBulkActionButtons
