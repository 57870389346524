import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, DeleteButton, SaveButton } from 'react-admin'

const useToolbarStyles = makeStyles({
    toolbar: {
        justifyContent: 'space-between'
    }
})

const SchoolPeriodEditToolbar = (props: any) => {
    const classes = useToolbarStyles()

    return (
        <Toolbar {...props} className={classes.toolbar}>
            <SaveButton />
            <DeleteButton redirect={`/School/${props.record?.school?.id}/periods`} />
        </Toolbar>
    )
}

export default SchoolPeriodEditToolbar
