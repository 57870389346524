import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PackagesDeletePackageGroupPackageMutationVariables = Types.Exact<{
  groupId?: Types.InputMaybe<Types.Scalars['Int']>;
  packageId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type PackagesDeletePackageGroupPackageMutation = { __typename?: 'Mutation', packagesDeletePackageGroupPackage?: boolean | null | undefined };


export const PackagesDeletePackageGroupPackageDocument = gql`
    mutation PackagesDeletePackageGroupPackage($groupId: Int, $packageId: Int) {
  packagesDeletePackageGroupPackage(group_id: $groupId, package_id: $packageId)
}
    `;
export type PackagesDeletePackageGroupPackageMutationFn = Apollo.MutationFunction<PackagesDeletePackageGroupPackageMutation, PackagesDeletePackageGroupPackageMutationVariables>;

/**
 * __usePackagesDeletePackageGroupPackageMutation__
 *
 * To run a mutation, you first call `usePackagesDeletePackageGroupPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackagesDeletePackageGroupPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packagesDeletePackageGroupPackageMutation, { data, loading, error }] = usePackagesDeletePackageGroupPackageMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function usePackagesDeletePackageGroupPackageMutation(baseOptions?: Apollo.MutationHookOptions<PackagesDeletePackageGroupPackageMutation, PackagesDeletePackageGroupPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PackagesDeletePackageGroupPackageMutation, PackagesDeletePackageGroupPackageMutationVariables>(PackagesDeletePackageGroupPackageDocument, options);
      }
export type PackagesDeletePackageGroupPackageMutationHookResult = ReturnType<typeof usePackagesDeletePackageGroupPackageMutation>;
export type PackagesDeletePackageGroupPackageMutationResult = Apollo.MutationResult<PackagesDeletePackageGroupPackageMutation>;
export type PackagesDeletePackageGroupPackageMutationOptions = Apollo.BaseMutationOptions<PackagesDeletePackageGroupPackageMutation, PackagesDeletePackageGroupPackageMutationVariables>;