import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ExportModelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  upgradeType?: Types.InputMaybe<Types.ConfiguratorSemanticVersion>;
}>;


export type ExportModelMutation = { __typename?: 'Mutation', configuratorExportModel?: { __typename?: 'configuratorModel', code: string, body?: string | null | undefined, version?: string | null | undefined } | null | undefined };


export const ExportModelDocument = gql`
    mutation ExportModel($id: ID!, $upgradeType: configuratorSemanticVersion) {
  configuratorExportModel(id: $id, upgrade_type: $upgradeType) {
    code
    body
    version
  }
}
    `;
export type ExportModelMutationFn = Apollo.MutationFunction<ExportModelMutation, ExportModelMutationVariables>;

/**
 * __useExportModelMutation__
 *
 * To run a mutation, you first call `useExportModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportModelMutation, { data, loading, error }] = useExportModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      upgradeType: // value for 'upgradeType'
 *   },
 * });
 */
export function useExportModelMutation(baseOptions?: Apollo.MutationHookOptions<ExportModelMutation, ExportModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportModelMutation, ExportModelMutationVariables>(ExportModelDocument, options);
      }
export type ExportModelMutationHookResult = ReturnType<typeof useExportModelMutation>;
export type ExportModelMutationResult = Apollo.MutationResult<ExportModelMutation>;
export type ExportModelMutationOptions = Apollo.BaseMutationOptions<ExportModelMutation, ExportModelMutationVariables>;