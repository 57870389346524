import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsExtensionAttributesSchemaQueryVariables = Types.Exact<{
  entityType: Types.Scalars['String'];
}>;


export type SchoolsExtensionAttributesSchemaQuery = { __typename?: 'Query', schoolsExtensionAttributesSchema?: string | null | undefined };


export const SchoolsExtensionAttributesSchemaDocument = gql`
    query SchoolsExtensionAttributesSchema($entityType: String!) {
  schoolsExtensionAttributesSchema(entity_type: $entityType)
}
    `;

/**
 * __useSchoolsExtensionAttributesSchemaQuery__
 *
 * To run a query within a React component, call `useSchoolsExtensionAttributesSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsExtensionAttributesSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsExtensionAttributesSchemaQuery({
 *   variables: {
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useSchoolsExtensionAttributesSchemaQuery(baseOptions: Apollo.QueryHookOptions<SchoolsExtensionAttributesSchemaQuery, SchoolsExtensionAttributesSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsExtensionAttributesSchemaQuery, SchoolsExtensionAttributesSchemaQueryVariables>(SchoolsExtensionAttributesSchemaDocument, options);
      }
export function useSchoolsExtensionAttributesSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsExtensionAttributesSchemaQuery, SchoolsExtensionAttributesSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsExtensionAttributesSchemaQuery, SchoolsExtensionAttributesSchemaQueryVariables>(SchoolsExtensionAttributesSchemaDocument, options);
        }
export type SchoolsExtensionAttributesSchemaQueryHookResult = ReturnType<typeof useSchoolsExtensionAttributesSchemaQuery>;
export type SchoolsExtensionAttributesSchemaLazyQueryHookResult = ReturnType<typeof useSchoolsExtensionAttributesSchemaLazyQuery>;
export type SchoolsExtensionAttributesSchemaQueryResult = Apollo.QueryResult<SchoolsExtensionAttributesSchemaQuery, SchoolsExtensionAttributesSchemaQueryVariables>;