import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'

const MiniPresetList = ({ presets, model }) => {
    return presets ? (
        <>
            <strong>Model name:</strong>
            <TableContainer component={Paper}>
                <Table aria-label="Affected presets table">
                    <TableBody>
                        <TableRow
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 }
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {model.code}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <strong>Presets:</strong>
            {presets.length && (
                <TableContainer component={Paper}>
                    <Table aria-label="Affected presets table">
                        <TableBody>
                            {presets.map((item) => (
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 }
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {item?.success ? '✅' : null}
                                        {item?.error ? '❌' : null}
                                        &nbsp;
                                        {item?.internal_name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    ) : (
        <></>
    )
}

export default MiniPresetList
