import { useEffect, useState } from 'react'
import { Loading } from 'react-admin'
import { useForm } from 'react-final-form'
import { IChangeEvent } from '@rjsf/core'
import Form from '@rjsf/material-ui'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { AsyncTypeaheadField } from 'react-jsonschema-form-extras/lib/TypeaheadField'

import { usePersonalizationActionConfigQuery } from 'apollo/personalization/queries/ActionConfig.generated'
import { formStyles } from 'layout/styles/formStyles'
import MentionsField from './common/MentionsField'
import validator from '@rjsf/validator-ajv8'
import PathTree from 'components/PathTree'
import { PersonalizationAvailableMentionsTypes } from 'apollo/types.generated'
import { galleryImageSelectorFieldRegistry } from 'components/GalleryImageSelector/uiSchema'
import { RegistryFieldsType } from '@rjsf/utils'

interface PersonalizationRuleFormActionsProps {
    actions: string
}

const useStyles = makeStyles(formStyles)

const PersonalizationRuleFormActions = ({ actions }: PersonalizationRuleFormActionsProps) => {
    const { change } = useForm()
    const formStyle = useStyles()
    const { data: { actionConfig } = {}, loading } = usePersonalizationActionConfigQuery({
        fetchPolicy: 'network-only'
    })
    const schema = actionConfig && JSON.parse(actionConfig)

    const fieldsRegistry: RegistryFieldsType = {
        asyncTypeahead: AsyncTypeaheadField,
        ...galleryImageSelectorFieldRegistry
    }

    const uiSchema = {
        actions: {
            items: {
                ...schema?.uiSchema?.actions?.items,
                variable_replace: {
                    overrides: {
                        items: {
                            path: {
                                'ui:widget': PathTree
                            },
                            'Entity Id': {
                                'ui:widget': (props) => (
                                    <MentionsField
                                        {...props}
                                        placeholder="Entity Id"
                                        variables={{
                                            type: PersonalizationAvailableMentionsTypes.School,
                                            withEntity: false
                                        }}
                                    />
                                )
                            },
                            Value: {
                                'ui:widget': (props) => (
                                    <MentionsField
                                        {...props}
                                        placeholder="Value"
                                        variables={{
                                            type: PersonalizationAvailableMentionsTypes.School,
                                            withEntity: false
                                        }}
                                    />
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    const [formData, setFormData] = useState<unknown>(actions && JSON.parse(actions))

    // Initial value
    useEffect(() => {
        change('actions', actions)
        setFormData(JSON.parse(actions))
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions])

    const onChangeHandler = (e: IChangeEvent<unknown>) => {
        change('actions', JSON.stringify(e.formData))
    }

    if (loading) return <Loading />

    return (
        <FormWrapper>
            <Form
                fields={fieldsRegistry}
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                onChange={(e) => onChangeHandler(e)}
                onError={(e) => console.error('errors', e)}
                validator={validator}
            >
                <button className={formStyle.hidden} type="submit" />
            </Form>
        </FormWrapper>
    )
}

const FormWrapper = styled.section`
    .MuiGrid-item {
        overflow: visible !important;
    }

    & .control-label {
        font-size: 24px;
    }

    & .rbt-input-main {
        z-index: 999;
        font: inherit;
        letter-spacing: inherit;
        color: currentColor;
        padding: 4px 0 5px;
        border: 1px solid grey;
        box-sizing: border-box;
        background: none;
        height: 1.4375em;
        margin: 5px 0 0 0;
        -webkit-tap-highlight-color: transparent;
        display: block;
        min-width: 0;
        width: 100%;
        padding: 16.5px 14px;
        border-radius: 10px;
    }

    & .rbt-sr-status {
        margin-top: 5px;
        color: inherit;
    }

    & .rbt-menu {
        z-index: 999;
        background: white;
        padding: 10px;
        border: 1px solid grey;

        .dropdown-menu {
            li {
                padding: 10px;
            }
        }
        .dropdown-item {
            z-index: 999;
            text-decoration: none;
            cursor: pointer;
            color: inherit;
            :hover {
                text-decoration: underline;
            }
        }
    }
`

export default PersonalizationRuleFormActions
