import { useCallback, useEffect, useState } from 'react'
import { BooleanInput, NumberInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import {
    Box,
    FormControlLabel,
    Grid,
    Typography,
    Switch,
    CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import PersonalizationRuleQueryBuilder from './PersonalizationRuleQueryBuilder'
import { formStyles } from 'layout/styles/formStyles'
import { usePersonalizationRuleBuilderConfigQuery } from 'apollo/personalization/queries/PersonalizationRuleBuilderConfig.generated'
import { generateRandomString } from 'utils/generateRandomString'

const useStyles = makeStyles(formStyles)
interface PersonalizationRuleFormTabRulesProps {
    rules: string
}

const PersonalizationRuleFormTabRules = ({ rules }: PersonalizationRuleFormTabRulesProps) => {
    const ALWAYS_APPLY_VALUE = 'true'
    const DEFAULT_JSON_STRING_VALUE = '{}'

    const { change } = useForm()
    const translate = useTranslate()
    const formStyle = useStyles()
    const [alwaysApply, setAlwaysApply] = useState<boolean>(rules === ALWAYS_APPLY_VALUE)
    const { data: builderConfig = {}, loading: isLoading } =
        usePersonalizationRuleBuilderConfigQuery()
    const [uniqueKey, setUniqueKey] = useState('')

    // Initial value
    useEffect(() => {
        change('rules', rules)
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isLoading) setUniqueKey(generateRandomString())
    }, [isLoading])

    // Update "rules" field in form
    const updateRulesFormField = useCallback(
        (rules: string) => {
            change('rules', rules)
        },
        [change]
    )

    const setRulesFromQuery = (rules: string) => {
        updateRulesFormField(rules)
    }

    const setRulesAreAlwaysApplied = () => {
        updateRulesFormField(ALWAYS_APPLY_VALUE)
    }

    const setRulesAreDefault = () => {
        updateRulesFormField(DEFAULT_JSON_STRING_VALUE)
    }

    const alwaysAppliedOnChangeHandler = () => {
        setAlwaysApply(!alwaysApply)
        if (!alwaysApply) setRulesAreAlwaysApplied()
        else if (alwaysApply) setRulesAreDefault()
    }

    // Seperated the from contents of this file, because useForm can only be called from inside a form component
    return (
        <Grid container className={formStyle.fullWidth}>
            <Grid item md={7}>
                <Typography variant="h6">{translate('manager.resources.rule.rules')}</Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={alwaysApply}
                            onChange={() => alwaysAppliedOnChangeHandler()}
                        />
                    }
                    label={translate('manager.resources.rule.always_apply')}
                />
                <BooleanInput
                    row
                    source="break"
                    label={translate('manager.resources.rule.break')}
                    defaultValue={false}
                />
                <Box mt={2}>
                    <NumberInput
                        source="priority"
                        variant="outlined"
                        label={translate('manager.resources.rule.priority')}
                        fullWidth
                        defaultValue={0}
                    />
                </Box>
            </Grid>
            <Grid item md={12}>
                <Box mb={2}>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <PersonalizationRuleQueryBuilder
                            key={uniqueKey}
                            rules={rules}
                            alwaysApply={alwaysApply}
                            builderConfig={builderConfig}
                            setRulesCallBack={(rules: string) => setRulesFromQuery(rules)}
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    )
}

export default PersonalizationRuleFormTabRules
