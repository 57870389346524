import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PersonalizationRuleBuilderConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PersonalizationRuleBuilderConfigQuery = { __typename?: 'Query', personalizationRuleBuilderConfig?: string | null | undefined };


export const PersonalizationRuleBuilderConfigDocument = gql`
    query PersonalizationRuleBuilderConfig {
  personalizationRuleBuilderConfig
}
    `;

/**
 * __usePersonalizationRuleBuilderConfigQuery__
 *
 * To run a query within a React component, call `usePersonalizationRuleBuilderConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalizationRuleBuilderConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalizationRuleBuilderConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalizationRuleBuilderConfigQuery(baseOptions?: Apollo.QueryHookOptions<PersonalizationRuleBuilderConfigQuery, PersonalizationRuleBuilderConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalizationRuleBuilderConfigQuery, PersonalizationRuleBuilderConfigQueryVariables>(PersonalizationRuleBuilderConfigDocument, options);
      }
export function usePersonalizationRuleBuilderConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalizationRuleBuilderConfigQuery, PersonalizationRuleBuilderConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalizationRuleBuilderConfigQuery, PersonalizationRuleBuilderConfigQueryVariables>(PersonalizationRuleBuilderConfigDocument, options);
        }
export type PersonalizationRuleBuilderConfigQueryHookResult = ReturnType<typeof usePersonalizationRuleBuilderConfigQuery>;
export type PersonalizationRuleBuilderConfigLazyQueryHookResult = ReturnType<typeof usePersonalizationRuleBuilderConfigLazyQuery>;
export type PersonalizationRuleBuilderConfigQueryResult = Apollo.QueryResult<PersonalizationRuleBuilderConfigQuery, PersonalizationRuleBuilderConfigQueryVariables>;