import { useRedirect, Edit, SimpleForm, useTranslate } from 'react-admin'
import SchoolPeriodEditToolbar from './SchoolPeriodEditToolbar'
import SchoolPeriodFormInputs from './SchoolPeriodFormInputs'
import { useIndexSchoolMutation } from '../../apollo/magento/mutations/IndexSchool.generated'

const SchoolPeriodEdit = (props) => {
    const translate = useTranslate()
    const redirect = useRedirect()
    const [indexSchoolMutation] = useIndexSchoolMutation()

    const onSuccess = (response: any) => {
        indexSchoolMutation({
            variables: {
                schoolId: response.data?.school?.ajat_id
            }
        })
        // Redirect with active tab open
        redirect(`/School/${response.data?.school?.id}/periods`)
    }

    return (
        <Edit
            {...props}
            undoable={false}
            onSuccess={onSuccess}
            basePath={props.basepath}
            resource={props.resource}
            title={translate(`manager.resources.${props.resource}.edit`)}
        >
            <SimpleForm
                initialValues={{ school_id: props?.location?.state?.school_id }}
                toolbar={<SchoolPeriodEditToolbar />}
            >
                <SchoolPeriodFormInputs {...props} />
            </SimpleForm>
        </Edit>
    )
}

export default SchoolPeriodEdit
