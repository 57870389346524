/**
 * Declare here query overrides:
 * a query name and set of fields
 */

const overridesMap = {
    configuratorPreset: `
      active
      id
      internal_name
      model {
        id
        code
      }
      parent_model_id
      public_name
      published
      published_code
    `,
    configuratorPresets: `
      active
      id
      internal_name
      public_name
      updated_at
      parent_model_id
    `,
    packagesPackageGroupCollections: `
      internal_name
      public_name
      active
      updated_at
    `,
    packagesPackageGroups: `
      internal_name
      public_name
      active
      updated_at
      presets {
        code
      }
    `,
    packagesPackages: `
      public_name
      order
      type
      active
      updated_at
      definition
    `,
    schoolsCreateSchool: `
      active
      address
      ajat_id
      city
      classes {
        active
        name
        updated_at
        year {
          name
        }
      }
      country
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      hero_banner
      hero_banner_url
      internal_note
      logo
      logo_url
      logo_2
      logo_2_url
      logo_3
      logo_3_url
      logo_4
      logo_4_url
      marketing_url
      name
      priority
      periods {
        active
        from_date
        grace_payment_date
        to_date
        updated_at
      }
      personalization_logo
      personalization_logo_url
      post_code
      priority
      sales_responsible
      sales_team
      representative_email
      representative_name
      representative_ssn
      representative_telephone
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        discount_used
        email
        id
        name
        schools {
          ajat_id
          name
        }
        ssn
        telephone
        updated_at
      }
      students_amount
      tags {
        id
        tag
      }
    `,
    schoolsCreateSchoolClass: `
      active
      ajat_id
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      id
      name
      priority
      school_id
      school_year_id
      students_amount
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        discount_used
        email
        id
        name
        schools {
          ajat_id
          name
        }
        ssn
        telephone
        updated_at
      }
      tags {
        id
        tag
      }
      year {
        active
        id
        name
      }
    `,
    schoolsCreateSchoolClassRepresentative: `
      name
      email
      ssn
      telephone
      schools {
        id
        ajat_id
        name
      }
      classes {
        id
        name
      }
      discount_amount
      discount_percentage
      discount_used
      updated_at
    `,
    schoolsSchool: `
      active
      address
      ajat_id
      city
      classes {
        active
        name
        updated_at
        year {
          name
        }
      }
      country
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      hero_banner
      hero_banner_url
      internal_note
      logo
      logo_url
      logo_2
      logo_2_url
      logo_3
      logo_3_url
      logo_4
      logo_4_url
      marketing_url
      name
      priority
      periods {
        active
        from_date
        grace_payment_date
        to_date
        updated_at
      }
      personalization_logo
      personalization_logo_url
      post_code
      priority
      sales_responsible
      sales_team
      representative_email
      representative_name
      representative_ssn
      representative_telephone
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        discount_used
        email
        id
        name
        schools {
          ajat_id
          name
        }
        ssn
        telephone
        updated_at
      }
      students_amount
      tags {
        id
        tag
      }
    `,
    schoolsSchools: `
      name
      ajat_id
      tags {
        tag
      }
      active
      periods {
        active
        from_date
        to_date
      }
      priority
      updated_at
      priority
    `,
    schoolsSchoolClass: `
      active
      ajat_id
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      id
      name
      priority
      school_id
      school_year_id
      students_amount
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        discount_used
        email
        id
        name
        schools {
          ajat_id
          name
        }
        ssn
        telephone
        updated_at
      }
      tags {
        id
        tag
      }
      year {
        active
        id
        name
      }
    `,
    schoolsSchoolClasses: `
      name
      school {
        ajat_id
        name
      }
      year {
        name
      }
      active
      priority
      updated_at
      priority
    `,
    schoolsSchoolTag: `
      tag
      updated_at
    `,
    schoolsSchoolTags: `
      tag
      updated_at
    `,
    schoolsSchoolYears: `
      name
      active
      updated_at
    `,
    schoolsEducations: `
      name
      code
      active
      priority
      updated_at
      priority
    `,
    schoolsSchoolClassRepresentative: `
      name
      email
      ssn
      telephone
      schools {
        id
        ajat_id
        name
      }
      classes {
        id
        name
      }
      discount_amount
      discount_percentage
      discount_used
      updated_at
    `,
    schoolsSchoolClassRepresentatives: `
      name
      email
      ssn
      telephone
      schools {
        name
      }
      classes {
        name
      }
      discount_amount
      discount_percentage
      discount_used
      updated_at
    `,
    schoolsUpdateSchool: `
      active
      address
      ajat_id
      city
      classes {
        active
        name
        updated_at
        year {
          name
        }
      }
      country
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      hero_banner
      hero_banner_url
      internal_note
      logo
      logo_url
      logo_2
      logo_2_url
      logo_3
      logo_3_url
      logo_4
      logo_4_url
      marketing_url
      name
      priority
      periods {
        active
        from_date
        grace_payment_date
        to_date
        updated_at
      }
      personalization_logo
      personalization_logo_url
      post_code
      priority
      sales_responsible
      sales_team
      representative_email
      representative_name
      representative_ssn
      representative_telephone
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        discount_used
        email
        id
        name
        schools {
          ajat_id
          name
        }
        ssn
        telephone
        updated_at
      }
      students_amount
      tags {
        id
        tag
      }
    `,
    schoolsUpdateSchoolClass: `
      active
      ajat_id
      embroidery_1
      embroidery_2
      embroidery_3
      extension_attributes
      id
      name
      priority
      school_id
      school_year_id
      students_amount
      representatives {
        classes {
          id
          name
        }
        discount_amount
        discount_percentage
        discount_used
        email
        id
        name
        schools {
          ajat_id
          name
        }
        ssn
        telephone
        updated_at
      }
      tags {
        id
        tag
      }
      year {
        active
        id
        name
      }
    `,
    schoolsUpdateSchoolClassRepresentative: `
      name
      email
      ssn
      telephone
      schools {
        id
        ajat_id
        name
      }
      classes {
        id
        name
      }
      discount_amount
      discount_percentage
      discount_used
      updated_at
    `,
    schoolsUpdateSchoolTag: `
      tag
      updated_at
    `,
    personalizationRules: `
      label
      active
      rules
      priority
    `
}

export default overridesMap
