import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PackagesPackageGalleryItemQueryVariables = Types.Exact<{
  packagesPackageGalleryItemId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type PackagesPackageGalleryItemQuery = { __typename?: 'Query', packagesPackageGalleryItem?: { __typename?: 'packagesPackageGalleryItem', id: string, package_id: number, alt: string, path: string, title: string } | null | undefined };


export const PackagesPackageGalleryItemDocument = gql`
    query PackagesPackageGalleryItem($packagesPackageGalleryItemId: ID) {
  packagesPackageGalleryItem(id: $packagesPackageGalleryItemId) {
    id
    package_id
    alt
    path
    title
  }
}
    `;

/**
 * __usePackagesPackageGalleryItemQuery__
 *
 * To run a query within a React component, call `usePackagesPackageGalleryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesPackageGalleryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesPackageGalleryItemQuery({
 *   variables: {
 *      packagesPackageGalleryItemId: // value for 'packagesPackageGalleryItemId'
 *   },
 * });
 */
export function usePackagesPackageGalleryItemQuery(baseOptions?: Apollo.QueryHookOptions<PackagesPackageGalleryItemQuery, PackagesPackageGalleryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesPackageGalleryItemQuery, PackagesPackageGalleryItemQueryVariables>(PackagesPackageGalleryItemDocument, options);
      }
export function usePackagesPackageGalleryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesPackageGalleryItemQuery, PackagesPackageGalleryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesPackageGalleryItemQuery, PackagesPackageGalleryItemQueryVariables>(PackagesPackageGalleryItemDocument, options);
        }
export type PackagesPackageGalleryItemQueryHookResult = ReturnType<typeof usePackagesPackageGalleryItemQuery>;
export type PackagesPackageGalleryItemLazyQueryHookResult = ReturnType<typeof usePackagesPackageGalleryItemLazyQuery>;
export type PackagesPackageGalleryItemQueryResult = Apollo.QueryResult<PackagesPackageGalleryItemQuery, PackagesPackageGalleryItemQueryVariables>;