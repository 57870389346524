import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateNodeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  parent_id?: Types.InputMaybe<Types.Scalars['Int']>;
  node_attributes: Types.Scalars['String'];
}>;


export type UpdateNodeMutation = { __typename?: 'Mutation', configuratorUpdateNode?: { __typename?: 'configuratorNode', id: string, parent_id?: number | null | undefined, node_attributes?: string | null | undefined } | null | undefined };


export const UpdateNodeDocument = gql`
    mutation UpdateNode($id: ID!, $parent_id: Int, $node_attributes: String!) {
  configuratorUpdateNode(
    id: $id
    parent_id: $parent_id
    node_attributes: $node_attributes
  ) {
    id
    parent_id
    node_attributes
  }
}
    `;
export type UpdateNodeMutationFn = Apollo.MutationFunction<UpdateNodeMutation, UpdateNodeMutationVariables>;

/**
 * __useUpdateNodeMutation__
 *
 * To run a mutation, you first call `useUpdateNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNodeMutation, { data, loading, error }] = useUpdateNodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parent_id: // value for 'parent_id'
 *      node_attributes: // value for 'node_attributes'
 *   },
 * });
 */
export function useUpdateNodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNodeMutation, UpdateNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNodeMutation, UpdateNodeMutationVariables>(UpdateNodeDocument, options);
      }
export type UpdateNodeMutationHookResult = ReturnType<typeof useUpdateNodeMutation>;
export type UpdateNodeMutationResult = Apollo.MutationResult<UpdateNodeMutation>;
export type UpdateNodeMutationOptions = Apollo.BaseMutationOptions<UpdateNodeMutation, UpdateNodeMutationVariables>;