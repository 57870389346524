import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConfiguratorPresetsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ConfiguratorPresetsQuery = { __typename?: 'Query', configuratorPresets?: { __typename?: 'configuratorPresetPaginator', data: Array<{ __typename?: 'configuratorPreset', id: string, internal_name: string, parent_model_id: number, code: string, model: { __typename?: 'configuratorNode', code?: string | null | undefined } }> } | null | undefined };


export const ConfiguratorPresetsDocument = gql`
    query ConfiguratorPresets($first: Int) {
  configuratorPresets(first: $first) {
    data {
      id
      internal_name
      parent_model_id
      code
      model {
        code
      }
    }
  }
}
    `;

/**
 * __useConfiguratorPresetsQuery__
 *
 * To run a query within a React component, call `useConfiguratorPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguratorPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguratorPresetsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useConfiguratorPresetsQuery(baseOptions?: Apollo.QueryHookOptions<ConfiguratorPresetsQuery, ConfiguratorPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfiguratorPresetsQuery, ConfiguratorPresetsQueryVariables>(ConfiguratorPresetsDocument, options);
      }
export function useConfiguratorPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfiguratorPresetsQuery, ConfiguratorPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfiguratorPresetsQuery, ConfiguratorPresetsQueryVariables>(ConfiguratorPresetsDocument, options);
        }
export type ConfiguratorPresetsQueryHookResult = ReturnType<typeof useConfiguratorPresetsQuery>;
export type ConfiguratorPresetsLazyQueryHookResult = ReturnType<typeof useConfiguratorPresetsLazyQuery>;
export type ConfiguratorPresetsQueryResult = Apollo.QueryResult<ConfiguratorPresetsQuery, ConfiguratorPresetsQueryVariables>;