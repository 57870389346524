import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useContext, useState } from 'react'
import FolderTree from './FolderTree'
import ImagesBlock from './ImagesBlock'
import UploadBlock from './UploadBlock'
import { GalleryContext } from 'context/gallery/GalleryContext'
import GalleryPopupTitle from '../../atoms/buttons/popup/GalleryPopupTitle'
import GalleryPopupContent from '../../atoms/buttons/popup/GalleryPopupContent'
import GalleryPopupActions from '../../atoms/buttons/popup/GalleryPopupActions'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles(() => ({
    popup: {
        width: '95vw',
        minHeight: '100%'
    }
}))

const GalleryPopup = () => {
    const classes = useStyles()
    const translate = useTranslate()
    const { state, setGalleryState } = useContext(GalleryContext)
    const [file, setFile] = useState('')
    const [extension, setExtension] = useState('')

    const handleClose = () => {
        setGalleryState({ ...state, openGallery: false, isUploading: false })
    }

    const handleFileSelection = (url: string) => {
        const fullUrl = `${state.breadcrumbs === '/' ? '' : state.breadcrumbs}/${url.substr(
            0,
            url.lastIndexOf('.')
        )}`
        const extension = `${url.substr(url.lastIndexOf('.'))}`

        setFile(fullUrl)
        setExtension(extension)
    }

    const handleFileSelect = () => {
        return setGalleryState({
            ...state,
            openGallery: false,
            selectedFile: file,
            selectedFileExtension: extension
        })
    }

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={state.openGallery}
                maxWidth={false}
            >
                <GalleryPopupTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    toggleUpload={() =>
                        setGalleryState({ ...state, isUploading: !state.isUploading })
                    }
                    isUploading={state.isUploading}
                >
                    {translate('manager.gallery.gallery')}
                </GalleryPopupTitle>
                {state.isUploading && <UploadBlock />}
                <GalleryPopupContent dividers className={classes.popup}>
                    {state.openGallery ? <FolderTree /> : null}
                    <ImagesBlock handleFileSelection={handleFileSelection} />
                </GalleryPopupContent>
                <GalleryPopupActions>
                    <Button autoFocus onClick={handleClose} color="secondary">
                        {translate('manager.gallery.close')}
                    </Button>
                    <Button
                        autoFocus
                        onClick={handleFileSelect}
                        variant="contained"
                        color="primary"
                    >
                        {translate('manager.gallery.select_file')}
                    </Button>
                </GalleryPopupActions>
            </Dialog>
        </div>
    )
}

export default GalleryPopup
