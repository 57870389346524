import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsImportSchoolsMutationVariables = Types.Exact<{
  file: Types.Scalars['schoolsUpload'];
}>;


export type SchoolsImportSchoolsMutation = { __typename?: 'Mutation', schoolsImportSchools?: boolean | null | undefined };


export const SchoolsImportSchoolsDocument = gql`
    mutation SchoolsImportSchools($file: schoolsUpload!) {
  schoolsImportSchools(file: $file)
}
    `;
export type SchoolsImportSchoolsMutationFn = Apollo.MutationFunction<SchoolsImportSchoolsMutation, SchoolsImportSchoolsMutationVariables>;

/**
 * __useSchoolsImportSchoolsMutation__
 *
 * To run a mutation, you first call `useSchoolsImportSchoolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolsImportSchoolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolsImportSchoolsMutation, { data, loading, error }] = useSchoolsImportSchoolsMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSchoolsImportSchoolsMutation(baseOptions?: Apollo.MutationHookOptions<SchoolsImportSchoolsMutation, SchoolsImportSchoolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SchoolsImportSchoolsMutation, SchoolsImportSchoolsMutationVariables>(SchoolsImportSchoolsDocument, options);
      }
export type SchoolsImportSchoolsMutationHookResult = ReturnType<typeof useSchoolsImportSchoolsMutation>;
export type SchoolsImportSchoolsMutationResult = Apollo.MutationResult<SchoolsImportSchoolsMutation>;
export type SchoolsImportSchoolsMutationOptions = Apollo.BaseMutationOptions<SchoolsImportSchoolsMutation, SchoolsImportSchoolsMutationVariables>;