// Packages
import React, { useContext } from 'react'
import { ApolloError } from '@apollo/client'
import { useNotify, useTranslate } from 'react-admin'
import clsx from 'clsx'

// MUI
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import BackupIcon from '@material-ui/icons/Backup'

// GraphQL
import { usePresetExportModelMutation } from 'apollo/configurator/mutations/PresetExportModel.generated'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'

// Custom
import { COLORS } from 'layout/theme/colors'
import { PresetModelContext } from 'context/preset-model/PresetModelContext'

interface ExportButtonProps {
    id: number
    presetId: number
    published: boolean
    isWithActionsFlush?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative'
        },
        buttonPublished: {
            backgroundColor: `${theme.palette.success.main}`,
            color: `${theme.palette.secondary.contrastText}`,
            '&:hover': {
                backgroundColor: `${theme.palette.success.dark}`
            }
        },
        buttonExportError: {
            backgroundColor: `${theme.palette.error.main}`,
            color: `${theme.palette.secondary.contrastText}`,
            '&:hover': {
                backgroundColor: `${theme.palette.error.dark}`
            }
        },
        buttonHasChanges: {
            backgroundColor: COLORS.theme.amber.main,
            '&:hover': {
                backgroundColor: COLORS.theme.amber.dark
            }
        },
        circularProgress: {
            marginLeft: theme.spacing(1),
            color: `${theme.palette.secondary.contrastText}`
        }
    })
)

const PresetExportButton = ({ id, presetId, published, isWithActionsFlush }: ExportButtonProps) => {
    const { state, setState } = useContext(PresetModelContext)
    const translate = useTranslate()
    const notify = useNotify()
    const classes = useStyles()

    const [open, setOpen] = React.useState(false)
    const [isPublished, setIsPublihsed] = React.useState(published)
    const [mutationError, setMutationError] = React.useState(false)
    const buttonClassname = clsx({
        [classes.buttonPublished]: isPublished && !mutationError,
        [classes.buttonExportError]: mutationError,
        [classes.buttonHasChanges]: !mutationError && (!isPublished || state.hasChanges)
    })
    const [exportModel, { loading, error }] = usePresetExportModelMutation({
        onCompleted({ configuratorExportModel: exportModel }) {
            if (exportModel) {
                handleExportSucces()
            } else if (error) {
                handleExportFailed(error)
            }
        },
        onError(error) {
            handleExportFailed(error)
        }
    })
    const [flushActionsCache] = useFlushActionsCacheMutation()

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleExportSucces = () => {
        setIsPublihsed(true)
        setMutationError(false)
        setState({
            ...state,
            hasEdited: false,
            hasChanges: false,
            showEditedMessage: false
        })
        notify('manager.resources.preset.manager.preset_export_succes', { type: 'success' })
    }

    const handleExportFailed = (error?: ApolloError | undefined) => {
        setMutationError(true)
        if (error) {
            notify('manager.resources.preset.manager.preset_export_error', { type: 'error' })
            console.error('handleExportFailed ->', error.message)
        }
    }

    const handleConfirm = () => {
        exportModel({
            variables: { configuratorExportModelId: `${id}`, preset: parseInt(`${presetId}`) }
        })
        if (isWithActionsFlush) flushActionsCache()
        setOpen(false)
    }

    return (
        <div className={classes.wrapper}>
            <Button
                color="primary"
                variant="contained"
                className={buttonClassname}
                onClick={handleClickOpen}
                startIcon={<BackupIcon />}
            >
                {translate('manager.resources.preset.publish')}
                {loading && <CircularProgress size={20} className={classes.circularProgress} />}
            </Button>
            <Dialog open={open} keepMounted onClose={handleClose}>
                <DialogTitle>
                    {translate('manager.resources.preset.publish_dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate('manager.resources.preset.publish_dialog.content')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="default">
                        {translate('manager.resources.preset.publish_dialog.cancel')}
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary">
                        {translate('manager.resources.preset.publish_dialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PresetExportButton
