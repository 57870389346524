import {
    useRedirect,
    Edit,
    SimpleForm,
    useTranslate,
    TextInput,
    Loading,
    required
} from 'react-admin'

import { usePackagesPackageGroupCollectionGalleryItemQuery } from 'apollo/package_manager/queries/PackageGroupCollectionGalleryItem.generated'

import { Typography } from '@material-ui/core'

import PackageGroupCollectionGalleryItemEditToolbar from './PackageGroupCollectionGalleryItemEditToolbar'
import UploadField from 'components/formfields/uploadField/UploadField'

const PackageGroupCollectionGalleryItemEdit = (props) => {
    const RESOURCE = 'PackageGroupCollectionGalleryItem'
    const id = props?.match?.params?.id ?? ''
    const translate = useTranslate()
    const redirect = useRedirect()
    const onSuccess = (response: any) => {
        redirect('edit', `/PackageGroupCollection/${response.data?.collection_id}/images`, '')
    }

    const { data: { packagesPackageGroupCollectionGalleryItem: record } = {} } =
        usePackagesPackageGroupCollectionGalleryItemQuery({
            variables: {
                packagesPackageGroupCollectionGalleryItemId: id
            }
        })

    if (!record) return <Loading />
    return (
        <Edit
            {...props}
            undoable={false}
            resource={RESOURCE}
            onSuccess={onSuccess}
            basePath={props.basepath}
            title={translate(`manager.resources.${RESOURCE}.edit`)}
        >
            <SimpleForm
                initialValues={{ collection_id: props?.location?.state?.collection_id }}
                toolbar={<PackageGroupCollectionGalleryItemEditToolbar resource={RESOURCE} />}
            >
                <Typography variant="h6" gutterBottom>
                    {translate(`manager.resources.${RESOURCE}.edit`)}
                </Typography>
                <TextInput
                    source="title"
                    variant="outlined"
                    label={translate(`manager.resources.${RESOURCE}.title`)}
                    validate={requiredValidate}
                />
                <TextInput
                    source="alt"
                    variant="outlined"
                    label={translate(`manager.resources.${RESOURCE}.alt`)}
                    validate={requiredValidate}
                />
                <UploadField
                    source="path"
                    label={translate(`manager.resources.${RESOURCE}.path`)}
                    data={record.path}
                    url={record.path}
                    validateRequired
                />
            </SimpleForm>
        </Edit>
    )
}

const requiredValidate = [required()]

export default PackageGroupCollectionGalleryItemEdit
