type OverrideObject = {
    [key: string]: any
}

export function filterNonEmptyObjects(array: OverrideObject[]): OverrideObject[] | false {
    if (!Array.isArray(array) || array.length === 0) {
        return false
    }

    const filtered = array.filter((item) => {
        if (typeof item === 'object' && item !== null) {
            return Object.keys(item).length > 0
        }
        return false
    })

    return filtered.length > 0 ? filtered : false
}
