import { darken } from '@material-ui/core'
import { COLORS } from './colors'

export const darkTheme = {
    palette: {
        primary: {
            main: COLORS.theme.ajat_green.main,
            light: COLORS.theme.ajat_green.light,
            dark: COLORS.theme.ajat_green.dark,
            contrastText: COLORS.white
        },
        secondary: {
            main: COLORS.theme.dark_purple.main,
            light: COLORS.theme.dark_purple.light,
            dark: COLORS.theme.dark_purple.dark,
            contrastText: COLORS.white
        },
        error: {
            main: COLORS.theme.error_red.main,
            light: COLORS.theme.error_red.light,
            dark: COLORS.theme.error_red.dark
        },
        type: 'dark' as 'dark'
    },
    sidebar: {
        width: 220
    },
    overrides: {
        RaLayout: {
            content: {
                paddingBottom: '64px'
            }
        },
        RaEdit: {
            root: {
                marginBottom: '2rem'
            }
        },
        RaMenuItemLink: {
            root: {
                borderLeft: `0rem solid ${COLORS.white}`
            },
            active: {
                borderLeft: `0.5rem solid ${COLORS.theme.ajat_green.main}`
            }
        },
        MuiAppBar: {
            colorSecondary: {
                color: darken(COLORS.white, 0.01),
                backgroundColor: COLORS.theme.onyx.main,
                borderBottom: `0.1rem solid ${COLORS.theme.grey.dark}`,
                boxShadow: 'none'
            }
        },
        MuiButton: {
            root: {
                minWidth: '2rem'
            },
            containedPrimary: {
                color: COLORS.white,
                backgroundColor: COLORS.theme.ajat_green.main,
                boxShadow: 'none'
            }
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit'
                }
            }
        },
        MuiDrawer: {
            root: {
                backgroundColor: COLORS.theme.onyx.main,
                borderRight: `0.1rem solid ${COLORS.theme.grey.dark}`
            }
        },
        MuiListItemIcon: {
            root: {
                color: COLORS.theme.ajat_green.main
            }
        },
        MuiToolbar: {
            root: {
                width: '100%',
                marginTop: 0,
                bottom: 0
            }
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
        MuiTextFieldBase: {
            variant: 'outlined'
        }
    },
    components: {
        MuiTextField: {
            variant: 'outlined'
        }
    }
}
