import { ReduxState, Record, Identifier } from 'react-admin'

export type ThemeName = 'light' | 'dark' | 'proudNerds'

export type TProductType = 'SimpleProduct' | 'ConfigurableProduct' | 'ConfigurationProduct'

export enum InputVariant {
    OUTLINED = 'outlined'
}

export interface AppState extends ReduxState {
    theme: ThemeName
}

export interface PackageProduct extends Record {
    active: boolean
    created_at: string
    definition: string
    id: Identifier
    package_id: Identifier
    pricing: string
    sku: string
    updated_at: string
}
export interface ChoiceType {
    id: string
    name: string
    type?: string
}
