import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formStyles } from 'layout/styles/formStyles'
import { useTranslate, TabbedForm, FormTab, TextInput, required, BooleanInput } from 'react-admin'
import { FormSpy } from 'react-final-form'

import PersonalizationRuleFormActions from './PersonalizationRuleFormActions'
import PersonalizationRuleFormTabRules from './PersonalizationRuleFormTabRules'
import CustomToolBar from 'components/common/CustomToolBar'
import DefaultToolbar from 'components/common/DefaultToolbar'

const useStyles = makeStyles(formStyles)

const PersonalizationRuleForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()
    const formStyle = useStyles()
    const DEFAULT_JSON_STRING_VALUE = '{}'
    const rules = props?.record?.rules ?? DEFAULT_JSON_STRING_VALUE
    const actions = props?.record?.actions ?? DEFAULT_JSON_STRING_VALUE

    return (
        <TabbedForm {...props} toolbar={!!props.isEdit ? <CustomToolBar /> : <DefaultToolbar />}>
            <FormTab label={translate('manager.tabs.general')} path="/">
                <Grid container className={formStyle.fullWidth}>
                    <Grid item md={7}>
                        <Grid item md={12}>
                            <Typography variant="h6" gutterBottom>
                                {translate('manager.resources.rule.personalization')}
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <TextInput
                                source="label"
                                variant="outlined"
                                label={translate('manager.resources.rule.label')}
                                validate={requiredValidate}
                                fullWidth
                            />
                            <BooleanInput
                                source="active"
                                label={translate('manager.resources.general.published')}
                                defaultValue={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label={translate('manager.resources.rule.rules')} path="rules">
                <PersonalizationRuleFormTabRules rules={rules} />
            </FormTab>
            <FormTab label={translate('manager.resources.rule.actions')} path="actions">
                <PersonalizationRuleFormActions actions={actions} />
            </FormTab>
            <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                    setIsPristineFormCallback(props?.pristine)
                }}
            />
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default PersonalizationRuleForm
