import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsImportSchoolClassRepresentativesMutationVariables = Types.Exact<{
  file: Types.Scalars['schoolsUpload'];
}>;


export type SchoolsImportSchoolClassRepresentativesMutation = { __typename?: 'Mutation', schoolsImportSchoolClassRepresentatives?: boolean | null | undefined };


export const SchoolsImportSchoolClassRepresentativesDocument = gql`
    mutation SchoolsImportSchoolClassRepresentatives($file: schoolsUpload!) {
  schoolsImportSchoolClassRepresentatives(file: $file)
}
    `;
export type SchoolsImportSchoolClassRepresentativesMutationFn = Apollo.MutationFunction<SchoolsImportSchoolClassRepresentativesMutation, SchoolsImportSchoolClassRepresentativesMutationVariables>;

/**
 * __useSchoolsImportSchoolClassRepresentativesMutation__
 *
 * To run a mutation, you first call `useSchoolsImportSchoolClassRepresentativesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolsImportSchoolClassRepresentativesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolsImportSchoolClassRepresentativesMutation, { data, loading, error }] = useSchoolsImportSchoolClassRepresentativesMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSchoolsImportSchoolClassRepresentativesMutation(baseOptions?: Apollo.MutationHookOptions<SchoolsImportSchoolClassRepresentativesMutation, SchoolsImportSchoolClassRepresentativesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SchoolsImportSchoolClassRepresentativesMutation, SchoolsImportSchoolClassRepresentativesMutationVariables>(SchoolsImportSchoolClassRepresentativesDocument, options);
      }
export type SchoolsImportSchoolClassRepresentativesMutationHookResult = ReturnType<typeof useSchoolsImportSchoolClassRepresentativesMutation>;
export type SchoolsImportSchoolClassRepresentativesMutationResult = Apollo.MutationResult<SchoolsImportSchoolClassRepresentativesMutation>;
export type SchoolsImportSchoolClassRepresentativesMutationOptions = Apollo.BaseMutationOptions<SchoolsImportSchoolClassRepresentativesMutation, SchoolsImportSchoolClassRepresentativesMutationVariables>;