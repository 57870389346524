import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PackageAvailableProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PackageAvailableProductsQuery = { __typename?: 'Query', packagesAvailableProducts?: Array<{ __typename?: 'packagesAvailableProduct', name: string, pricing?: string | null | undefined, sku: string, type: string } | null | undefined> | null | undefined };


export const PackageAvailableProductsDocument = gql`
    query PackageAvailableProducts {
  packagesAvailableProducts {
    name
    pricing
    sku
    type
  }
}
    `;

/**
 * __usePackageAvailableProductsQuery__
 *
 * To run a query within a React component, call `usePackageAvailableProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageAvailableProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageAvailableProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePackageAvailableProductsQuery(baseOptions?: Apollo.QueryHookOptions<PackageAvailableProductsQuery, PackageAvailableProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackageAvailableProductsQuery, PackageAvailableProductsQueryVariables>(PackageAvailableProductsDocument, options);
      }
export function usePackageAvailableProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackageAvailableProductsQuery, PackageAvailableProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackageAvailableProductsQuery, PackageAvailableProductsQueryVariables>(PackageAvailableProductsDocument, options);
        }
export type PackageAvailableProductsQueryHookResult = ReturnType<typeof usePackageAvailableProductsQuery>;
export type PackageAvailableProductsLazyQueryHookResult = ReturnType<typeof usePackageAvailableProductsLazyQuery>;
export type PackageAvailableProductsQueryResult = Apollo.QueryResult<PackageAvailableProductsQuery, PackageAvailableProductsQueryVariables>;