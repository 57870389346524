import { useAuthProvider } from 'context/auth/AuthProvider'
import React, { useContext, useEffect } from 'react'
import { GalleryContext } from '../../../context/gallery/GalleryContext'
import UploadBlock from '../molecules/gallery/UploadBlock'
import GalleryContent from '../organisms/gallery/GalleryContent'

const GalleryTemplate: React.FC = () => {
    const { state, setGalleryState } = useContext(GalleryContext)
    const { permissions, isLoading } = useAuthProvider()

    useEffect(() => {
        setGalleryState({ ...state, isUploading: false })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setGalleryState]) // Disabled because adding state causes an infinite loop

    if (isLoading) {
        return <></>
    }

    return permissions?.['Gallery']?.length ? (
        <div>
            {state?.isUploading && <UploadBlock />}
            <GalleryContent />
        </div>
    ) : (
        <></>
    )
}

export default GalleryTemplate
