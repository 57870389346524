/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState } from 'react'

export type InitialStateType = {
    hasChanges: boolean
    hasEdited: boolean
    currentModel: string
    currentModelCode: string
    updatedModel: boolean
    showEditedMessage: boolean
}

const initialState = {
    state: {
        hasChanges: false,
        hasEdited: false,
        currentModel: '',
        currentModelCode: '',
        updatedModel: false,
        showEditedMessage: false
    },
    setState: (_state: InitialStateType) => {}
}

const ModelContext = createContext(initialState)

const ModelProvider: React.FC = ({ children }): React.ReactElement => {
    const [state, setState] = useState(initialState.state)
    return <ModelContext.Provider value={{ state, setState }}>{children}</ModelContext.Provider>
}

export { ModelProvider, ModelContext, initialState }
