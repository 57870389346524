import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ImportModelMutationVariables = Types.Exact<{
  json?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ImportModelMutation = { __typename?: 'Mutation', configuratorImportJSONModel?: { __typename?: 'configuratorJSONImport', model_id?: string | null | undefined } | null | undefined };


export const ImportModelDocument = gql`
    mutation ImportModel($json: String) {
  configuratorImportJSONModel(json: $json) {
    model_id
  }
}
    `;
export type ImportModelMutationFn = Apollo.MutationFunction<ImportModelMutation, ImportModelMutationVariables>;

/**
 * __useImportModelMutation__
 *
 * To run a mutation, you first call `useImportModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importModelMutation, { data, loading, error }] = useImportModelMutation({
 *   variables: {
 *      json: // value for 'json'
 *   },
 * });
 */
export function useImportModelMutation(baseOptions?: Apollo.MutationHookOptions<ImportModelMutation, ImportModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportModelMutation, ImportModelMutationVariables>(ImportModelDocument, options);
      }
export type ImportModelMutationHookResult = ReturnType<typeof useImportModelMutation>;
export type ImportModelMutationResult = Apollo.MutationResult<ImportModelMutation>;
export type ImportModelMutationOptions = Apollo.BaseMutationOptions<ImportModelMutation, ImportModelMutationVariables>;