import CollectionsIcon from '@mui/icons-material/Collections'
import PackageGroupCollectionList from './PackageGroupCollectionList'
import PackageGroupCollectionEdit from './PackageGroupCollectionEdit'
import PackageGroupCollectionCreate from './PackageGroupCollectionCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: PackageGroupCollectionList,
    edit: PackageGroupCollectionEdit,
    create: PackageGroupCollectionCreate,
    icon: CollectionsIcon
}
