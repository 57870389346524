import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreatePresetNodeMutationVariables = Types.Exact<{
  definition: Types.Scalars['String'];
  parentNodeId: Types.Scalars['Int'];
  presetId: Types.Scalars['Int'];
  removed?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CreatePresetNodeMutation = { __typename?: 'Mutation', configuratorCreatePresetNode?: { __typename?: 'configuratorPresetNode', definition: string, id: string, parent_node_id: number, preset_id: number, removed: boolean } | null | undefined };


export const CreatePresetNodeDocument = gql`
    mutation CreatePresetNode($definition: String!, $parentNodeId: Int!, $presetId: Int!, $removed: Boolean) {
  configuratorCreatePresetNode(
    definition: $definition
    parent_node_id: $parentNodeId
    preset_id: $presetId
    removed: $removed
  ) {
    definition
    id
    parent_node_id
    preset_id
    removed
  }
}
    `;
export type CreatePresetNodeMutationFn = Apollo.MutationFunction<CreatePresetNodeMutation, CreatePresetNodeMutationVariables>;

/**
 * __useCreatePresetNodeMutation__
 *
 * To run a mutation, you first call `useCreatePresetNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresetNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresetNodeMutation, { data, loading, error }] = useCreatePresetNodeMutation({
 *   variables: {
 *      definition: // value for 'definition'
 *      parentNodeId: // value for 'parentNodeId'
 *      presetId: // value for 'presetId'
 *      removed: // value for 'removed'
 *   },
 * });
 */
export function useCreatePresetNodeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePresetNodeMutation, CreatePresetNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePresetNodeMutation, CreatePresetNodeMutationVariables>(CreatePresetNodeDocument, options);
      }
export type CreatePresetNodeMutationHookResult = ReturnType<typeof useCreatePresetNodeMutation>;
export type CreatePresetNodeMutationResult = Apollo.MutationResult<CreatePresetNodeMutation>;
export type CreatePresetNodeMutationOptions = Apollo.BaseMutationOptions<CreatePresetNodeMutation, CreatePresetNodeMutationVariables>;