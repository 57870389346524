import { FormSpy } from 'react-final-form'
import {
    TextInput,
    BooleanInput,
    useTranslate,
    required,
    TabbedForm,
    FormTab,
    NumberInput
} from 'react-admin'

import { Grid, Typography } from '@mui/material'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import PackageGroupCollectionGalleryItemList from 'resources/packageGroupCollectionGalleryItem/PackageGroupCollectionGalleryItemList'
import PackageGroupList from 'resources/packageGroup/PackageGroupList'

import DefaultToolbar from 'components/common/DefaultToolbar'

const styles = makeStyles({
    textArea: {
        '& textarea': {
            resize: 'vertical'
        }
    }
})

const PackageGroupCollectionForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()
    const classes = styles()
    const collection_id = parseInt(props?.record?.id)

    return (
        <TabbedForm
            {...props}
            toolbar={<DefaultToolbar isWithActionsFlush />}
            redirect={props.basePath}
        >
            <FormTab label={translate('manager.tabs.general')} path="/">
                <Typography variant="h6" gutterBottom>
                    {translate('manager.resources.packageGroupCollection.collection')}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item md={7}>
                        <TextInput
                            fullWidth
                            source="public_name"
                            variant="outlined"
                            label={translate(
                                'manager.resources.packageGroupCollection.public_name'
                            )}
                            validate={requiredValidate}
                        />
                        <TextInput
                            fullWidth
                            source="internal_name"
                            variant="outlined"
                            label={translate(
                                'manager.resources.packageGroupCollection.internal_name'
                            )}
                            validate={requiredValidate}
                        />
                        <Grid container spacing={6} alignItems="center">
                            <Grid item md={3}>
                                <NumberInput
                                    source="order"
                                    variant="outlined"
                                    min={0}
                                    defaultValue={0}
                                    label={translate(
                                        'manager.resources.packageGroupCollection.order'
                                    )}
                                    validate={requiredValidate}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <BooleanInput
                                    source="is_favourite"
                                    label={translate(
                                        'manager.resources.packageGroupCollection.is_favourite'
                                    )}
                                />
                            </Grid>
                            <Grid item md={5}>
                                <BooleanInput
                                    source="is_default"
                                    label={translate(
                                        'manager.resources.packageGroupCollection.is_default'
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <TextInput
                            fullWidth
                            source="short_description"
                            variant="outlined"
                            label={translate(
                                'manager.resources.packageGroupCollection.short_description'
                            )}
                            multiline={true}
                        />
                        <TextInput
                            fullWidth
                            source="long_description"
                            variant="outlined"
                            label={translate(
                                'manager.resources.packageGroupCollection.long_description'
                            )}
                            rows={3}
                            multiline={true}
                        />
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={4}>
                        <TextInput
                            fullWidth
                            source="usps"
                            variant="outlined"
                            label={translate('manager.resources.packageGroupCollection.usps')}
                            rows={5}
                            multiline={true}
                            helperText={translate(
                                'manager.resources.packageGroupCollection.usps_helper_text'
                            )}
                            className={classes.textArea}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <Box mb={2}>
                            <BooleanInput
                                row
                                source="active"
                                label={translate('manager.resources.general.published')}
                                defaultValue={true}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </FormTab>
            {/* Trailing slash (images/) below is to enable redirect location sync for tab */}
            {!!props.isEdit && (
                <FormTab label={translate('manager.tabs.images')} path="images/" syncWithLocation>
                    <PackageGroupCollectionGalleryItemList filter={{ collection_id }} />
                </FormTab>
            )}
            {props?.record?.groups?.length && (
                <FormTab label={translate('manager.tabs.packageGroup')} path="packageGroups">
                    <PackageGroupList filter={{ collection_id }} />
                </FormTab>
            )}
            <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                    setIsPristineFormCallback(props?.pristine)
                }}
            />
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default PackageGroupCollectionForm
