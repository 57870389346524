import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsSchoolTagsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SchoolsSchoolTagFilterInput>;
  orderBy?: Types.InputMaybe<Array<Types.SchoolsQuerySchoolTagsOrderByOrderByClause> | Types.SchoolsQuerySchoolTagsOrderByOrderByClause>;
}>;


export type SchoolsSchoolTagsQuery = { __typename?: 'Query', schoolsSchoolTags?: { __typename?: 'schoolsSchoolTagPaginator', data: Array<{ __typename?: 'schoolsSchoolTag', id: string, tag: string, created_at: any, updated_at: any, schools: Array<{ __typename?: 'schoolsSchool', id: string, name: string } | null | undefined> }> } | null | undefined };


export const SchoolsSchoolTagsDocument = gql`
    query SchoolsSchoolTags($filter: schoolsSchoolTagFilterInput, $orderBy: [schoolsQuerySchoolTagsOrderByOrderByClause!]) {
  schoolsSchoolTags(filter: $filter, orderBy: $orderBy) {
    data {
      id
      tag
      created_at
      updated_at
      schools {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSchoolsSchoolTagsQuery__
 *
 * To run a query within a React component, call `useSchoolsSchoolTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsSchoolTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsSchoolTagsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSchoolsSchoolTagsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsSchoolTagsQuery, SchoolsSchoolTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsSchoolTagsQuery, SchoolsSchoolTagsQueryVariables>(SchoolsSchoolTagsDocument, options);
      }
export function useSchoolsSchoolTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsSchoolTagsQuery, SchoolsSchoolTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsSchoolTagsQuery, SchoolsSchoolTagsQueryVariables>(SchoolsSchoolTagsDocument, options);
        }
export type SchoolsSchoolTagsQueryHookResult = ReturnType<typeof useSchoolsSchoolTagsQuery>;
export type SchoolsSchoolTagsLazyQueryHookResult = ReturnType<typeof useSchoolsSchoolTagsLazyQuery>;
export type SchoolsSchoolTagsQueryResult = Apollo.QueryResult<SchoolsSchoolTagsQuery, SchoolsSchoolTagsQueryVariables>;