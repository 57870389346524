import { Route } from 'react-router-dom'
import GalleryTemplate from './components/modelManager/templates/GalleryTemplate'
import HomepageTemplate from './components/modelManager/templates/HomepageTemplate'
import Configuration from './configuration/Configuration'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/model" render={() => <HomepageTemplate />} />,
    <Route exact path="/gallery" render={() => <GalleryTemplate />} />
]
