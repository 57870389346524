import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ModelSchemaForModelQueryVariables = Types.Exact<{
  model?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ModelSchemaForModelQuery = { __typename?: 'Query', configuratorModelSchema?: string | null | undefined };


export const ModelSchemaForModelDocument = gql`
    query ModelSchemaForModel($model: String) {
  configuratorModelSchema(model: $model)
}
    `;

/**
 * __useModelSchemaForModelQuery__
 *
 * To run a query within a React component, call `useModelSchemaForModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelSchemaForModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelSchemaForModelQuery({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useModelSchemaForModelQuery(baseOptions?: Apollo.QueryHookOptions<ModelSchemaForModelQuery, ModelSchemaForModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelSchemaForModelQuery, ModelSchemaForModelQueryVariables>(ModelSchemaForModelDocument, options);
      }
export function useModelSchemaForModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelSchemaForModelQuery, ModelSchemaForModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelSchemaForModelQuery, ModelSchemaForModelQueryVariables>(ModelSchemaForModelDocument, options);
        }
export type ModelSchemaForModelQueryHookResult = ReturnType<typeof useModelSchemaForModelQuery>;
export type ModelSchemaForModelLazyQueryHookResult = ReturnType<typeof useModelSchemaForModelLazyQuery>;
export type ModelSchemaForModelQueryResult = Apollo.QueryResult<ModelSchemaForModelQuery, ModelSchemaForModelQueryVariables>;