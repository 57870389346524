// import * as React from 'react'
import { memo, FC } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import { useRecordContext } from 'ra-core'

import sanitizeFieldRestProps from './sanitizeFieldRestProps'
import { PublicFieldProps, InjectedFieldProps, fieldPropTypes } from './types'
import { PropertyPath } from 'lodash'
import moment from 'moment'

const toLocaleStringSupportsLocales = (() => {
    // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    try {
        new Date().toLocaleString('i')
    } catch (error) {
        return error instanceof RangeError
    }
    return false
})()

/**
 * Display a date value as a locale string.
 *
 * Uses Intl.DateTimeFormat() if available, passing the locales and options props as arguments.
 * If Intl is not available, it outputs date as is (and ignores the locales and options props).
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 * @example
 * <DateFieldCustom source="published_at" />
 * // renders the record { id: 1234, published_at: new Date('2012-11-07') } as
 * <span>07/11/2012</span>
 *
 * <DateFieldCustom source="published_at" className="red" />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span class="red">07/11/2012</span>
 *
 * <DateFieldCustom source="share" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>Wednesday, November 7, 2012</span>
 *
 * <DateFieldCustom source="price" locales="fr-FR" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>mercredi 7 novembre 2012</span>
 */
export const DateFieldCustom: FC<DateFieldProps> = memo((props) => {
    const {
        className,
        emptyText,
        locales,
        options,
        showTime = false,
        source,
        transform,
        ...rest
    } = props
    const record = useRecordContext(props)
    if (!record) {
        return null
    }
    const value = get(record, source as PropertyPath)
    if (value == null || value === '') {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Typography>
        ) : null
    }

    const date = value instanceof Date ? value : moment(value)
    let dateString = showTime
        ? toLocaleStringSupportsLocales
            ? date.toLocaleString(locales, options)
            : date.toLocaleString()
        : toLocaleStringSupportsLocales
        ? date.toLocaleString(locales, options)
        : date.toLocaleString()

    transform && (dateString = transform(dateString))

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            style={{ whiteSpace: 'nowrap' }}
            {...sanitizeFieldRestProps(rest)}
        >
            {dateString}
        </Typography>
    )
})

DateFieldCustom.defaultProps = {
    addLabel: true
}

DateFieldCustom.propTypes = {
    // @ts-ignore
    ...Typography.propTypes,
    ...fieldPropTypes,
    locales: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    options: PropTypes.object,
    showTime: PropTypes.bool
}

DateFieldCustom.displayName = 'DateFieldCustom'

export interface DateFieldProps extends PublicFieldProps, InjectedFieldProps, TypographyProps {
    locales?: string | string[]
    options?: object
    showTime?: boolean
    transform?: CallableFunction
}

export default DateFieldCustom
