import { RJSFSchema } from '@rjsf/utils'
import { SchemaAttributes } from '../types'

/**
 * Deserializes a JSON string into an object containing key-value pairs for an RJSF form.
 *
 * @param serializedData - The JSON string to deserialize.
 * @param schema - The RJSF schema used to create the form.
 * @returns An object containing the deserialized key-value pairs.
 */
export const deserializeData = (serializedData: string, schema: RJSFSchema) => {
    const schemaAttributeDefinitions = (schema?.properties?.attributes as SchemaAttributes)?.items
        ?.dependencies?.key?.oneOf
    const parsedData = JSON.parse(serializedData || '[]')

    const formattedData = parsedData?.map(({ key, value }) => {
        const type = schemaAttributeDefinitions?.find((item) => {
            return JSON.stringify(item?.properties?.key || '')?.includes(key)
        })?.properties?.type?.default

        return {
            key,
            type,
            [`value_${type}`]: value
        }
    })

    return {
        attributes: formattedData || {}
    }
}
