import { useState } from 'react'
import { Create, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import SchoolTagForm from './SchoolTagForm'

const SchoolTagCreate = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} />
            <Create title={translate('manager.resources.tag.create')} {...props}>
                <SchoolTagForm
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Create>
        </>
    )
}

export default SchoolTagCreate
