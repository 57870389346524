import { MenuItem } from '@material-ui/core'

export const defaultRenderSuggestionItem = (dataTestId: string) => {
    return (suggestion) => {
        const itemContent = suggestion.display
        return (
            <MenuItem
                component="div"
                style={{ height: '100%', width: '100%' }}
                data-testid={`${dataTestId}-menu-item-${itemContent}`}
            >
                {itemContent}
            </MenuItem>
        )
    }
}
