import moment from 'moment'
// import * as dotenv from 'dotenv'

// Load environment variables from .env file
// dotenv.config()

export function formatDateString(inputDate: string, wTime: boolean = false): string {
    // Load environment variables from .env file
    // dotenv.config()

    // Parse the input date using moment
    const dateObject = moment(inputDate)

    // Check if the input date is valid
    if (!dateObject.isValid()) {
        throw new Error('Invalid date')
    }

    // Get the date format from environment variable or use a default ISO format
    let dateFormat = process.env.REACT_APP_DATE_FORMAT || 'YYYY-MM-DD'
    dateFormat += wTime ? ' ' + (process.env.REACT_APP_TIME_FORMAT || ' HH:mm') : ''

    // Format the date according to the specified format
    const formattedDate = dateObject.format(dateFormat)

    return formattedDate
}
