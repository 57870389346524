import { TopToolbar, useTranslate } from 'react-admin'
import { Button } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'

interface SchoolPeriodListToolbarProps {
    resource: string
    handleCreateCallback: () => void
}

const SchoolPeriodListToolbar = ({
    resource,
    handleCreateCallback
}: SchoolPeriodListToolbarProps) => {
    const translate = useTranslate()

    return (
        <TopToolbar>
            <Button color="primary" variant={'outlined'} onClick={handleCreateCallback}>
                <AddIcon />
                {translate(`manager.resources.${resource}.add`)}
            </Button>
        </TopToolbar>
    )
}

export default SchoolPeriodListToolbar
