export const formStyles = {
    fullWidth: { width: '100%!important' },
    buttonsAfterInputContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginTop: '8px',
        height: '100%'
    },
    buttonsAfterInput: {
        marginLeft: '8px'
    },
    flexStart: { alignSelf: 'flex-start', flexFlow: 'column wrap' },
    hidden: { display: 'none' }
}
