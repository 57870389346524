/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState } from 'react'

export type InitialStateType = {
    isUploading: boolean
    openGallery: boolean
    breadcrumbs: string
    hasUploaded: boolean
    updatedFolder: boolean
    selectedFile: string
    selectedFileExtension: string
}

export interface IInitialState {
    state: InitialStateType
    setGalleryState: (state: InitialStateType) => void
}

const initialState = {
    state: {
        isUploading: false,
        openGallery: false,
        breadcrumbs: '/',
        hasUploaded: false,
        updatedFolder: false,
        selectedFile: '',
        selectedFileExtension: ''
    },
    setGalleryState: (_state: InitialStateType) => {}
}

const GalleryContext = createContext(initialState)

const GalleryProvider: React.FC = ({ children }) => {
    const [state, setGalleryState] = useState(initialState.state)
    return (
        <GalleryContext.Provider value={{ state, setGalleryState }}>
            {children}
        </GalleryContext.Provider>
    )
}

export { GalleryProvider, GalleryContext, initialState }
