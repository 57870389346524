import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDeleteDirMutation } from 'apollo/configurator/mutations/DeleteDir.generated'
import { TreeItem } from 'react-sortable-tree'
import { IRemovableDir } from 'ts/interfaces'
import { deleteNodeInTree } from '../manager/helpers/TreeHelperFunctions'

interface IRemoveFolderPopup {
    removableItem: IRemovableDir
    setRemovableItem: ({ dir, parent_dir, extendedNode }: IRemovableDir) => void
    treeState: TreeItem[]
    setTreeState: (data: TreeItem[]) => void
}

const RemoveFolderPopup = ({
    removableItem,
    setRemovableItem,
    treeState,
    setTreeState
}: IRemoveFolderPopup) => {
    const [removeDir] = useDeleteDirMutation({})

    const handleClose = () => {
        setRemovableItem({ dir: null, parent_dir: null, extendedNode: null })
    }

    const handleRemove = async () => {
        const { dir, parent_dir } = removableItem

        if (dir && parent_dir) {
            await removeDir({
                variables: {
                    dir: dir,
                    parent_dir: parent_dir
                }
            })
                .then(() => {
                    removableItem.extendedNode &&
                        deleteNodeInTree(treeState, setTreeState, removableItem.extendedNode)
                })
                .catch((err) => console.error(err))
        }

        setRemovableItem({ dir: null, parent_dir: null, extendedNode: null })
    }
    return (
        <div>
            <Dialog
                open={removableItem.dir ? true : false}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to remove this node?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        By removing this folder it will be deleted from the server and this cannot
                        be reversed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Do not remove
                    </Button>
                    <Button onClick={handleRemove} color="secondary" variant="contained" autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default RemoveFolderPopup
