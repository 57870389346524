import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import {
    Button,
    createStyles,
    FormControl,
    FormGroup,
    Paper,
    TextField,
    Typography
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FieldProps, RJSFSchema, RJSFValidationError } from '@rjsf/utils/dist'
import Form from '@rjsf/material-ui'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { JSONSchema7Object } from 'json-schema'
import _ from 'lodash'

import { IChangeEvent } from '@rjsf/core'
import { ModelContext } from 'context/model/ModelContext'
import { getSchemaByNode } from 'utils/Schema'
import { GalleryContext } from 'context/gallery/GalleryContext'
import { ErrorContext } from 'context/error/ErrorContext'
import { useModelSchemaForModelQuery } from 'apollo/configurator/queries/ModelSchemaForModel.generated'
import { useNodeQuery } from 'apollo/configurator/queries/Node.generated'
import { useUpdateNodeMutation } from 'apollo/configurator/mutations/UpdateNode.generated'
import { useUiSchemaQuery } from 'apollo/configurator/queries/UiSchema.generated'
import { useHasNodesOfPresetsQuery } from 'apollo/configurator/queries/HasNodesOfPresets.generated'
import { useConfiguratorPresetQuery } from 'apollo/configurator/queries/ConfiguratorPreset.generated'
import WarningDialog from 'components/modelManager/molecules/popups/WarningDialog'
import { useTranslate } from 'react-admin'
import validator from '@rjsf/validator-ajv8'
import { UiSchema } from '@rjsf/utils'
import { lightTheme } from 'layout/theme/LightTheme'

interface IAttributedEditor {
    id: number
    parentId: string | undefined
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: '100%',
            maxHeight: 'calc(100vh - 280px)',
            overflow: 'scroll'
        },
        form: {
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            '& > .MuiFormControl-root': {
                height: '100%',
                overflowY: 'scroll',
                overflowX: 'hidden',
                maxHeight: 'calc(100vh - 360px)',
                borderTop: '1px solid #eaeaea'
            }
        }
    })
)

const affectedPresetsTableColumns = ['ID', 'Internal name', 'Published', 'Published code']

const AffectedPresetTable = ({ affectedPresetsIDs }) => (
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Affected presets table">
            <TableHead>
                <TableRow>
                    {affectedPresetsTableColumns.map((item) => (
                        <TableCell>{item}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {affectedPresetsIDs.map((id) => (
                    <AffectedPresetTableRow id={id?.preset_id} />
                ))}
            </TableBody>
        </Table>
    </TableContainer>
)

const AffectedPresetTableRow = ({ id }) => {
    const { data: affectedPreset, loading } = useConfiguratorPresetQuery({
        variables: {
            configuratorPresetId: id
        }
    })

    if (loading)
        return (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {[...Array(affectedPresetsTableColumns.length)].map((x, i) => (
                    <TableCell>
                        <Skeleton animation="wave" key={i} />
                    </TableCell>
                ))}
            </TableRow>
        )

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
                {affectedPreset?.configuratorPreset?.id}
            </TableCell>
            <TableCell>{affectedPreset?.configuratorPreset?.internal_name}</TableCell>
            <TableCell>{affectedPreset?.configuratorPreset?.published ? '✅' : '❌'}</TableCell>
            <TableCell>{affectedPreset?.configuratorPreset?.published_code}</TableCell>
        </TableRow>
    )
}

const AttributesEditor = ({ id, parentId }: IAttributedEditor) => {
    const translate = useTranslate()
    const { data: { configuratorNode: node } = {}, loading } = useNodeQuery({
        variables: { id: id as any },
        skip: !id
    })
    const { data: { configuratorUiSchema: uiSchema } = {}, loading: uiSchemaLoading } =
        useUiSchemaQuery()
    const { state: modelState, setState: setModelState } = useContext(ModelContext)
    const { data: { configuratorModelSchema: modelSchema } = {} } = useModelSchemaForModelQuery({
        variables: { model: modelState.currentModelCode }
    })
    const [updateNode] = useUpdateNodeMutation({
        variables: { id: 0 as any, node_attributes: '' }
    })
    const modelSchemaObject = modelSchema && JSON.parse(modelSchema)
    const body = node && JSON.parse(node?.node_attributes || '')
    const nodeSchema =
        modelSchemaObject &&
        node &&
        getSchemaByNode(modelSchemaObject, body.type, false, node.parent_id === null)
    const [formData, setFormData] = useState<unknown>({})
    const [uniqueKey, setUniqueKey] = useState<number>(0)
    const [formEvent, setFormEvent] = useState<IChangeEvent<any, RJSFSchema, any> | undefined>()
    const classes = useStyles()
    const { setErrorState } = useContext(ErrorContext)

    const [isActiveAffectedPresetsWarningPopup, setIsActiveAffectedPresetsWarningPopup] =
        useState(false)
    const { data: hasNodesOfPresets } = useHasNodesOfPresetsQuery({
        variables: {
            configuratorNodeId: node?.id
        }
    })

    // Filter uniq affected presets
    const affectedPresetsIDs = _.uniqBy(
        hasNodesOfPresets?.configuratorNode?.has_nodes_of_presets,
        'preset_id'
    )

    const submitForm = (e: IChangeEvent<any, RJSFSchema, any>) => {
        const variables: any = {
            id,
            node_attributes: JSON.stringify(e.formData)
        }
        if (typeof parentId == 'number') {
            variables.parent_id = parentId
        }
        updateNode({
            variables: variables
        })

        setModelState({
            ...modelState,
            updatedModel: true,
            hasChanges: true,
            hasEdited: true,
            showEditedMessage: true
        })

        // Reset the edited message but keep al the changes, necessary for export button state
        setTimeout(() => {
            return setModelState({
                ...modelState,
                updatedModel: true,
                hasChanges: true,
                hasEdited: true,
                showEditedMessage: false
            })
        }, 1000)
    }

    const handleCloseAffectedPresetsWarningPopup = () => {
        setIsActiveAffectedPresetsWarningPopup(false)
    }

    const handleContinueAffectedPresetsWarningPopup = () => {
        if (formEvent) submitForm(formEvent)
        setIsActiveAffectedPresetsWarningPopup(false)
    }

    const IconFieldTemplate = (props: FieldProps) => {
        const { state, setGalleryState } = useContext(GalleryContext)
        const [files, setFiles] = useState({ ...props.formData })
        const [selectedInput, setSelectedInput] = useState<string>()
        const idSchemaArray: string[] = []

        const formatFileName = (file: string) =>
            file.includes('//') ? file.replace('//', '') : file.replace('/', '')

        // Fetch all the different icon states that are available
        Object.keys(props.idSchema).forEach((key: string) => {
            if (key !== '$id') {
                idSchemaArray.push(key)
            }
        })

        useEffect(() => {
            if (!state.openGallery && state.selectedFile && selectedInput) {
                setFiles({
                    ...files,
                    [selectedInput]: formatFileName(state.selectedFile)
                })
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state])

        useEffect(() => {
            // Return the changed filenames to the json schema
            if (files) {
                return props.onChange(files)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [files, selectedInput])

        const handleChange = (name: string, event: ChangeEvent<HTMLInputElement>) => {
            return setFiles({
                ...files,
                [name]: formatFileName(event.target.value)
            })
        }

        return (
            <React.Fragment>
                <h3>{props?.name}</h3>
                <FormControl fullWidth={true}>
                    {idSchemaArray?.map((file: string) => {
                        const properties: JSONSchema7Object = props.schema
                            .properties as JSONSchema7Object
                        const property: JSONSchema7Object = properties[file] as JSONSchema7Object
                        return (
                            <FormGroup
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    margin: '10px 0px'
                                }}
                                key={file + Math.random()}
                            >
                                <TextField
                                    type="text"
                                    disabled={true}
                                    style={{ width: 350, margin: 0 }}
                                    label={property.description || file}
                                    value={files[file]}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        handleChange(file, event)
                                    }
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginLeft: 25 }}
                                    onClick={() => {
                                        setSelectedInput(file)
                                        return setGalleryState({ ...state, openGallery: true })
                                    }}
                                >
                                    Select file
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginLeft: 25 }}
                                    onClick={() => {
                                        setFiles({ ...files, [file]: '' })
                                    }}
                                >
                                    Remove
                                </Button>
                            </FormGroup>
                        )
                    })}
                </FormControl>
            </React.Fragment>
        )
    }

    const hiddenNodes = JSON.parse(process.env.REACT_APP_HIDDEN_CONFIGURATOR_NODES || '[]')
    const hiddenNodesUiSchema: UiSchema = {}
    for (const node of hiddenNodes) {
        const nodePath = node.split('.')
        let currentUiSchema = hiddenNodesUiSchema

        for (const pathSegment of nodePath) {
            currentUiSchema[pathSegment] = currentUiSchema[pathSegment] || {}
            currentUiSchema = currentUiSchema[pathSegment]
        }
        currentUiSchema['ui:widget'] = 'hidden'
    }

    let uiSchemaObject: UiSchema = (uiSchema && JSON.parse(uiSchema)) || {}
    if (uiSchemaObject) {
        uiSchemaObject.icon = {
            'ui:field': IconFieldTemplate
        }

        const defaults = {
            code: { 'ui:readonly': true },
            version: { 'ui:readonly': true }
        }
        uiSchemaObject = { ...uiSchemaObject, ...hiddenNodesUiSchema, ...defaults }
    }
    useEffect(() => {
        // To prevent form caching we need this to update the forms correctly.
        setUniqueKey(Date.now())
        setFormData(node && JSON.parse(node?.node_attributes || ''))

        return () => {
            setFormData({})
        }
    }, [node])

    const OnChangeHandler = (e: IChangeEvent<any, RJSFSchema, any>) => {
        setFormData(e.formData)
    }

    const OnSaveHandler = (e: IChangeEvent<any, RJSFSchema, any>) => {
        if (affectedPresetsIDs.length) {
            setFormEvent(e)
            setIsActiveAffectedPresetsWarningPopup(true)
            return
        }
        submitForm(e)
    }

    const TransformErrors = (errors: RJSFValidationError[]) => {
        return errors?.map((error: RJSFValidationError) => {
            console.warn(error)
            return error
        })
    }

    const OnErrorHandler = (errors: JSONSchema7Object[]) => {
        setErrorState({ hasError: true, message: errors[0].stack as string })
    }

    return loading || uiSchemaLoading ? (
        <CircularProgress />
    ) : (
        <>
            {nodeSchema && node?.node_attributes && (
                <>
                    <Typography
                        variant="h5"
                        style={{
                            marginBottom: 15,
                            fontWeight: 'bold',
                            zIndex: 98,
                            position: 'sticky',
                            top: '0',
                            backgroundColor: lightTheme.palette.background.default,
                            width: '100%',
                            height: '100px'
                        }}
                    >
                        {JSON.parse(node?.node_attributes)?.label ??
                            translate('manager.resources.model_manager.root_node')}
                    </Typography>
                    <Form
                        showErrorList={false}
                        key={uniqueKey}
                        schema={nodeSchema}
                        uiSchema={uiSchemaObject}
                        formData={formData}
                        onSubmit={(e) => OnSaveHandler(e)}
                        onChange={(e) => OnChangeHandler(e)}
                        onError={(e) => OnErrorHandler(e)}
                        transformErrors={TransformErrors}
                        validator={validator}
                        className={classes.form}
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{
                                marginBottom: 15,
                                zIndex: 99,
                                position: 'sticky',
                                top: '50px',
                                marginTop: '-100px'
                            }}
                        >
                            {translate('manager.resources.general.save')}
                        </Button>
                    </Form>
                    <WarningDialog
                        open={isActiveAffectedPresetsWarningPopup}
                        handleClose={handleCloseAffectedPresetsWarningPopup}
                        handleContinue={handleContinueAffectedPresetsWarningPopup}
                        maxWidth="md"
                        title="Affected presets"
                        content="Editing the node will affect on these presets:"
                        JSXContent={<AffectedPresetTable affectedPresetsIDs={affectedPresetsIDs} />}
                    />
                </>
            )}
        </>
    )
}

export default AttributesEditor
