import { UiSchema, RegistryWidgetsType } from '@rjsf/utils'
import { PathWidget } from 'components/PathWidget'

export const uiSchema: UiSchema = {
    attributes: {
        items: {
            value_path: {
                'ui:widget': 'pathWidget'
            }
        }
    }
}

export const widgets: RegistryWidgetsType = {
    pathWidget: PathWidget
}
