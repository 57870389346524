import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PackageGroupCollectionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PackageGroupCollectionsQuery = { __typename?: 'Query', packagesPackageGroupCollections?: { __typename?: 'packagesPackageGroupCollectionPaginator', data: Array<{ __typename?: 'packagesPackageGroupCollection', internal_name: string, id: string }> } | null | undefined };


export const PackageGroupCollectionsDocument = gql`
    query PackageGroupCollections {
  packagesPackageGroupCollections {
    data {
      internal_name
      id
    }
  }
}
    `;

/**
 * __usePackageGroupCollectionsQuery__
 *
 * To run a query within a React component, call `usePackageGroupCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageGroupCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageGroupCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePackageGroupCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<PackageGroupCollectionsQuery, PackageGroupCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackageGroupCollectionsQuery, PackageGroupCollectionsQueryVariables>(PackageGroupCollectionsDocument, options);
      }
export function usePackageGroupCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackageGroupCollectionsQuery, PackageGroupCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackageGroupCollectionsQuery, PackageGroupCollectionsQueryVariables>(PackageGroupCollectionsDocument, options);
        }
export type PackageGroupCollectionsQueryHookResult = ReturnType<typeof usePackageGroupCollectionsQuery>;
export type PackageGroupCollectionsLazyQueryHookResult = ReturnType<typeof usePackageGroupCollectionsLazyQuery>;
export type PackageGroupCollectionsQueryResult = Apollo.QueryResult<PackageGroupCollectionsQuery, PackageGroupCollectionsQueryVariables>;