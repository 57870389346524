import { useTranslate } from 'ra-core'
import { useState } from 'react'
import { ChoiceType } from 'types'
import { useCreateSchoolTagMutation } from 'apollo/school_manager/mutations/CreateSchoolTag.generated'
import { useSchoolsSchoolClassQuery } from 'apollo/school_manager/queries/SchoolsSchoolClass.generated'
import { useSchoolsSchoolTagsQuery } from 'apollo/school_manager/queries/SchoolsSchoolTags.generated'
import TagsAutocompleteInput from 'components/formfields/TagsAutocompleteInput'

/*
 * Wrapper component to provide the actual autocomplete input with data and functionality \
 */
const SchoolClassTagsInput = ({ schoolClassId }) => {
    const translate = useTranslate()
    const [initialValues, setInitialValues] = useState<ChoiceType[]>([])
    const [initialChoices, setInitialChoices] = useState<ChoiceType[]>([])
    const [createSchoolTag] = useCreateSchoolTagMutation()

    useSchoolsSchoolClassQuery({
        variables: {
            id: schoolClassId
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted: ({ schoolsSchoolClass }) => {
            if (schoolsSchoolClass) {
                setInitialValues(
                    schoolsSchoolClass.tags.map((tag) => ({
                        id: tag.id,
                        name: tag.tag
                    }))
                )
            }
        }
    })

    // Retrieve every possible tag to populate choices in the autocomplete
    useSchoolsSchoolTagsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted: ({ schoolsSchoolTags }) => {
            if (schoolsSchoolTags) {
                setInitialChoices(
                    schoolsSchoolTags.data.map((tag) => ({
                        id: tag.id,
                        name: tag.tag
                    }))
                )
            }
        }
    })

    // Prompt user for new tag
    const handleCreate = async () => {
        const newTag = prompt(translate('manager.resources.school.enter_new_tag'))
        if (!newTag) return

        const found = initialChoices.find((item) => item.name === newTag)
        if (found) return found

        // Save and return new tag
        const tag = await save(newTag)
        initialChoices && tag && initialChoices.push(tag)
        return tag
    }

    // Save new tag in db
    const save = async (newTag: string) => {
        return await createSchoolTag({
            variables: { tag: newTag }
        })
            .then(async ({ data }) => {
                return {
                    id: data?.createSchoolTag?.id ?? '',
                    name: data?.createSchoolTag?.tag ?? ''
                }
            })
            .catch((error) => console.error('createSchoolClassTag:', error))
    }

    return (
        <TagsAutocompleteInput
            source="tags"
            initialValues={initialValues}
            initialChoices={initialChoices}
            onCreate={handleCreate}
        />
    )
}
export default SchoolClassTagsInput
