import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { WidgetProps } from '@rjsf/utils'
import { useConfiguratorModelTreeStructureQuery } from 'apollo/package_manager/queries/ConfiguratorModelTreeStructure.generated'
import { useState } from 'react'
import CheckboxTree from 'react-checkbox-tree'
import { useRootNodesQuery } from 'apollo/configurator/queries/RootNodes.generated'
import { SelectInput, useTranslate } from 'react-admin'
import { ChoiceType } from 'types'

export const PathWidget = (props: WidgetProps) => {
    const [rootModel, setRootModel] = useState<string | null>(null)
    const [expanded, setExpanded] = useState<string[]>([])
    const translate = useTranslate()
    const { data: { configuratorNodes: nodes } = {} } = useRootNodesQuery()
    const choices = nodes?.map(
        (item): ChoiceType => ({
            id: item.code ?? '',
            name: item.code ?? ''
        })
    )

    const { data: { configuratorModelTreeStructure } = {}, error } =
        useConfiguratorModelTreeStructureQuery({
            variables: {
                code: rootModel || ''
            },
            skip: !rootModel
        })

    const onExpand = (expanded) => {
        setExpanded(expanded)
    }

    if (error) {
        return <>{`no model tree found for ${rootModel}`}</>
    }

    return choices ? (
        <>
            <SelectInput
                fullWidth
                variant="outlined"
                source="code"
                choices={choices ?? []}
                onChange={(event) => setRootModel(event?.target?.value)}
            />

            {configuratorModelTreeStructure && (
                <>
                    <CheckboxTree
                        showNodeIcon={false}
                        showExpandAll={false}
                        noCascade={true}
                        checkModel="all"
                        nodes={JSON.parse(configuratorModelTreeStructure || '[]')}
                        checked={new Array(props.value)}
                        expanded={expanded}
                        onCheck={(_, node) => props.onChange(node.value)}
                        onExpand={onExpand}
                    />

                    <Box sx={{ mt: 2 }}>
                        <Divider />

                        <Typography variant="caption">
                            <strong>
                                {translate(
                                    'manager.resources.rule.actions_tab.path_tree.path_hint'
                                )}
                                :
                            </strong>

                            {props.value}
                        </Typography>
                    </Box>
                </>
            )}
        </>
    ) : (
        <CircularProgress />
    )
}
