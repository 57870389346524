import { useState } from 'react'
import { useSelector } from 'react-redux'
import { DashboardMenuItem, MenuProps, ReduxState, useTranslate } from 'react-admin'
import cx from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import ArticleIcon from '@mui/icons-material/Article'
import ImageIcon from '@mui/icons-material/Image'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { AppState } from '../types'
import schoolClass from '../resources/schoolClass'
import education from '../resources/education'
import schoolTag from '../resources/schoolTag'
import packages from '../resources/packages'
import packageGroup from '../resources/packageGroup'
import schoolYear from '../resources/schoolYear'
import collections from '../resources/packageGroupCollection'
import schools from '../resources/schools'
import presets from '../resources/presets'
import personalization from '../resources/personalizationRule'
import schoolClassRepresentative from '../resources/schoolClassRepresentative'
import SubMenu from './SubMenu'
import { MenuItemLinkWrapper } from 'components/common/MenuItemLinkWrapper'

type MenuName = 'menuPackages' | 'menuSchools'

const Menu = ({ dense = false }: MenuProps) => {
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen)
    useSelector((state: AppState) => state.theme) // force rerender on theme change
    const classes = useStyles()
    const translate = useTranslate()
    const [state, setState] = useState({
        menuPackages: false,
        menuSchools: false
    })
    const handleToggle = (menu: MenuName) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }))
    }

    return (
        <div
            className={cx(classes.root, {
                [classes.open]: open,
                [classes.closed]: !open
            })}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggleCallback={() => handleToggle('menuPackages')}
                isOpen={state.menuPackages}
                name={translate('manager.menu.package_manager')}
                icon={<ChevronRightIcon />}
                dense={dense}
            >
                <MenuItemLinkWrapper
                    name="Package"
                    to={{
                        pathname: '/packageGroupCollection',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.collections`)}
                    leftIcon={<collections.icon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'PackageGroup'}
                    to={{
                        pathname: '/packageGroup',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.packageGroup`)}
                    leftIcon={<packageGroup.icon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'PackageGroupCollection'}
                    to={{
                        pathname: '/Package',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.packages`)}
                    leftIcon={<packages.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggleCallback={() => handleToggle('menuSchools')}
                isOpen={state.menuSchools}
                name={translate('manager.menu.school_manager')}
                icon={<ChevronRightIcon />}
                dense={dense}
            >
                <MenuItemLinkWrapper
                    name={'School'}
                    to={{
                        pathname: '/school',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.schools`)}
                    leftIcon={<schools.icon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'SchoolClass'}
                    to={{
                        pathname: '/schoolClass',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.classes`)}
                    leftIcon={<schoolClass.icon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'SchoolYear'}
                    to={{
                        pathname: '/schoolYear',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.years`)}
                    leftIcon={<schoolYear.icon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'Education'}
                    to={{
                        pathname: '/education',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.educations`)}
                    leftIcon={<education.icon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'SchoolTag'}
                    to={{
                        pathname: '/schoolTag',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.tags`)}
                    leftIcon={<schoolTag.icon />}
                    dense={dense}
                />
                <MenuItemLinkWrapper
                    name={'SchoolClassRepresentative'}
                    to={{
                        pathname: '/schoolClassRepresentative',
                        state: { _scrollToTop: true }
                    }}
                    primaryText={translate(`manager.menu.contactPersons`)}
                    leftIcon={<schoolClassRepresentative.icon />}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLinkWrapper
                name={'Preset'}
                to={{
                    pathname: '/preset',
                    state: { _scrollToTop: true }
                }}
                primaryText={translate(`manager.menu.presets`)}
                leftIcon={<presets.icon />}
                dense={dense}
            />
            <MenuItemLinkWrapper
                name={'Rule'}
                to={{
                    pathname: '/rule',
                    state: { _scrollToTop: true }
                }}
                primaryText={translate(`manager.menu.personalization`)}
                leftIcon={<personalization.icon />}
                dense={dense}
            />
            <MenuItemLinkWrapper
                name={'Model'}
                to={{
                    pathname: '/model',
                    state: { _scrollToTop: true }
                }}
                primaryText={translate(`manager.menu.model`)}
                leftIcon={<ArticleIcon />}
                dense={dense}
            />
            <MenuItemLinkWrapper
                name={'Gallery'}
                to={{
                    pathname: '/gallery',
                    state: { _scrollToTop: true }
                }}
                primaryText={translate(`manager.menu.gallery`)}
                leftIcon={<ImageIcon />}
                dense={dense}
            />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    open: {
        width: 220
    },
    closed: {
        width: 55
    }
}))

export default Menu
