import { Theme, withStyles } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'

const GalleryPopupContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '1fr 3fr'
    }
}))(MuiDialogContent)

export default GalleryPopupContent
