import { useState } from 'react'
import PropTypes from 'prop-types'
import { withTypes } from 'react-final-form'

import { Button, Card, CardActions, CircularProgress } from '@material-ui/core'
import { createTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { Notification, useTranslate } from 'react-admin'
import { useAuth0 } from '@auth0/auth0-react'

import Logo from './Logo'
import { darkTheme } from './theme/DarkTheme'
import { lightTheme } from './theme/LightTheme'
import { proudNerdsTheme } from './theme/ProudNerdsTheme'
import backgroundimage from '../img/background.jpg'
import { Head } from 'Head'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: `url(${backgroundimage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    card: {
        minWidth: 300,
        marginTop: '6em'
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        backgroundColor: 'transparent'
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500]
    },
    form: {
        padding: '0 1em 1em 1em'
    },
    input: {
        marginTop: '1em'
    },
    actions: {
        padding: '0 1em 1em 1em'
    }
}))

interface FormValues {
    username?: string
    password?: string
    permissions?: string
}

const { Form } = withTypes<FormValues>()

const Login = () => {
    const [loading] = useState(false)
    const translate = useTranslate()
    const classes = useStyles()
    const { loginWithRedirect } = useAuth0()

    const handleSubmit = () => {
        loginWithRedirect()
    }

    return (
        <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Logo />
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && <CircularProgress size={25} thickness={2} />}
                                    {translate('ra.auth.sign_in')}
                                </Button>
                            </CardActions>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    )
}

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string
}

const theme = () => {
    let newTheme
    switch (process.env.REACT_APP_THEME) {
        case 'dark':
            newTheme = darkTheme
            break
        case 'light':
            newTheme = lightTheme
            break
        case 'proudNerds':
            newTheme = proudNerdsTheme
            break
        default:
            newTheme = lightTheme
    }

    return newTheme
}

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={createTheme(theme())}>
        <Head />
        <Login {...props} />
    </ThemeProvider>
)

export default LoginWithTheme
