import { useContext, useEffect, useState } from 'react'
import { required, TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'

import { Box, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { GalleryContext } from 'context/gallery/GalleryContext'
import { formStyles } from 'layout/styles/formStyles'

interface UploadFieldProps {
    source: string
    label: string
    data: any
    url: string
    validateRequired?: boolean
}

const useStyles = makeStyles({
    ...formStyles,
    image: {
        maxWidth: '100%'
    }
})

const UploadField = ({ source, label, data, url, validateRequired }: UploadFieldProps) => {
    const EMPTY_FILES_OBJ = { [source]: '' }
    const REACT_APP_MEDIA_URL = process.env.REACT_APP_MEDIA_URL

    const { state: galleryState, setGalleryState } = useContext(GalleryContext)
    const [files, setFiles] = useState({ [source]: data })
    const [currentSource, setCurrentSource] = useState('')
    const [showPreview, toggleShowPreview] = useState(false)
    const { change } = useForm()
    const formStyle = useStyles()
    const translate = useTranslate()

    const formatFileName = (file: string) => {
        return file.includes('//') ? file.replace('//', '') : file.replace('/', '')
    }

    // If a file is selected from the gallery, update the local file state
    // Using a currentSource to make sure we update the right input field
    useEffect(() => {
        if (!galleryState.openGallery && galleryState.selectedFile && currentSource === source) {
            setFiles({
                [source]: `${formatFileName(galleryState.selectedFile)}${
                    galleryState.selectedFileExtension
                }`
            })
        }
    }, [currentSource, galleryState, source])

    // Update formfield data whenever local file state changes
    useEffect(() => {
        if (files) change(source, files[source])
    }, [change, files, source])

    // Open gallery
    const handleSelectFileClick = () => {
        setCurrentSource(source)
        setGalleryState({ ...galleryState, openGallery: true })
    }

    // Remove image
    const handleRemoveClick = () => {
        setFiles(EMPTY_FILES_OBJ)
    }

    return (
        <>
            <Grid container className={formStyle.fullWidth}>
                <Grid item md={5}>
                    <TextInput
                        disabled
                        fullWidth
                        variant="outlined"
                        source={source}
                        label={label}
                        value={files[source]}
                        validate={!!validateRequired ? requiredValidate : undefined}
                    />
                </Grid>
                <Grid item md={5} className={formStyle.buttonsAfterInputContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSelectFileClick}
                        className={formStyle.buttonsAfterInput}
                    >
                        {translate('manager.resources.general.upload.select_file')}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleRemoveClick}
                        className={formStyle.buttonsAfterInput}
                    >
                        {translate('manager.resources.general.upload.remove')}
                    </Button>
                    {!!REACT_APP_MEDIA_URL && url && (
                        <Button
                            onClick={() => toggleShowPreview(!showPreview)}
                            className={formStyle.buttonsAfterInput}
                        >
                            {!!showPreview
                                ? translate('manager.resources.general.upload.hide_preview')
                                : translate('manager.resources.general.upload.show_preview')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            {!!showPreview && !!REACT_APP_MEDIA_URL && (
                <Box mb={2}>
                    <img className={formStyle.image} src={REACT_APP_MEDIA_URL + url} alt={source} />
                </Box>
            )}
        </>
    )
}

const requiredValidate = [required()]

export default UploadField
