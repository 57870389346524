export const handleImportModel = (modelCodes, setDuplicateCode, setPreImportModel) => {
    var input = document.createElement('input')
    input.accept = 'application/json'
    input.type = 'file'

    input.onchange = (e: any) => {
        // getting a hold of the file reference
        var file = e?.target?.files[0] ?? null

        // setting up the reader
        var reader = new FileReader()
        reader.readAsText(file, 'UTF-8')

        // here we tell the reader what to do when it's done reading...
        reader.onload = (readerEvent) => {
            var content = readerEvent?.target?.result // this is the content!
            if (typeof content === 'string') {
                const obj = JSON.parse(content)

                if (modelCodes?.includes(obj.model.code)) {
                    console.log(modelCodes, obj.model.code)
                    setDuplicateCode(true)
                } else {
                    setPreImportModel(obj)
                }
            }
        }
    }

    input.click()
}

export const executeImport = (content, importModelMutation) => {
    importModelMutation({
        variables: {
            json: content
        }
    }).then((res) => {
        window.location.reload()
    })
}

export const handleConfirm = (downloadModel, id, inclPresets) => {
    downloadModel({
        variables: {
            rootId: '' + id, // value for 'rootId's
            includePresets: inclPresets // value for 'includePresets'
        }
    })
}

export const exportData = (data) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`
    const link = document.createElement('a')
    link.href = jsonString
    link.download = 'data.json'

    link.click()
}
