import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, DeleteButton, SaveButton, Button, useRedirect, useTranslate } from 'react-admin'

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 auto',
        width: '100%'
    },
    buttonMargin: {
        marginRight: '1rem'
    },
    buttonRight: {
        margin: '0 0 0 auto'
    }
})

const PackageGroupGalleryItemEditToolbar = (props: any) => {
    const backUrl = `/PackageGroup/${props.record?.group_id}/images`
    const classes = useToolbarStyles()
    const redirect = useRedirect()
    const translate = useTranslate()
    const goBack = () => {
        redirect('edit', backUrl, '')
    }

    return (
        <Toolbar {...props} className={classes.toolbar}>
            <Button
                className={classes.buttonMargin}
                onClick={goBack}
                label={translate('manager.resources.general.go_back')}
            />
            <SaveButton />
            <DeleteButton className={classes.buttonRight} redirect={backUrl + '/'} />
        </Toolbar>
    )
}

export default PackageGroupGalleryItemEditToolbar
