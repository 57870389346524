import { TextInput, BooleanInput, useTranslate, required, TabbedForm, FormTab } from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { FormSpy } from 'react-final-form'

import DefaultToolbar from '../../components/common/DefaultToolbar'

const SchoolYearForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()

    return (
        <TabbedForm {...props} toolbar={<DefaultToolbar />} redirect={props.basePath}>
            <FormTab label={translate('manager.tabs.general')} path="/">
                <Grid container>
                    <Grid item md={7}>
                        <Grid item md={12}>
                            <Typography variant="h6" gutterBottom>
                                {translate('manager.resources.schoolYear.year')}
                            </Typography>
                        </Grid>
                        <TextInput
                            fullWidth
                            source="name"
                            variant="outlined"
                            label={translate('manager.resources.schoolYear.name')}
                            validate={requiredValidate}
                        />
                        <BooleanInput
                            source="active"
                            label={translate('manager.resources.general.published')}
                            defaultValue={true}
                        />
                    </Grid>
                </Grid>
            </FormTab>
            <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                    setIsPristineFormCallback(props?.pristine)
                }}
            />
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default SchoolYearForm
