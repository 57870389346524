import ReactDOM from 'react-dom'
import * as dotenv from 'dotenv'

import App from './App'
import './index.css'
import { Auth0Provider } from '@auth0/auth0-react'
import { ApolloProvider } from '@apollo/client'
import ApolloClient from './lib/init-apollo'

dotenv.config()

const client = ApolloClient()

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
    >
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </Auth0Provider>,
    document.getElementById('root')
)
