import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConfiguratorPresetQueryVariables = Types.Exact<{
  configuratorPresetId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type ConfiguratorPresetQuery = { __typename?: 'Query', configuratorPreset?: { __typename?: 'configuratorPreset', id: string, internal_name: string, published: boolean, published_code?: string | null | undefined } | null | undefined };


export const ConfiguratorPresetDocument = gql`
    query ConfiguratorPreset($configuratorPresetId: ID) {
  configuratorPreset(id: $configuratorPresetId) {
    id
    internal_name
    published
    published_code
  }
}
    `;

/**
 * __useConfiguratorPresetQuery__
 *
 * To run a query within a React component, call `useConfiguratorPresetQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguratorPresetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguratorPresetQuery({
 *   variables: {
 *      configuratorPresetId: // value for 'configuratorPresetId'
 *   },
 * });
 */
export function useConfiguratorPresetQuery(baseOptions?: Apollo.QueryHookOptions<ConfiguratorPresetQuery, ConfiguratorPresetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfiguratorPresetQuery, ConfiguratorPresetQueryVariables>(ConfiguratorPresetDocument, options);
      }
export function useConfiguratorPresetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfiguratorPresetQuery, ConfiguratorPresetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfiguratorPresetQuery, ConfiguratorPresetQueryVariables>(ConfiguratorPresetDocument, options);
        }
export type ConfiguratorPresetQueryHookResult = ReturnType<typeof useConfiguratorPresetQuery>;
export type ConfiguratorPresetLazyQueryHookResult = ReturnType<typeof useConfiguratorPresetLazyQuery>;
export type ConfiguratorPresetQueryResult = Apollo.QueryResult<ConfiguratorPresetQuery, ConfiguratorPresetQueryVariables>;