import { useState } from 'react'
import { Edit, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import SchoolYearForm from './SchoolYearForm'

const SchoolYearEdit = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} />
            <Edit
                title={translate('manager.resources.schoolYear.edit')}
                undoable={false}
                {...props}
            >
                <SchoolYearForm
                    isEdit
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Edit>
        </>
    )
}

export default SchoolYearEdit
