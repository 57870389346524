import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type IndexSchoolMutationVariables = Types.Exact<{
  schoolId: Types.Scalars['String'];
}>;


export type IndexSchoolMutation = { __typename?: 'Mutation', indexSchool?: { __typename?: 'IndexResult', school_id: string, status: string } | null | undefined };


export const IndexSchoolDocument = gql`
    mutation IndexSchool($schoolId: String!) {
  indexSchool(schoolId: $schoolId) {
    school_id
    status
  }
}
    `;
export type IndexSchoolMutationFn = Apollo.MutationFunction<IndexSchoolMutation, IndexSchoolMutationVariables>;

/**
 * __useIndexSchoolMutation__
 *
 * To run a mutation, you first call `useIndexSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexSchoolMutation, { data, loading, error }] = useIndexSchoolMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useIndexSchoolMutation(baseOptions?: Apollo.MutationHookOptions<IndexSchoolMutation, IndexSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexSchoolMutation, IndexSchoolMutationVariables>(IndexSchoolDocument, options);
      }
export type IndexSchoolMutationHookResult = ReturnType<typeof useIndexSchoolMutation>;
export type IndexSchoolMutationResult = Apollo.MutationResult<IndexSchoolMutation>;
export type IndexSchoolMutationOptions = Apollo.BaseMutationOptions<IndexSchoolMutation, IndexSchoolMutationVariables>;