import { TextInput, BooleanInput, useTranslate, required, TabbedForm, FormTab } from 'react-admin'
import { useNotify } from 'ra-core'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

// MUI
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import ConfiguratorNodesSelect from '../../components/formfields/ConfiguratorNodesSelect'
import { formStyles } from 'layout/styles/formStyles'
import PresetManager from './PresetManager'
import PresetToolbar from './PresetToolbar'
import { FormSpy } from 'react-final-form'

const useStyles = makeStyles(formStyles)

const PresetForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()
    const formStyle = useStyles()
    const notify = useNotify()
    const publishedCode = props?.record?.published_code
    const presetManagerPortal = document.getElementById('preset-manager-portal')
    const copyToClipboard = () => {
        navigator.clipboard.writeText(publishedCode)
        notify('manager.resources.general.copied_to_clipboard', {
            type: 'info',
            undoable: false,
            messageArgs: {},
            autoHideDuration: 1000
        })
    }

    return (
        <PresetFormWrapper>
            <TabbedForm
                {...props}
                toolbar={<PresetToolbar isWithActionsFlush {...props} />}
                redirect={props.basePath}
            >
                <FormTab label={translate('manager.tabs.general')} path="/">
                    <Grid container>
                        <Grid item md={7}>
                            <Grid item md={12}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('manager.resources.preset.preset')}
                                </Typography>
                            </Grid>
                            <TextInput
                                fullWidth
                                source="internal_name"
                                variant="outlined"
                                label={translate('manager.resources.preset.internal_name')}
                                validate={requiredValidate}
                            />
                            <TextInput
                                fullWidth
                                source="public_name"
                                variant="outlined"
                                label={translate('manager.resources.preset.public_name')}
                                validate={requiredValidate}
                            />
                            <ConfiguratorNodesSelect disabled={props.isEdit} required />
                            <BooleanInput
                                source="active"
                                label={translate('manager.resources.general.published')}
                                defaultValue={true}
                            />
                            {/*
                             * Temp fix
                             * https://riffonline.atlassian.net/browse/CLS-2470
                             * // TODO: Fix in BE and remove
                             */}
                            <BooleanInput
                                source="published"
                                defaultValue={false}
                                className={formStyle.hidden}
                            />
                        </Grid>
                        <Grid item md={1} />
                        <Grid item md={4}>
                            <Box mt={2} mb={2}>
                                <Typography variant="h6" gutterBottom>
                                    {`${translate('manager.resources.preset.published_code')}:`}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {publishedCode ? `${publishedCode} ` : '-'}
                                    {publishedCode && (
                                        <Button variant="text" onClick={() => copyToClipboard()}>
                                            <ContentCopyIcon />
                                        </Button>
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </FormTab>
                {props?.record?.id && (
                    <FormTab label={translate('manager.tabs.model')} path="model">
                        <div id="preset-manager-portal" className="preset-manager-portal" />
                    </FormTab>
                )}
                <FormSpy
                    subscription={{ pristine: true }}
                    onChange={(props) => {
                        setIsPristineFormCallback(props?.pristine)
                    }}
                />
            </TabbedForm>
            {presetManagerPortal &&
                // We move the Presetmanager from outside of the main form, to inside of the main form with react portal
                // This needs to be done to prevent event bubbling. Otherwise the preset manager submit button will never fire the submitEvent when clicked
                ReactDOM.createPortal(<PresetManager {...props} />, presetManagerPortal)}
        </PresetFormWrapper>
    )
}

const PresetFormWrapper = styled.section`
    .preset-manager-portal {
        width: auto;
    }
`

const requiredValidate = [required()]

export default PresetForm
