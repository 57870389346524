import { BooleanInput, SearchInput } from 'react-admin'

const PackageGroupCollectionListFilter = [
    <SearchInput
        variant="outlined"
        placeholder="Search internal name"
        source="internal_name"
        alwaysOn
    />,
    <SearchInput
        variant="outlined"
        placeholder="Search public name"
        source="public_name"
        alwaysOn
    />,
    <BooleanInput source="active" label="Published" variant="outlined" defaultValue={true} />
]

export default PackageGroupCollectionListFilter
