import { useEffect, useState } from 'react'
import {
    SaveButton,
    Toolbar,
    useDataProvider,
    useNotify,
    useRedirect,
    useRefresh,
    useTranslate
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

import { useCreateSchoolSchoolTagMutation } from 'apollo/school_manager/mutations/CreateSchoolSchoolTag.generated'
import { useDeleteSchoolSchoolTagMutation } from 'apollo/school_manager/mutations/DeleteSchoolSchoolTag.generated'
import { useSchoolsSchoolQuery } from 'apollo/school_manager/queries/SchoolsSchool.generated'

import { ChoiceType } from 'types'

export const styles = {
    toolbar: { marginTop: 0 },
    button: { marginRight: '1rem' }
}
const useStyles = makeStyles(styles)

const SchoolEditToolbar = (props) => {
    const translate = useTranslate()
    const classes = useStyles()
    const redirect = useRedirect()
    const refresh = useRefresh()
    const notify = useNotify()
    const dataProvider = useDataProvider()
    const [createSchoolSchoolTag] = useCreateSchoolSchoolTagMutation()
    const [deleteSchoolSchoolTag] = useDeleteSchoolSchoolTagMutation()
    const [initialTags, setInitialTags] = useState<ChoiceType[]>([])

    // Retreive all tags related to this schoolId
    const { data: schoolTags } = useSchoolsSchoolQuery({
        variables: {
            schoolId: props.record.id
        }
    })

    // Format and set values
    useEffect(() => {
        if (schoolTags?.schoolsSchool?.tags) {
            setInitialTags(
                schoolTags.schoolsSchool.tags.map(
                    (item): ChoiceType => ({
                        id: item.id,
                        name: item.tag
                    })
                )
            )
        }
    }, [schoolTags])

    // Custom save so that we can mutate the tags seperately
    const handleSave = (values, redirectAfterSave) => {
        const currentTags = values.tags
        const schoolId = values.id

        // DELETED values
        const deletedDiff =
            initialTags &&
            initialTags.filter((a) => {
                return !currentTags.find((b) => a.id === b.id)
            })

        // ADDED values
        const addedDiff =
            currentTags &&
            currentTags.filter((a) => {
                return !initialTags.find((b) => a.id === b.id)
            })

        if (deletedDiff) {
            // Delete relation for removed tags
            deletedDiff.forEach((tag) => {
                deleteSchoolSchoolTag({
                    variables: {
                        schoolId: parseInt(schoolId),
                        schoolTagId: parseInt(tag.id)
                    }
                })
            })
        }

        if (addedDiff) {
            // Add relation for new tags
            addedDiff.forEach((tag) => {
                createSchoolSchoolTag({
                    variables: {
                        schoolId: parseInt(schoolId),
                        schoolTagId: parseInt(tag.id)
                    }
                })
            })
        }

        // Update school
        dataProvider
            .update(props.resource, {
                id: values.id,
                data: values,
                previousData: props?.record
            })
            .then(() => {
                if (redirectAfterSave) {
                    redirect(props.basePath)
                    refresh()
                }
            })
            .finally(() =>
                notify(
                    `${props.resource} ${translate(
                        'manager.resources.general.edited_successfully'
                    )}`
                )
            )
    }

    return (
        <Toolbar {...props} className={classes.toolbar}>
            <SaveButton
                className={classes.button}
                label={translate('manager.resources.general.save')}
                redirect={false}
                onSave={(values) => {
                    handleSave(values, false)
                }}
            />
            <SaveButton
                label={translate('manager.resources.general.save_and_go_back')}
                redirect="list"
                color="secondary"
                icon={<SaveAltIcon />}
                onSave={(values) => {
                    handleSave(values, true)
                }}
            />
        </Toolbar>
    )
}

export default SchoolEditToolbar
