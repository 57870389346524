import { useState } from 'react'
import { FormDataConsumer, required, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'

import { ChoiceType, TProductType } from 'types'
import ConfiguratorOption from 'resources/packages/common/ConfiguratorOption'
import ConfiguratorNodesSelect from './ConfiguratorNodesSelect'
import ConfiguratorPresetsSelect from './ConfiguratorPresetsSelect'
import { PackagesPackageProductType } from 'apollo/types.generated'
import { usePackageAvailableProductsQuery } from 'apollo/package_manager/queries/PackageAvailableProducts.generated'

interface AvailableProductsSelectProps {
    disabled?: boolean
    required?: boolean
    isEdit?: boolean
    isPackage?: boolean
}

const AvailableProductsSelect = ({
    disabled,
    required,
    isEdit = false,
    isPackage
}: AvailableProductsSelectProps) => {
    const translate = useTranslate()
    const [selectedRootModelCode, setSelectedRootModelCode] = useState<string | null>(null)
    const { change } = useForm()

    const { data: { packagesAvailableProducts: availableProducts } = {}, loading } =
        usePackageAvailableProductsQuery()

    // Map and sort available products
    const mappedAvailableProducts =
        availableProducts &&
        availableProducts
            .map(
                (item): ChoiceType => ({
                    id: item?.sku ?? '',
                    name: `${item?.name} | ${item?.sku}`
                })
            )
            .sort((a: ChoiceType, b: ChoiceType) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })

    const onRootModelSelectorChangeHandler = (event) => {
        setSelectedRootModelCode(event.target.value)
    }

    const onPresetSelectorChangeHandler = (event) => {}

    return (
        <>
            <SelectInput
                fullWidth
                disabled={disabled || loading}
                helperText={loading ? translate('manager.loading') : null}
                source="sku"
                variant="outlined"
                choices={(mappedAvailableProducts && mappedAvailableProducts) ?? []}
                validate={required && requiredValidate}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    const selectedProduct = availableProducts?.find(
                        (product) => product?.sku === formData.sku
                    )
                    const selectedProductType = isEdit ? formData?.type : selectedProduct?.type
                    let isSimpleSelectedProductType: boolean

                    if (isEdit) {
                        isSimpleSelectedProductType =
                            (selectedProductType as PackagesPackageProductType) === 'simple' ||
                            (selectedProductType as PackagesPackageProductType) === 'configurable'
                                ? true
                                : false
                        setSelectedRootModelCode(formData?.root_model)
                    } else {
                        isSimpleSelectedProductType =
                            (selectedProductType as TProductType) === 'SimpleProduct' ||
                            (selectedProductType as TProductType) === 'ConfigurableProduct'
                                ? true
                                : false
                        change(
                            'type',
                            selectedProduct?.type
                                .replace('Product', '')
                                .toLowerCase() as PackagesPackageProductType
                        )
                    }

                    return (
                        <>
                            {isPackage && (
                                <>
                                    <TextInput
                                        fullWidth
                                        variant="outlined"
                                        label={translate(
                                            'manager.resources.package.products.comparison_label'
                                        )}
                                        source="comparison_label"
                                        validate={requiredValidate}
                                    />
                                    <TextInput
                                        fullWidth
                                        variant="outlined"
                                        label={translate(
                                            'manager.resources.package.products.short_description'
                                        )}
                                        source="short_description"
                                    />
                                </>
                            )}
                            {!isSimpleSelectedProductType && (
                                <>
                                    {formData.sku && (
                                        <ConfiguratorNodesSelect
                                            source="root_model"
                                            label={translate(
                                                'manager.resources.package.products.root_model'
                                            )}
                                            choiseValueIsID
                                            required
                                            setIsSelectedRootModel={
                                                onRootModelSelectorChangeHandler
                                            }
                                        />
                                    )}
                                    {selectedRootModelCode && (
                                        <>
                                            <ConfiguratorPresetsSelect
                                                label={translate(
                                                    'manager.resources.package.products.preset_code'
                                                )}
                                                isFilteredPresetsByModelCode
                                                parentModelCode={selectedRootModelCode}
                                                setIsSelectedPreset={onPresetSelectorChangeHandler}
                                            />
                                            <ConfiguratorOption
                                                initialData={formData?.included_options}
                                                selectedRootModelCode={selectedRootModelCode}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )
                }}
            </FormDataConsumer>
        </>
    )
}

const requiredValidate = [required()]

export default AvailableProductsSelect
