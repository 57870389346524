import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DownloadModelQueryVariables = Types.Exact<{
  rootId: Types.Scalars['ID'];
  includePresets: Types.Scalars['Boolean'];
}>;


export type DownloadModelQuery = { __typename?: 'Query', configuratorExportJSONModel?: { __typename?: 'configuratorJSONExport', model?: string | null | undefined, presets?: string | null | undefined } | null | undefined };


export const DownloadModelDocument = gql`
    query DownloadModel($rootId: ID!, $includePresets: Boolean!) {
  configuratorExportJSONModel(node_id: $rootId, include_presets: $includePresets) {
    model
    presets
  }
}
    `;

/**
 * __useDownloadModelQuery__
 *
 * To run a query within a React component, call `useDownloadModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadModelQuery({
 *   variables: {
 *      rootId: // value for 'rootId'
 *      includePresets: // value for 'includePresets'
 *   },
 * });
 */
export function useDownloadModelQuery(baseOptions: Apollo.QueryHookOptions<DownloadModelQuery, DownloadModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadModelQuery, DownloadModelQueryVariables>(DownloadModelDocument, options);
      }
export function useDownloadModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadModelQuery, DownloadModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadModelQuery, DownloadModelQueryVariables>(DownloadModelDocument, options);
        }
export type DownloadModelQueryHookResult = ReturnType<typeof useDownloadModelQuery>;
export type DownloadModelLazyQueryHookResult = ReturnType<typeof useDownloadModelLazyQuery>;
export type DownloadModelQueryResult = Apollo.QueryResult<DownloadModelQuery, DownloadModelQueryVariables>;