import { makeStyles } from '@material-ui/core/styles'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { Toolbar } from '@mui/material'
import { SaveButton, Toolbar as RaToolbar, useTranslate } from 'react-admin'

const styles = {
    toolbar: { marginTop: 0 },
    button: { marginRight: '1rem' }
}
const useStyles = makeStyles(styles)

const CustomToolBar = (props) => {
    const toolbarStyle = useStyles()
    const translate = useTranslate()

    return (
        <Toolbar sx={{ position: 'fixed', bottom: 0, margin: 0, zIndex: 2 }} disableGutters>
            <RaToolbar {...props} className={toolbarStyle.toolbar}>
                <SaveButton
                    className={toolbarStyle.button}
                    label={translate('manager.resources.general.save')}
                    redirect={false}
                />
                <SaveButton
                    label={translate('manager.resources.general.save_and_go_back')}
                    redirect="list"
                    color="secondary"
                    icon={<SaveAltIcon />}
                />
            </RaToolbar>
        </Toolbar>
    )
}
export default CustomToolBar
