import { Button, FormControl, FormGroup, TextField } from '@material-ui/core'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { FieldProps } from '@rjsf/utils/dist'
import { JSONSchema7Object } from 'json-schema'
import { GalleryContext } from 'context/gallery/GalleryContext'

const IconFieldTemplate = (props: FieldProps) => {
    const { state, setGalleryState } = useContext(GalleryContext)
    const [files, setFiles] = useState({ ...props.formData })
    const [selectedInput, setSelectedInput] = useState<string>()
    const idSchemaArray: string[] = []

    const formatFileName = (file: string) =>
        file.includes('//') ? file.replace('//', '') : file.replace('/', '')

    // Fetch all the different icon states that are available
    Object.keys(props.idSchema).forEach((key: string) => {
        if (key !== '$id') {
            idSchemaArray.push(key)
        }
    })

    useEffect(() => {
        if (!state.openGallery && state.selectedFile && selectedInput) {
            setFiles({
                ...files,
                [selectedInput]: formatFileName(state.selectedFile)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    useEffect(() => {
        // Return the changed filenames to the json schema
        if (files) {
            return props.onChange(files)
        }
    }, [files, props, selectedInput])

    const handleChange = (name: string, event: ChangeEvent<HTMLInputElement>) => {
        return setFiles({
            ...files,
            [name]: formatFileName(event.target.value)
        })
    }

    return (
        <>
            <h3>{props?.name}</h3>
            <FormControl fullWidth={true}>
                {idSchemaArray?.map((file: string) => {
                    const properties: JSONSchema7Object = props.schema
                        .properties as JSONSchema7Object
                    const property: JSONSchema7Object = properties[file] as JSONSchema7Object
                    return (
                        <FormGroup
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: '10px 0px'
                            }}
                            key={file + Math.random()}
                        >
                            <TextField
                                type="text"
                                disabled={true}
                                style={{ width: 350, margin: 0 }}
                                label={property.description || file}
                                value={files[file]}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    handleChange(file, event)
                                }
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 25 }}
                                onClick={() => {
                                    setSelectedInput(file)
                                    return setGalleryState({ ...state, openGallery: true })
                                }}
                            >
                                Select file
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                style={{ marginLeft: 25 }}
                                onClick={() => {
                                    setFiles({ ...files, [file]: '' })
                                }}
                            >
                                Remove
                            </Button>
                        </FormGroup>
                    )
                })}
            </FormControl>
        </>
    )
}

export default IconFieldTemplate
