import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsSchoolClassesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SchoolsSchoolClassFilterInput>;
}>;


export type SchoolsSchoolClassesQuery = { __typename?: 'Query', schoolsSchoolClasses?: { __typename?: 'schoolsSchoolClassPaginator', data: Array<{ __typename?: 'schoolsSchoolClass', id: string, name: string }> } | null | undefined };


export const SchoolsSchoolClassesDocument = gql`
    query SchoolsSchoolClasses($filter: schoolsSchoolClassFilterInput) {
  schoolsSchoolClasses(filter: $filter) {
    data {
      id
      name
    }
  }
}
    `;

/**
 * __useSchoolsSchoolClassesQuery__
 *
 * To run a query within a React component, call `useSchoolsSchoolClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsSchoolClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsSchoolClassesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSchoolsSchoolClassesQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsSchoolClassesQuery, SchoolsSchoolClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsSchoolClassesQuery, SchoolsSchoolClassesQueryVariables>(SchoolsSchoolClassesDocument, options);
      }
export function useSchoolsSchoolClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsSchoolClassesQuery, SchoolsSchoolClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsSchoolClassesQuery, SchoolsSchoolClassesQueryVariables>(SchoolsSchoolClassesDocument, options);
        }
export type SchoolsSchoolClassesQueryHookResult = ReturnType<typeof useSchoolsSchoolClassesQuery>;
export type SchoolsSchoolClassesLazyQueryHookResult = ReturnType<typeof useSchoolsSchoolClassesLazyQuery>;
export type SchoolsSchoolClassesQueryResult = Apollo.QueryResult<SchoolsSchoolClassesQuery, SchoolsSchoolClassesQueryVariables>;