import { Reducer } from 'redux'
import { CHANGE_THEME, changeTheme } from './configuration/actions'
import { ThemeName } from './types'

type State = ThemeName | string
type Action = ReturnType<typeof changeTheme> | { type: 'OTHER_ACTION'; payload?: any }

const persistedState = localStorage.getItem('theme') ?? process.env.REACT_APP_THEME ?? 'light'
const themeReducer: Reducer<State, Action> = (previousState = persistedState, action) => {
    if (action.type === CHANGE_THEME) {
        localStorage.setItem('theme', action.payload)
        return action.payload
    }
    return previousState
}

export default themeReducer
