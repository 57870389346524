import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UiSchemaQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UiSchemaQuery = { __typename?: 'Query', configuratorUiSchema?: string | null | undefined };


export const UiSchemaDocument = gql`
    query UiSchema {
  configuratorUiSchema
}
    `;

/**
 * __useUiSchemaQuery__
 *
 * To run a query within a React component, call `useUiSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useUiSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUiSchemaQuery({
 *   variables: {
 *   },
 * });
 */
export function useUiSchemaQuery(baseOptions?: Apollo.QueryHookOptions<UiSchemaQuery, UiSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UiSchemaQuery, UiSchemaQueryVariables>(UiSchemaDocument, options);
      }
export function useUiSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UiSchemaQuery, UiSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UiSchemaQuery, UiSchemaQueryVariables>(UiSchemaDocument, options);
        }
export type UiSchemaQueryHookResult = ReturnType<typeof useUiSchemaQuery>;
export type UiSchemaLazyQueryHookResult = ReturnType<typeof useUiSchemaLazyQuery>;
export type UiSchemaQueryResult = Apollo.QueryResult<UiSchemaQuery, UiSchemaQueryVariables>;