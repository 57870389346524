import SchoolIcon from '@material-ui/icons/School'
import SchoolEdit from './SchoolEdit'
import SchoolList from './SchoolList'
import SchoolCreate from './SchoolCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: SchoolList,
    edit: SchoolEdit,
    create: SchoolCreate,
    icon: SchoolIcon
}
