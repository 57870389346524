import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DuplicatePackageGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  internal_name: Types.Scalars['String'];
  public_name: Types.Scalars['String'];
}>;


export type DuplicatePackageGroupMutation = { __typename?: 'Mutation', duplicatePackageGroup?: { __typename?: 'packagesPackageGroup', id: string } | null | undefined };


export const DuplicatePackageGroupDocument = gql`
    mutation DuplicatePackageGroup($id: ID!, $internal_name: String!, $public_name: String!) {
  duplicatePackageGroup: packagesDuplicatePackageGroup(
    id: $id
    internal_name: $internal_name
    public_name: $public_name
  ) {
    id
  }
}
    `;
export type DuplicatePackageGroupMutationFn = Apollo.MutationFunction<DuplicatePackageGroupMutation, DuplicatePackageGroupMutationVariables>;

/**
 * __useDuplicatePackageGroupMutation__
 *
 * To run a mutation, you first call `useDuplicatePackageGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePackageGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePackageGroupMutation, { data, loading, error }] = useDuplicatePackageGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      internal_name: // value for 'internal_name'
 *      public_name: // value for 'public_name'
 *   },
 * });
 */
export function useDuplicatePackageGroupMutation(baseOptions?: Apollo.MutationHookOptions<DuplicatePackageGroupMutation, DuplicatePackageGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicatePackageGroupMutation, DuplicatePackageGroupMutationVariables>(DuplicatePackageGroupDocument, options);
      }
export type DuplicatePackageGroupMutationHookResult = ReturnType<typeof useDuplicatePackageGroupMutation>;
export type DuplicatePackageGroupMutationResult = Apollo.MutationResult<DuplicatePackageGroupMutation>;
export type DuplicatePackageGroupMutationOptions = Apollo.BaseMutationOptions<DuplicatePackageGroupMutation, DuplicatePackageGroupMutationVariables>;