import SchoolClassRepresentativeCreate from './SchoolClassRepresentativeCreate'
import SchoolClassRepresentativeEdit from './SchoolClassRepresentativeEdit'
import SchoolClassRepresentativeList from './SchoolClassRepresentativeList'
import AccountBoxIcon from '@mui/icons-material/AccountBox'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: SchoolClassRepresentativeList,
    create: SchoolClassRepresentativeCreate,
    edit: SchoolClassRepresentativeEdit,
    icon: AccountBoxIcon
}
