import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateSchoolTagMutationVariables = Types.Exact<{
  tag: Types.Scalars['String'];
}>;


export type CreateSchoolTagMutation = { __typename?: 'Mutation', createSchoolTag?: { __typename?: 'schoolsSchoolTag', tag: string, id: string } | null | undefined };


export const CreateSchoolTagDocument = gql`
    mutation CreateSchoolTag($tag: String!) {
  createSchoolTag: schoolsCreateSchoolTag(tag: $tag) {
    tag
    id
  }
}
    `;
export type CreateSchoolTagMutationFn = Apollo.MutationFunction<CreateSchoolTagMutation, CreateSchoolTagMutationVariables>;

/**
 * __useCreateSchoolTagMutation__
 *
 * To run a mutation, you first call `useCreateSchoolTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolTagMutation, { data, loading, error }] = useCreateSchoolTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useCreateSchoolTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolTagMutation, CreateSchoolTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolTagMutation, CreateSchoolTagMutationVariables>(CreateSchoolTagDocument, options);
      }
export type CreateSchoolTagMutationHookResult = ReturnType<typeof useCreateSchoolTagMutation>;
export type CreateSchoolTagMutationResult = Apollo.MutationResult<CreateSchoolTagMutation>;
export type CreateSchoolTagMutationOptions = Apollo.BaseMutationOptions<CreateSchoolTagMutation, CreateSchoolTagMutationVariables>;