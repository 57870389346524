import { JSONSchema7, JSONSchema7Type, JSONSchema7Object, JSONSchema7Array } from 'json-schema'

const allowedChildren: JSONSchema7Object = {
    tab: ['fieldset', 'select', 'string', 'upload', 'content', 'number'],
    fieldset: ['select', 'string', 'upload', 'content', 'number'],
    select: ['option'],
    string: [],
    upload: [],
    option: ['fieldset', 'select', 'string', 'upload', 'content', 'number'],
    number: [],
    content: [],
    root: ['tab']
}

const getAvailableTypes = (fullSchema: JSONSchema7, parentType: string): string[] => {
    const definitions = fullSchema.definitions as JSONSchema7Object
    const child = definitions.child as JSONSchema7
    if (child && definitions) {
        const type = child?.properties?.type as JSONSchema7
        const typeList = type.enum?.map((code: JSONSchema7Type) => code as string) || []
        const allowedChild: string[] = (allowedChildren[parentType] as string[]) || []
        const strings = typeList.filter((code: string) => allowedChild.includes(code)) as string[]
        return strings
    }
    return []
}

function cleanProperties(nodeProperties: JSONSchema7Object) {
    // managed as tree nodes
    delete nodeProperties['children']

    // not supported by design
    // delete nodeProperties["labelling"]
    // delete nodeProperties["3d_model"]
    // delete nodeProperties["camera"]
    //
    // delete nodeProperties["constraints"]
    // delete nodeProperties["upsell"]
    // delete nodeProperties["valueGroups"]

    // not supported by form
    delete nodeProperties['allOf']
}

function processTypeConfig(
    nodeProperties: JSONSchema7Object,
    definitions: JSONSchema7Object,
    type: string,
    requiredOnly: boolean
) {
    let typeConfig: JSONSchema7 = {}
    nodeProperties = definitions.child as JSONSchema7Object
    typeConfig =
        (definitions['config_' + type] as JSONSchema7) ||
        ({
            type: 'object'
        } as JSONSchema7)
    const configRequired = typeConfig?.required || []
    const properties = typeConfig?.properties || {}
    // delete properties["renderer"]
    if (requiredOnly) {
        Object.keys(properties).forEach((property: string) => {
            if (!configRequired.includes(property)) {
                delete properties[property]
            }
        })
    }
    typeConfig.properties = properties
    typeConfig.title = type
    if (nodeProperties.properties) {
        const nodePropertiesProperties: JSONSchema7Object =
            nodeProperties.properties as JSONSchema7Object
        nodePropertiesProperties.config = typeConfig as JSONSchema7Object
    }
    return nodeProperties
}

const getSchemaByNode = (
    fullSchema: JSONSchema7,
    type = '',
    requiredOnly = false,
    isRoot = false
): JSONSchema7 => {
    let nodeProperties: JSONSchema7Object = {}
    const definitions = fullSchema.definitions as JSONSchema7Object
    const child = definitions.child as JSONSchema7Object
    let required: JSONSchema7Array = []

    if (child && definitions) {
        const rootProperties: JSONSchema7Object = fullSchema.properties as JSONSchema7Object
        if (!isRoot) {
            const nodeDefinition = processTypeConfig(
                nodeProperties,
                definitions,
                type,
                requiredOnly
            )
            nodeProperties = nodeDefinition.properties as JSONSchema7Object
            required = nodeDefinition.required as JSONSchema7Array
            if (requiredOnly) {
                Object.keys(nodeProperties).forEach((property: string) => {
                    if (!required.includes(property) && property !== 'config') {
                        delete nodeProperties[property]
                    }
                })
            }
            if (requiredOnly) {
                delete nodeProperties['type']
                required = required.filter((prop: JSONSchema7Type) => {
                    return prop !== 'type'
                })
            }
        } else {
            nodeProperties = rootProperties
            required =
                fullSchema?.required?.filter((prop: string) => {
                    return prop !== 'children'
                }) || []
        }
        cleanProperties(nodeProperties)
    }
    return {
        $id: undefined, // important! otherwise validation does not work properly!
        $schema: fullSchema.$schema,
        type: 'object',
        properties: nodeProperties,
        definitions: definitions,
        required: required
    } as JSONSchema7
}

export { getSchemaByNode, getAvailableTypes, allowedChildren }
