import Form from '@rjsf/material-ui'
import { useContext, useState } from 'react'
import { useForm } from 'react-final-form'
import { JSONSchema7Object } from 'json-schema'

import { ErrorContext } from 'context/error/ErrorContext'
import ConfiguratorOptionTreeMultiple from './ConfiguratorOptionTreeMultiple'
import validator from '@rjsf/validator-ajv8'
import { IChangeEvent } from '@rjsf/core'
import { RJSFSchema, RJSFValidationError } from '@rjsf/utils/dist'

interface IConfiguratorOptionProps {
    selectedRootModelCode: string
    initialData?: string
}

const ConfiguratorOption = ({ selectedRootModelCode, initialData }: IConfiguratorOptionProps) => {
    const { change } = useForm()
    const { setErrorState } = useContext(ErrorContext)
    const [formData, setFormData] = useState<unknown>(JSON.parse(initialData ?? '{}'))

    const Schema = JSON.parse(`
      {
        "type": "object",
        "properties": {
          "options": {
            "type": "array",
            "title": "",
            "items": {
              "type": "object",
              "properties":{
                "path": { "type":"string", "title":"Option" },
                "label": { "type":"string", "title":"Comparison Label" },
                "description": { "type":"string", "title":"Description" },
                "is_default_path": {"type":"boolean", "title":"Is Default Option", "description":"The first path in list is used", "default":false},
                "default_path": { "type":"string", "title":"Default path", "description":"Use in case multiple paths are selected" }
              }
            }
          }
        }
      }
    `)

    const uiSchema = {
        options: {
            items: {
                path: {
                    'ui:widget': ConfiguratorOptionTreeMultiple
                }
            }
        }
    }

    const onChangeHandler = (e: IChangeEvent<any, RJSFSchema, any>) => {
        if (e.formData !== formData) setFormData(e.formData)
        change('included_options', JSON.stringify(e.formData))
    }

    const OnErrorHandler = (errors: JSONSchema7Object[]) => {
        setErrorState({ hasError: true, message: errors[0].stack as string })
    }

    const TransformErrors = (errors: RJSFValidationError[]) => {
        return errors?.map((error: RJSFValidationError) => {
            console.warn(error)
            return error
        })
    }

    return (
        <Form
            schema={Schema}
            uiSchema={uiSchema}
            formData={formData}
            children={true}
            onChange={(e) => onChangeHandler(e)}
            onError={(e) => OnErrorHandler(e)}
            transformErrors={TransformErrors}
            validator={validator}
        ></Form>
    )
}

export default ConfiguratorOption
