import { useState } from 'react'
import {
    TextInput,
    NumberInput,
    BooleanInput,
    useTranslate,
    required,
    TabbedForm,
    FormTab,
    SelectInput,
    regex
} from 'react-admin'
import { CircularProgress, Grid, InputAdornment, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormSpy } from 'react-final-form'
import { useHistory } from 'react-router-dom'

import PackageSchemaForm from './PackageSchemaForm'
import DefaultToolbar from 'components/common/DefaultToolbar'
import PackageProductList from '../packageProduct/PackageProductList'
import { usePackageSchemaQuery } from 'apollo/package_manager/queries/PackageSchema.generated'
import { PackagesPackageType } from 'apollo/types.generated'
import { formStyles } from 'layout/styles/formStyles'
import PackageGalleryItemList from 'resources/packageGalleryItem/PackageGalleryItemList'

const GENERAL_TAB_PATH = '/general'

const useStyles = makeStyles(formStyles)

const PackageForm = ({ isPristineForm, setIsPristineFormCallback, isEdit = false, ...props }) => {
    const translate = useTranslate()
    const formStyle = useStyles()
    const history = useHistory()

    const [packageType, setPackageType] = useState<PackagesPackageType>(
        props.record.type ?? PackagesPackageType.Package
    )
    const validateRegex = regex(/^[\w-]+$/, translate('manager.regex.az09'))

    const { data: { packageSchema } = {}, loading } = usePackageSchemaQuery({
        variables: {
            type: packageType
        }
    })

    const handleSubmit = (hasError: boolean) => {
        if (hasError && !history.location.pathname.includes(GENERAL_TAB_PATH))
            history.push(history.location.pathname + GENERAL_TAB_PATH)
    }

    const generalTab = () => (
        <FormTab
            label={translate('manager.tabs.general')}
            path={isEdit ? undefined : GENERAL_TAB_PATH}
        >
            <Grid container className={formStyle.fullWidth}>
                <Grid item md={7}>
                    <Grid item md={12}>
                        <Typography variant="h6" gutterBottom>
                            {translate('manager.resources.package.package')}
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <TextInput
                            fullWidth
                            source="ajat_id"
                            variant="outlined"
                            label={translate('manager.resources.package.package_id')}
                            validate={[required(), validateRegex]}
                            helperText={translate('manager.regex.az09')}
                        />
                        <TextInput
                            fullWidth
                            source="public_name"
                            variant="outlined"
                            label={translate('manager.resources.package.public_name')}
                            validate={requiredValidate}
                        />
                        <Grid container md={12} spacing={6} alignItems="center">
                            <Grid item md={3}>
                                <NumberInput
                                    source="order"
                                    variant="outlined"
                                    min={0}
                                    defaultValue={0}
                                    label={translate('manager.resources.package.order')}
                                    validate={requiredValidate}
                                />
                            </Grid>
                            {packageType === PackagesPackageType.Package && (
                                <>
                                    <Grid item md={4}>
                                        <BooleanInput
                                            source="is_favourite"
                                            label={translate(
                                                'manager.resources.package.is_favourite'
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={5}>
                                        <BooleanInput
                                            source="is_default"
                                            label={translate(
                                                'manager.resources.package.is_default'
                                            )}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        {packageType === PackagesPackageType.Package && (
                            <>
                                <TextInput
                                    fullWidth
                                    source="short_description"
                                    variant="outlined"
                                    label={translate('manager.resources.package.short_description')}
                                    multiline={true}
                                />
                                <TextInput
                                    fullWidth
                                    source="long_description"
                                    variant="outlined"
                                    label={translate('manager.resources.package.long_description')}
                                    rows={3}
                                    multiline={true}
                                />
                                <TextInput
                                    fullWidth
                                    source="overlay_text"
                                    variant="outlined"
                                    label={translate('manager.resources.package.overlay_text')}
                                />
                                <TextInput
                                    fullWidth
                                    source="overlay_text_bottom"
                                    variant="outlined"
                                    label={translate(
                                        'manager.resources.package.overlay_text_bottom'
                                    )}
                                />
                                <TextInput
                                    fullWidth
                                    source="wp_slug"
                                    variant="outlined"
                                    label={translate('manager.resources.package.wp_slug')}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item md={1} />
                <Grid item md={4}>
                    <Grid item md={12} className={formStyle.flexStart}>
                        <Typography variant="h6" gutterBottom>
                            {translate('manager.resources.package.pricing')}
                        </Typography>
                        {packageType === PackagesPackageType.Package && (
                            <NumberInput
                                source="base_price"
                                variant="outlined"
                                label={translate('manager.resources.package.price_original')}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">€</InputAdornment>
                                    )
                                }}
                            />
                        )}
                        <NumberInput
                            source="final_price"
                            variant="outlined"
                            label={translate('manager.resources.package.price_sell')}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>
                            }}
                            validate={requiredValidate}
                        />
                        {packageType === PackagesPackageType.Package && (
                            <TextInput
                                fullWidth
                                source="price_tooltip"
                                variant="outlined"
                                label={translate('manager.resources.package.price_tooltip')}
                            />
                        )}
                        <BooleanInput
                            row
                            source="active"
                            label={translate('manager.resources.general.published')}
                            defaultValue={true}
                        />
                    </Grid>
                </Grid>
                {props?.record?.id && (
                    <Grid container spacing={1} className={formStyle.fullWidth}>
                        <PackageProductList
                            filter={{ package_id: parseInt(props?.record?.id) }}
                            isPackage={packageType === PackagesPackageType.Package}
                        />
                    </Grid>
                )}
            </Grid>
        </FormTab>
    )

    const configTab = () => (
        <FormTab label={translate('manager.tabs.config')} path={isEdit ? '/config' : undefined}>
            <Grid container className={formStyle.fullWidth}>
                <Grid item md={6}>
                    <SelectInput
                        source="type"
                        variant="outlined"
                        disabled={isEdit}
                        choices={[
                            { id: 'package', name: 'Package' },
                            { id: 'megadeal', name: 'Megadeal' }
                        ]}
                        defaultValue="package"
                        fullWidth
                        validate={requiredValidate}
                        label={translate('manager.resources.package.type')}
                        onChange={(event) => {
                            setPackageType(event.target.value as PackagesPackageType)
                        }}
                    />
                    {packageSchema && (
                        <PackageSchemaForm
                            packageSchema={packageSchema}
                            definition={props?.record?.definition}
                        />
                    )}
                    {loading && <CircularProgress />}
                </Grid>
            </Grid>
        </FormTab>
    )

    if (isEdit) {
        return (
            <TabbedForm
                {...props}
                toolbar={<DefaultToolbar isWithActionsFlush />}
                redirect={props.basePath}
            >
                {generalTab()}
                {/* Trailing slash (images/) below is to enable redirect location sync for tab */}
                {packageType === PackagesPackageType.Package && (
                    <FormTab
                        label={translate('manager.tabs.images')}
                        path="images/"
                        syncWithLocation
                    >
                        <PackageGalleryItemList
                            filter={{ package_id: parseInt(props?.record?.id) }}
                        />
                    </FormTab>
                )}
                {configTab()}
                <FormSpy
                    subscription={{ pristine: true }}
                    onChange={(props) => {
                        setIsPristineFormCallback(props?.pristine)
                    }}
                />
            </TabbedForm>
        )
    } else {
        return (
            <TabbedForm
                {...props}
                toolbar={<DefaultToolbar isWithActionsFlush onClick={handleSubmit} />}
                redirect={props.basePath}
            >
                {configTab()}
                {generalTab()}
                <FormSpy
                    subscription={{ pristine: true }}
                    onChange={(props) => {
                        setIsPristineFormCallback(props?.pristine)
                    }}
                />
            </TabbedForm>
        )
    }
}

const requiredValidate = [required()]

export default PackageForm
