import { makeStyles } from '@material-ui/core/styles'
import { Toolbar } from '@mui/material'
import { SaveButton, Toolbar as RaToolbar } from 'react-admin'
import { useForm } from 'react-final-form'

import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'

interface DefaultToolbarProps {
    isWithActionsFlush?: boolean
    onClick?: (hasError: boolean) => void
}

const styles = {
    toolbar: { margin: 0 }
}
const useStyles = makeStyles(styles)

const DefaultToolbar = ({ isWithActionsFlush, onClick, ...props }: DefaultToolbarProps) => {
    const classes = useStyles()
    const { getState } = useForm()

    const [flushActionsCache] = useFlushActionsCacheMutation()

    const handleSaveButtonClick = () => {
        const formState = getState()

        onClick &&
            onClick(
                !!formState?.errors?.ajat_id ||
                    !!formState?.errors?.final_price ||
                    !!formState?.errors?.public_name
            )

        if (isWithActionsFlush) {
            flushActionsCache()
        } else {
            return false
        }
    }

    return (
        <Toolbar sx={{ position: 'fixed', bottom: 0, margin: 0, zIndex: 2 }} disableGutters>
            <RaToolbar {...props} className={classes.toolbar}>
                <SaveButton onClick={handleSaveButtonClick} />
            </RaToolbar>
        </Toolbar>
    )
}
export default DefaultToolbar
