import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsSchoolsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SchoolsSchoolsQuery = { __typename?: 'Query', schoolsSchools?: { __typename?: 'schoolsSchoolPaginator', data: Array<{ __typename?: 'schoolsSchool', ajat_id: string, id: string, name: string }> } | null | undefined };


export const SchoolsSchoolsDocument = gql`
    query SchoolsSchools($first: Int) {
  schoolsSchools(first: $first) {
    data {
      ajat_id
      id
      name
    }
  }
}
    `;

/**
 * __useSchoolsSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsSchoolsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSchoolsSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsSchoolsQuery, SchoolsSchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsSchoolsQuery, SchoolsSchoolsQueryVariables>(SchoolsSchoolsDocument, options);
      }
export function useSchoolsSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsSchoolsQuery, SchoolsSchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsSchoolsQuery, SchoolsSchoolsQueryVariables>(SchoolsSchoolsDocument, options);
        }
export type SchoolsSchoolsQueryHookResult = ReturnType<typeof useSchoolsSchoolsQuery>;
export type SchoolsSchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsSchoolsLazyQuery>;
export type SchoolsSchoolsQueryResult = Apollo.QueryResult<SchoolsSchoolsQuery, SchoolsSchoolsQueryVariables>;