import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PresetExportModelMutationVariables = Types.Exact<{
  configuratorExportModelId: Types.Scalars['ID'];
  preset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type PresetExportModelMutation = { __typename?: 'Mutation', configuratorExportModel?: { __typename?: 'configuratorModel', body?: string | null | undefined, code: string, parent?: string | null | undefined, parent_version?: string | null | undefined, version?: string | null | undefined } | null | undefined };


export const PresetExportModelDocument = gql`
    mutation PresetExportModel($configuratorExportModelId: ID!, $preset: Int) {
  configuratorExportModel(id: $configuratorExportModelId, preset: $preset) {
    body
    code
    parent
    parent_version
    version
  }
}
    `;
export type PresetExportModelMutationFn = Apollo.MutationFunction<PresetExportModelMutation, PresetExportModelMutationVariables>;

/**
 * __usePresetExportModelMutation__
 *
 * To run a mutation, you first call `usePresetExportModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePresetExportModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [presetExportModelMutation, { data, loading, error }] = usePresetExportModelMutation({
 *   variables: {
 *      configuratorExportModelId: // value for 'configuratorExportModelId'
 *      preset: // value for 'preset'
 *   },
 * });
 */
export function usePresetExportModelMutation(baseOptions?: Apollo.MutationHookOptions<PresetExportModelMutation, PresetExportModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PresetExportModelMutation, PresetExportModelMutationVariables>(PresetExportModelDocument, options);
      }
export type PresetExportModelMutationHookResult = ReturnType<typeof usePresetExportModelMutation>;
export type PresetExportModelMutationResult = Apollo.MutationResult<PresetExportModelMutation>;
export type PresetExportModelMutationOptions = Apollo.BaseMutationOptions<PresetExportModelMutation, PresetExportModelMutationVariables>;