import { useState } from 'react'
import { Create, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import PackageGroupCollectionForm from './PackageGroupCollectionForm'

const PackageGroupCollectionCreate = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} />
            <Create title={translate('manager.resources.packageGroupCollection.create')} {...props}>
                <PackageGroupCollectionForm
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Create>
        </>
    )
}

export default PackageGroupCollectionCreate
