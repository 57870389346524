import { Box, Snackbar, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import CachedIcon from '@mui/icons-material/Cached'
import { useState } from 'react'
import { Title, useRefresh, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../types'
import { changeTheme } from './actions'

import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'
import { useFlushProductsCacheMutation } from 'apollo/personalization/mutations/FlushProductsCache.generated'

const SNACKBAR_TIMEOUT = 4000

const useStyles = makeStyles({
    button: { marginRight: '1em' },
    title: { marginBottom: '1em' },
    configurationCard: { margin: '1em', padding: '1em' },
    flushItemWrapper: { gap: '1em' },
    loadingButton: {
        boxShadow: 'none !important',
        borderRadius: '10px !important',
        '&.MuiLoadingButton-loading': {
            backgroundColor: 'rgba(0, 0, 0, 0.12) !important'
        }
    }
})

const Configuration = () => {
    const translate = useTranslate()
    const classes = useStyles()
    const theme = useSelector((state: AppState) => state.theme)
    const dispatch = useDispatch()
    const reload = useRefresh()

    const [isFlushedProductsCache, setIsFlushedProductsCache] = useState(false)
    const [isFlushedActionsCache, setIsFlushedActionsCache] = useState(false)

    const [flushProductsCache, { loading: flushProductsCacheLoading }] =
        useFlushProductsCacheMutation()
    const [flushActionsCache, { loading: flushActionsCacheLoading }] =
        useFlushActionsCacheMutation()

    return (
        <>
            {process.env.REACT_APP_THEME !== 'proudNerds' ? (
                <Card className={classes.configurationCard}>
                    <Title title={translate('manager.configuration')} />
                    <CardContent>
                        <Typography variant="h6" className={classes.title}>
                            {translate('manager.theme.name')}
                        </Typography>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color={theme === 'light' ? 'primary' : 'default'}
                            onClick={() => dispatch(changeTheme('light'))}
                        >
                            {translate('manager.theme.light')}
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color={theme === 'dark' ? 'primary' : 'default'}
                            onClick={() => dispatch(changeTheme('dark'))}
                        >
                            {translate('manager.theme.dark')}
                        </Button>
                        {process.env.REACT_APP_THEME === 'proudNerds' && (
                            <Button
                                variant="contained"
                                className={classes.button}
                                color={theme === 'proudNerds' ? 'primary' : 'default'}
                                onClick={() => dispatch(changeTheme('proudNerds'))}
                            >
                                {translate('manager.theme.proudNerds')}
                            </Button>
                        )}
                    </CardContent>
                    {/* <CardContent>
                <div className={classes.label}>{translate('manager.language')}</div>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={locale === 'en' ? 'primary' : 'default'}
                    onClick={() => setLocale('en')}
                >
                    {translate('manager.languages.english')}
                </Button>
                <Button
                    disabled
                    variant="contained"
                    className={classes.button}
                    color={locale === 'fi' ? 'primary' : 'default'}
                    onClick={() => setLocale('fi')}
                >
                    {translate('manager.languages.finnish')}
                </Button>
            </CardContent> */}
                </Card>
            ) : null}
            <Card className={classes.configurationCard}>
                <CardContent>
                    <Typography
                        variant="h6"
                        className={classes.title}
                        style={{ marginBottom: '2em' }}
                    >
                        {translate('manager.cache.title')}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gridGap: '1em',
                            marginBottom: '2em'
                        }}
                        className={classes.flushItemWrapper}
                    >
                        <Typography>{translate('manager.cache.productsCache')}</Typography>

                        <Button
                            className={classes.loadingButton}
                            disabled={flushProductsCacheLoading}
                            variant="contained"
                            color="primary"
                            startIcon={<CachedIcon />}
                            onClick={() =>
                                flushProductsCache().then(() => {
                                    reload()
                                    setIsFlushedProductsCache(true)
                                })
                            }
                        >
                            {translate('manager.cache.flush')}
                        </Button>
                    </Box>

                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gridGap: '1em' }}
                        className={classes.flushItemWrapper}
                    >
                        <Typography>{translate('manager.cache.actionsCache')}</Typography>
                        <Button
                            className={classes.loadingButton}
                            disabled={flushActionsCacheLoading}
                            variant="contained"
                            color="primary"
                            startIcon={<CachedIcon />}
                            onClick={() =>
                                flushActionsCache().then(() => {
                                    reload()
                                    setIsFlushedActionsCache(true)
                                })
                            }
                        >
                            {translate('manager.cache.flush')}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <Snackbar
                open={isFlushedProductsCache}
                onClose={() => setIsFlushedProductsCache(false)}
                autoHideDuration={SNACKBAR_TIMEOUT}
                message={translate('manager.cache.productsCacheSuccess')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
            <Snackbar
                open={isFlushedActionsCache}
                onClose={() => setIsFlushedActionsCache(false)}
                autoHideDuration={SNACKBAR_TIMEOUT}
                message={translate('manager.cache.actionsCacheSuccess')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </>
    )
}

export default Configuration
