import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PersonalizationActionConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PersonalizationActionConfigQuery = { __typename?: 'Query', actionConfig?: string | null | undefined };


export const PersonalizationActionConfigDocument = gql`
    query PersonalizationActionConfig {
  actionConfig: personalizationActionConfig
}
    `;

/**
 * __usePersonalizationActionConfigQuery__
 *
 * To run a query within a React component, call `usePersonalizationActionConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalizationActionConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalizationActionConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalizationActionConfigQuery(baseOptions?: Apollo.QueryHookOptions<PersonalizationActionConfigQuery, PersonalizationActionConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalizationActionConfigQuery, PersonalizationActionConfigQueryVariables>(PersonalizationActionConfigDocument, options);
      }
export function usePersonalizationActionConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalizationActionConfigQuery, PersonalizationActionConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalizationActionConfigQuery, PersonalizationActionConfigQueryVariables>(PersonalizationActionConfigDocument, options);
        }
export type PersonalizationActionConfigQueryHookResult = ReturnType<typeof usePersonalizationActionConfigQuery>;
export type PersonalizationActionConfigLazyQueryHookResult = ReturnType<typeof usePersonalizationActionConfigLazyQuery>;
export type PersonalizationActionConfigQueryResult = Apollo.QueryResult<PersonalizationActionConfigQuery, PersonalizationActionConfigQueryVariables>;