import {
    useRedirect,
    Edit,
    SimpleForm,
    useTranslate,
    TextInput,
    Loading,
    required
} from 'react-admin'

import { usePackagesPackageGroupGalleryItemQuery } from 'apollo/package_manager/queries/PackageGroupGalleryItem.generated'

import { Typography } from '@material-ui/core'

import UploadField from 'components/formfields/uploadField/UploadField'
import PackageGroupGalleryItemEditToolbar from './PackageGroupGalleryItemEditToolbar'

const PackageGroupGalleryItemEdit = (props) => {
    const RESOURCE = 'PackageGroupGalleryItem'
    const id = props?.match?.params?.id ?? ''
    const translate = useTranslate()
    const redirect = useRedirect()
    const onSuccess = (response: any) => {
        redirect('edit', `/PackageGroup/${response.data?.group_id}/images`, '')
    }

    const { data: { packagesPackageGroupGalleryItem: record } = {} } =
        usePackagesPackageGroupGalleryItemQuery({
            variables: {
                packagesPackageGroupGalleryItemId: id
            }
        })

    if (!record) return <Loading />
    return (
        <Edit
            {...props}
            undoable={false}
            resource={RESOURCE}
            onSuccess={onSuccess}
            basePath={props.basepath}
            title={translate(`manager.resources.${RESOURCE}.edit`)}
        >
            <SimpleForm
                initialValues={{ group_id: props?.location?.state?.group_id }}
                toolbar={<PackageGroupGalleryItemEditToolbar resource={RESOURCE} />}
            >
                <Typography variant="h6" gutterBottom>
                    {translate(`manager.resources.${RESOURCE}.edit`)}
                </Typography>
                <TextInput
                    source="title"
                    variant="outlined"
                    label={translate(`manager.resources.${RESOURCE}.title`)}
                    validate={requiredValidate}
                />
                <TextInput
                    source="alt"
                    variant="outlined"
                    label={translate(`manager.resources.${RESOURCE}.alt`)}
                    validate={requiredValidate}
                />
                <UploadField
                    source="path"
                    label={translate(`manager.resources.${RESOURCE}.path`)}
                    data={record.path}
                    url={record.path}
                    validateRequired
                />
            </SimpleForm>
        </Edit>
    )
}

const requiredValidate = [required()]

export default PackageGroupGalleryItemEdit
