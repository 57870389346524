import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PackagesCreatePackageGroupPresetMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
  groupId: Types.Scalars['Int'];
  sku: Types.Scalars['String'];
}>;


export type PackagesCreatePackageGroupPresetMutation = { __typename?: 'Mutation', packagesCreatePackageGroupPreset?: { __typename?: 'packagesPackageGroupPreset', code: string, id: string, sku: string } | null | undefined };


export const PackagesCreatePackageGroupPresetDocument = gql`
    mutation PackagesCreatePackageGroupPreset($code: String!, $groupId: Int!, $sku: String!) {
  packagesCreatePackageGroupPreset(code: $code, group_id: $groupId, sku: $sku) {
    code
    id
    sku
  }
}
    `;
export type PackagesCreatePackageGroupPresetMutationFn = Apollo.MutationFunction<PackagesCreatePackageGroupPresetMutation, PackagesCreatePackageGroupPresetMutationVariables>;

/**
 * __usePackagesCreatePackageGroupPresetMutation__
 *
 * To run a mutation, you first call `usePackagesCreatePackageGroupPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackagesCreatePackageGroupPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packagesCreatePackageGroupPresetMutation, { data, loading, error }] = usePackagesCreatePackageGroupPresetMutation({
 *   variables: {
 *      code: // value for 'code'
 *      groupId: // value for 'groupId'
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function usePackagesCreatePackageGroupPresetMutation(baseOptions?: Apollo.MutationHookOptions<PackagesCreatePackageGroupPresetMutation, PackagesCreatePackageGroupPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PackagesCreatePackageGroupPresetMutation, PackagesCreatePackageGroupPresetMutationVariables>(PackagesCreatePackageGroupPresetDocument, options);
      }
export type PackagesCreatePackageGroupPresetMutationHookResult = ReturnType<typeof usePackagesCreatePackageGroupPresetMutation>;
export type PackagesCreatePackageGroupPresetMutationResult = Apollo.MutationResult<PackagesCreatePackageGroupPresetMutation>;
export type PackagesCreatePackageGroupPresetMutationOptions = Apollo.BaseMutationOptions<PackagesCreatePackageGroupPresetMutation, PackagesCreatePackageGroupPresetMutationVariables>;