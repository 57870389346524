import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslate } from 'react-admin'

interface IWarningDialog {
    open: boolean
    title: string
    content?: string
    JSXContent?: any
    maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined
    handleContinue: () => void
    handleClose: () => void
}
const WarningDialog = ({
    open,
    title,
    content,
    JSXContent,
    maxWidth = 'sm',
    handleContinue,
    handleClose
}: IWarningDialog) => {
    const translate = useTranslate()

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={maxWidth}
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                {content && (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {content}
                        </DialogContentText>
                    </DialogContent>
                )}
                {JSXContent && <DialogContent children={JSXContent}></DialogContent>}
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="default">
                        {translate('manager.resources.model_manager.warning_dialog.cancel')}
                    </Button>
                    <Button onClick={handleContinue} variant="contained" color="primary" autoFocus>
                        {translate('manager.resources.model_manager.warning_dialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default WarningDialog
