import { Alert, Box, Divider, Typography } from '@mui/material'
import { useTranslate } from 'react-admin'
import CheckboxTree, { Node } from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'

export interface AttributeSelectorProps {
    parsedAttributesTreeDataTreeStructure: Node[]
    checkedAttributes: string[]
    expandedAttributes: string[]
    onCheckAttributes: (checked: any, targetNode: any) => void
    onExpandAttributes: (expanded: any) => void
}

export const AttributeSelector: React.FC<AttributeSelectorProps> = ({
    parsedAttributesTreeDataTreeStructure,
    checkedAttributes,
    expandedAttributes,
    onCheckAttributes,
    onExpandAttributes
}) => {
    const translate = useTranslate()

    return (
        <>
            <Box sx={{ mb: 3, width: '100%' }}>
                <Typography variant="h6">
                    {translate('manager.resources.rule.actions_tab.path_tree.attribute_path_title')}
                </Typography>

                <Divider sx={{ mt: 1, mb: 3 }} />

                {parsedAttributesTreeDataTreeStructure?.length > 0 ? (
                    <CheckboxTree
                        showNodeIcon={false}
                        showExpandAll={true}
                        noCascade={true}
                        onlyLeafCheckboxes={true}
                        nodes={parsedAttributesTreeDataTreeStructure ?? []}
                        checked={checkedAttributes}
                        expanded={expandedAttributes}
                        onCheck={onCheckAttributes}
                        onExpand={onExpandAttributes}
                    />
                ) : (
                    <Alert severity="error">
                        {translate(
                            'manager.resources.rule.actions_tab.path_tree.invalid_path_alert'
                        )}
                    </Alert>
                )}
            </Box>

            {/* PATH SUMMARY */}
            <Box sx={{ mt: 3 }}>
                <Divider />

                <Typography variant="caption">
                    <strong>
                        {translate('manager.resources.rule.actions_tab.path_tree.path_hint')}:
                    </strong>

                    {checkedAttributes[checkedAttributes.length - 1]}
                </Typography>
            </Box>
        </>
    )
}
