import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type FlushActionsCacheMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type FlushActionsCacheMutation = { __typename?: 'Mutation', personalizationFlushActionsCache?: boolean | null | undefined };


export const FlushActionsCacheDocument = gql`
    mutation FlushActionsCache {
  personalizationFlushActionsCache
}
    `;
export type FlushActionsCacheMutationFn = Apollo.MutationFunction<FlushActionsCacheMutation, FlushActionsCacheMutationVariables>;

/**
 * __useFlushActionsCacheMutation__
 *
 * To run a mutation, you first call `useFlushActionsCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlushActionsCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flushActionsCacheMutation, { data, loading, error }] = useFlushActionsCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useFlushActionsCacheMutation(baseOptions?: Apollo.MutationHookOptions<FlushActionsCacheMutation, FlushActionsCacheMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FlushActionsCacheMutation, FlushActionsCacheMutationVariables>(FlushActionsCacheDocument, options);
      }
export type FlushActionsCacheMutationHookResult = ReturnType<typeof useFlushActionsCacheMutation>;
export type FlushActionsCacheMutationResult = Apollo.MutationResult<FlushActionsCacheMutation>;
export type FlushActionsCacheMutationOptions = Apollo.BaseMutationOptions<FlushActionsCacheMutation, FlushActionsCacheMutationVariables>;