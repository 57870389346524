import { useState } from 'react'
import {
    Create,
    Datagrid,
    List,
    ListProps,
    SimpleForm,
    useTranslate,
    FunctionField
} from 'react-admin'
import { useRefresh } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Record } from 'ra-core'

import datagridStyles from '../../layout/styles/datagridStyles'
import SchoolPeriodListToolbar from './SchoolPeriodListToolbar'
import SchoolPeriodFormInputs from './SchoolPeriodFormInputs'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import { formatDateString } from 'utils/formatDateString'

const useListStyles = makeStyles({
    list: {
        width: '100%',
        margin: '2rem 0'
    },
    form: {
        width: 500
    }
})

const SchoolPeriodList = (props: ListProps) => {
    const RESOURCE = 'SchoolPeriod'
    const [open, setOpen] = useState(false)
    const reload = useRefresh()
    const translate = useTranslate()
    const classes = useListStyles()
    const datagridClasses = datagridStyles()
    const schoolId = props?.filter?.school_id

    // Toggle modal state
    const toggleModal = (): void => {
        setOpen(!open)
    }

    // Closes modal and re-renders after saving
    const handleSuccess = () => {
        toggleModal()
        reload()
    }

    return (
        <>
            <ListWrapper>
                <List
                    basePath={`/${RESOURCE}`}
                    resource={RESOURCE}
                    actions={
                        <SchoolPeriodListToolbar
                            resource={RESOURCE}
                            handleCreateCallback={() => toggleModal()}
                        />
                    }
                    empty={false}
                    className={classes.list}
                    bulkActionButtons={false}
                    sort={{ field: 'from_date', order: 'ASC' }}
                    filter={props.filter}
                >
                    <Datagrid
                        body={<CustomDatagridBody />}
                        classes={datagridClasses}
                        rowClick="edit"
                    >
                        <DateFieldCustom
                            label={translate(`manager.resources.${RESOURCE}.from_date`)}
                            source="from_date"
                            transform={(value) => formatDateString(value)}
                        />
                        <DateFieldCustom
                            label={translate(`manager.resources.${RESOURCE}.to_date`)}
                            source="to_date"
                            transform={(value) => formatDateString(value)}
                        />
                        <DateFieldCustom
                            label={translate(`manager.resources.${RESOURCE}.grace_payment_date`)}
                            source="grace_payment_date"
                            transform={(value) => formatDateString(value)}
                        />
                        <FunctionField
                            source="active"
                            label={translate('manager.resources.general.status')}
                            render={(record?: Record | undefined) => (
                                <DatagridPublishedColumnContent record={record} />
                            )}
                        />
                        <DateFieldCustom
                            label={translate('manager.resources.general.last_updated')}
                            source="updated_at"
                            showTime
                            transform={(value) => formatDateString(value, true)}
                        />
                    </Datagrid>
                </List>
            </ListWrapper>

            <Dialog open={open} onClose={() => toggleModal()}>
                <Create resource={RESOURCE} basePath={`/${RESOURCE}`} onSuccess={handleSuccess}>
                    <SimpleForm className={classes.form} initialValues={{ school_id: schoolId }}>
                        <Typography variant="h6" gutterBottom>
                            {translate(`manager.resources.${RESOURCE}.add`)}
                        </Typography>
                        <SchoolPeriodFormInputs />
                    </SimpleForm>
                </Create>
            </Dialog>
        </>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    & .MuiPaper-root {
        border: 0;
    }
`

export default SchoolPeriodList
