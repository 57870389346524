import validator from '@rjsf/validator-ajv8'
import Form from '@rjsf/material-ui'
import { useSchoolsExtensionAttributesSchemaQuery } from 'apollo/school_manager/queries/SchoolsExtensionAttributesSchema.generated'
import { CircularProgress } from '@mui/material'
import { IChangeEvent } from '@rjsf/core'
import { RJSFSchema } from '@rjsf/utils'
import { useForm } from 'react-final-form'
import { Record } from 'react-admin'
import { useEffect, useState } from 'react'
import { deserializeData, serializeData } from './utils'
import { uiSchema, widgets } from './uiSchema'

export interface VariablesProps {
    record: Record | undefined
    entityType: string
}

export const Variables: React.FC<VariablesProps> = ({ record, entityType }) => {
    const [schema, setSchema] = useState<RJSFSchema>()
    const [initialFormData, setInitialFormData] = useState<any>(null)
    const { change } = useForm()
    const { data: { schoolsExtensionAttributesSchema } = {}, error } =
        useSchoolsExtensionAttributesSchemaQuery({
            variables: {
                entityType
            }
        })

    /**
     * Handles a change event for an RJSF form by serializing the data and updating the form state.
     *
     * @param data - The IChangeEvent object containing the changed form data.
     */
    const handleChange = (data: IChangeEvent<any, RJSFSchema, any>) => {
        change('extension_attributes', serializeData(data))
        setInitialFormData(data?.formData || {})
    }

    /**
     * Sets the RJSF schema based on the `schoolsExtensionAttributesSchema` state variable.
     */
    useEffect(() => {
        if (schoolsExtensionAttributesSchema) {
            setSchema(JSON.parse(schoolsExtensionAttributesSchema || '{}'))
        }
    }, [schoolsExtensionAttributesSchema])

    /**
     * Deserializes the `extension_attributes` data and sets the initial form data based on the `schema` state variable.
     * This effect runs only once, when the component mounts.
     */
    useEffect(() => {
        if (schema && record?.['extension_attributes'] !== undefined && initialFormData === null) {
            const deserializedData = deserializeData(record?.extension_attributes, schema)
            setInitialFormData(deserializedData || {})
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema, record])

    if (error) {
        return <>{`no schema found for ${record?.__typename}`}</>
    }

    return schema && initialFormData !== null ? (
        <Form
            key={'form'}
            schema={schema}
            validator={validator}
            onChange={handleChange}
            formData={initialFormData}
            uiSchema={uiSchema}
            widgets={widgets}
        >
            {/* hack to hide submit button */}
            <button />
        </Form>
    ) : (
        <CircularProgress />
    )
}
