import { CSSProperties } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { floatingLabelClassName, mentionInputClassName } from './MentionsField'

const paddingTop = '21px'
const paddingBottom = '6px'
const padding = `${paddingTop} 0 ${paddingBottom} 0`
const fontSize = 16

export const getPlaceholderStyle = (isSuperscript: boolean) => ({
    top: isSuperscript ? 0 : paddingTop,
    fontSize: isSuperscript ? 0.75 * fontSize : fontSize
})

export const useStyles = makeStyles(({ palette, transitions }: Theme) => {
    return createStyles({
        // @ts-ignore-line
        root: (props) => ({
            [`& .${mentionInputClassName}__control`]: {
                fontSize: fontSize
            },

            [`& .${mentionInputClassName}__input`]: {
                padding,
                border: 0,
                borderBottom: `1px solid ${palette.grey[600]}`,
                color: palette.text.primary,
                transition: transitions.create('border-bottom-color', {
                    duration: transitions.duration.shorter,
                    easing: transitions.easing.easeInOut
                }),
                '&:focus': {
                    outline: 'none',
                    borderBottom: `2px solid ${palette.primary.main}`
                },
                '&:disabled': {
                    color: palette.text.disabled,
                    borderBottom: `1px dotted ${palette.grey[600]}`
                },
                '&:hover:not(:disabled):not(:focus)': {
                    borderBottom: `2px solid ${palette.grey[600]}`
                }
            },

            [`& .${mentionInputClassName}__highlighter`]: {
                padding: padding
            },

            [`& .${mentionInputClassName}__suggestions`]: {
                backgroundColor: palette.background.paper,
                marginTop: `calc(${paddingTop} + ${paddingBottom}) !important`,
                border: `1px solid ${palette.action.disabledBackground}`,
                boxShadow: `0 0 8px ${palette.action.disabled}`,
                width: 'max-content',
                maxHeight: 300,
                overflow: 'auto'
            },

            [`& .${mentionInputClassName}__suggestions__item`]: {
                display: 'flex',
                alignItems: 'center',
                height: 48,
                transition: transitions.create('background-color', {
                    duration: transitions.duration.shortest,
                    easing: transitions.easing.easeInOut
                })
            },

            [`& .${mentionInputClassName}__suggestions__item--focused`]: {
                backgroundColor: palette.action.selected
            },

            [`& .${floatingLabelClassName}`]: () => {
                let color: string = palette.text.secondary

                return {
                    ...getPlaceholderStyle(false),
                    color: color,
                    fontWeight: 'normal',
                    position: 'absolute',
                    pointerEvents: 'none',
                    transition: transitions.create('all', {
                        duration: transitions.duration.shorter,
                        easing: transitions.easing.easeInOut
                    })
                }
            },

            [`& .${floatingLabelClassName}.filled`]: () => {
                return {
                    ...getPlaceholderStyle(true)
                }
            },

            '&:focus-within': {
                [`& .${floatingLabelClassName}`]: {
                    ...getPlaceholderStyle(true),
                    color: palette.primary.main
                }
            }
        })
    })
})

export const defaultMentionStyle: CSSProperties = {
    backgroundColor: '#00dbb4',
    opacity: 0.3,
    padding: 1,
    marginLeft: -2,
    borderRadius: 3
}
