import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteSchoolSchoolTagMutationVariables = Types.Exact<{
  schoolId: Types.Scalars['Int'];
  schoolTagId: Types.Scalars['Int'];
}>;


export type DeleteSchoolSchoolTagMutation = { __typename?: 'Mutation', deleteSchoolSchoolTag?: { __typename?: 'schoolsSchoolSchoolTag', school_id: number, school_tag_id: number } | null | undefined };


export const DeleteSchoolSchoolTagDocument = gql`
    mutation DeleteSchoolSchoolTag($schoolId: Int!, $schoolTagId: Int!) {
  deleteSchoolSchoolTag: schoolsDeleteSchoolSchoolTag(
    school_id: $schoolId
    school_tag_id: $schoolTagId
  ) {
    school_id
    school_tag_id
  }
}
    `;
export type DeleteSchoolSchoolTagMutationFn = Apollo.MutationFunction<DeleteSchoolSchoolTagMutation, DeleteSchoolSchoolTagMutationVariables>;

/**
 * __useDeleteSchoolSchoolTagMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolSchoolTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolSchoolTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolSchoolTagMutation, { data, loading, error }] = useDeleteSchoolSchoolTagMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      schoolTagId: // value for 'schoolTagId'
 *   },
 * });
 */
export function useDeleteSchoolSchoolTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchoolSchoolTagMutation, DeleteSchoolSchoolTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchoolSchoolTagMutation, DeleteSchoolSchoolTagMutationVariables>(DeleteSchoolSchoolTagDocument, options);
      }
export type DeleteSchoolSchoolTagMutationHookResult = ReturnType<typeof useDeleteSchoolSchoolTagMutation>;
export type DeleteSchoolSchoolTagMutationResult = Apollo.MutationResult<DeleteSchoolSchoolTagMutation>;
export type DeleteSchoolSchoolTagMutationOptions = Apollo.BaseMutationOptions<DeleteSchoolSchoolTagMutation, DeleteSchoolSchoolTagMutationVariables>;