import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePresetNodeMutationVariables = Types.Exact<{
  definition?: Types.InputMaybe<Types.Scalars['String']>;
  removed?: Types.InputMaybe<Types.Scalars['Boolean']>;
  configuratorUpdatePresetNodeId: Types.Scalars['ID'];
}>;


export type UpdatePresetNodeMutation = { __typename?: 'Mutation', configuratorUpdatePresetNode?: { __typename?: 'configuratorPresetNode', id: string, parent_node_id: number, preset_id: number, removed: boolean, definition: string } | null | undefined };


export const UpdatePresetNodeDocument = gql`
    mutation UpdatePresetNode($definition: String, $removed: Boolean, $configuratorUpdatePresetNodeId: ID!) {
  configuratorUpdatePresetNode(
    definition: $definition
    removed: $removed
    id: $configuratorUpdatePresetNodeId
  ) {
    id
    parent_node_id
    preset_id
    removed
    definition
  }
}
    `;
export type UpdatePresetNodeMutationFn = Apollo.MutationFunction<UpdatePresetNodeMutation, UpdatePresetNodeMutationVariables>;

/**
 * __useUpdatePresetNodeMutation__
 *
 * To run a mutation, you first call `useUpdatePresetNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePresetNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePresetNodeMutation, { data, loading, error }] = useUpdatePresetNodeMutation({
 *   variables: {
 *      definition: // value for 'definition'
 *      removed: // value for 'removed'
 *      configuratorUpdatePresetNodeId: // value for 'configuratorUpdatePresetNodeId'
 *   },
 * });
 */
export function useUpdatePresetNodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePresetNodeMutation, UpdatePresetNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePresetNodeMutation, UpdatePresetNodeMutationVariables>(UpdatePresetNodeDocument, options);
      }
export type UpdatePresetNodeMutationHookResult = ReturnType<typeof useUpdatePresetNodeMutation>;
export type UpdatePresetNodeMutationResult = Apollo.MutationResult<UpdatePresetNodeMutation>;
export type UpdatePresetNodeMutationOptions = Apollo.BaseMutationOptions<UpdatePresetNodeMutation, UpdatePresetNodeMutationVariables>;