import TabIcon from '@material-ui/icons/Tab'
import ListIcon from '@material-ui/icons/List'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import TextFormatOutlinedIcon from '@material-ui/icons/TextFormatOutlined'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import ArtTrackOutlinedIcon from '@material-ui/icons/ArtTrackOutlined'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import DialpadOutlinedIcon from '@material-ui/icons/DialpadOutlined'
import { lighten } from '@material-ui/core'
import cx from 'classnames'

import { COLORS } from 'layout/theme/colors'
import { TreeItem } from 'react-sortable-tree'
import { findPresetByPresetIdInArray } from 'components/presetManager/helpers/TreeHelperFunctions'

export const getNodeStyle = (node: TreeItem) => {
    const { descendants_have_nodes_of_presets: descendants, presetId } = node
    const index = findPresetByPresetIdInArray(descendants, presetId)

    // Booleans
    const isPreset = !!node?.presetNode
    const hasPresetDescendants = index > -1
    const removed = node?.presetNode?.removed

    // BorderStyle
    const presetBorder = `1px solid ${COLORS.theme.grey.main}` // No thick border
    const bothBorder = `0.5rem solid ${COLORS.theme.amber.main}`
    const presetDescendantsBorder = `0.5rem solid ${lighten(COLORS.theme.amber.main, 0.01)}` // This has to be a VERY slight different color or the tree stops rendering the color
    const borderStyle = {
        [presetBorder]: isPreset && !hasPresetDescendants,
        [presetDescendantsBorder]: !isPreset && hasPresetDescendants,
        [bothBorder]: hasPresetDescendants && isPreset
    }

    return {
        borderLeft: cx(borderStyle),
        textIndent: cx({ '0': hasPresetDescendants || (hasPresetDescendants && isPreset) }), // To align text and icons in the normal and isPreset node
        opacity: cx('1', { '0.5': removed })
    }
}

export const getIcon = (type: string) => {
    const style = { color: COLORS.theme.grey.main, marginRight: '0.5em' }
    switch (type) {
        case 'tab':
            return <VerticalSplitIcon style={style} />
        case 'fieldset':
            return <TabIcon style={style} />
        case 'select':
            return <ListIcon style={style} />
        case 'string':
            return <TextFormatOutlinedIcon style={style} />
        case 'number':
            return <DialpadOutlinedIcon style={style} />
        case 'upload':
            return <CloudUploadOutlinedIcon style={style} />
        case 'option':
            return <LabelOutlinedIcon style={style} />
        case 'content':
            return <ArtTrackOutlinedIcon style={style} />
        case 'default':
        case 'root':
            return <AccountTreeOutlinedIcon style={style} />
    }
}

export const getNodeTitle = (node: TreeItem) => {
    const { title, node_attributes, presetNode } = node
    const label = node_attributes?.label
    const type: string = node_attributes?.type || 'root'
    const isRemoved = presetNode && presetNode?.removed
    const hasPreset = !!presetNode

    return (
        <>
            <span className="tree-title">
                {getIcon(type)} {label ? label + ` (${title})` : title}
            </span>
            <div
                className={cx('color-overlay', {
                    'color-overlay--is-removed': isRemoved,
                    'color-overlay--is-preset': hasPreset
                })}
            />
        </>
    )
}
