import { DialogTitle, IconButton } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { IChangeEvent } from '@rjsf/core'
import { RJSFSchema } from '@rjsf/utils/dist'
import Form from '@rjsf/material-ui'
import { useCreateNodeMutation } from 'apollo/configurator/mutations/CreateNode.generated'
import { useModelSchemaQuery } from 'apollo/configurator/queries/ModelSchema.generated'
import { JSONSchema7 } from 'json-schema'
import React, { useEffect, useState } from 'react'
import { ExtendedNodeData, TreeItem } from 'react-sortable-tree'
import { getAvailableTypes, getSchemaByNode } from 'utils/Schema'
import { addNode } from 'components/modelManager/molecules/manager/helpers/TreeHelperFunctions'
import { useTranslate } from 'react-admin'
import validator from '@rjsf/validator-ajv8'

type TFormNode = { id: string; open: boolean; rowInfo: ExtendedNodeData | null }

interface INodeForm extends TFormNode {
    setForm: ({ id, open, rowInfo }: TFormNode) => void
    treeState: TreeItem[] | null
    setTreeState: (treeState: TreeItem[] | null) => void
}

const useStyles = makeStyles(() =>
    createStyles({
        content: {
            padding: 20
        },
        titleContainer: {
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20
        },
        title: {
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 0,
            paddingRight: 0
        },
        iconButton: {
            height: 50,
            width: 50
        },
        formControl: {
            margin: 1,
            minWidth: 120
        }
    })
)

const AddNodeForm = ({ id, open, rowInfo, setForm, treeState, setTreeState }: INodeForm) => {
    const { data: { configuratorModelSchema: modelSchema } = {} } = useModelSchemaQuery()
    const modelSchemaObject = modelSchema && JSON.parse(modelSchema)
    const parentType = (id && rowInfo?.node?.node_attributes.type) || 'root'
    const types = (id && parentType && getAvailableTypes(modelSchemaObject, parentType)) || []
    const [formData, setFormData] = useState<unknown>({})
    const [nodeSchema, setNodeSchema] = useState<JSONSchema7>({})
    const [type, setType] = useState<string>('')
    const [createNode] = useCreateNodeMutation({})
    const classes = useStyles()
    const translate = useTranslate()
    const uiSchema = {
        version: { 'ui:readonly': true },
        constraints: { 'ui:readonly': true },
        upsell: { 'ui:readonly': true },
        valueGroups: { 'ui:readonly': true },
        config: {
            value_group: { 'ui:readonly': true },
            validation: { 'ui:readonly': true }
        },
        description: {
            'ui:widget': 'textarea',
            'ui:options': {
                rows: 5
            }
        },
        description_short: {
            'ui:widget': 'textarea',
            'ui:options': {
                rows: 2
            }
        }
    }
    const handleTypeChange = (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
        setFormData(null)
        setNodeSchema(modelSchemaObject && getSchemaByNode(modelSchemaObject, type, true))
        const { value } = event?.target
        value && setType(value as string)
    }

    useEffect(() => {
        type &&
            type.length &&
            setNodeSchema(modelSchemaObject && getSchemaByNode(modelSchemaObject, type, true))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    const OnSaveHandler = (e: IChangeEvent<any, RJSFSchema, any>) => {
        const { formData } = e
        if (!formData.config) {
            formData.config = {}
        }
        formData.type = type
        createNode({
            variables: {
                parent_id: +id,
                node_attributes: JSON.stringify(formData)
            }
        }).then(async (res) => {
            const createNode = res?.data?.configuratorCreateNode
            if (treeState && rowInfo && createNode) {
                const addFolder = await addNode(
                    treeState,
                    createNode,
                    rowInfo,
                    createNode?.parent_id || null
                )
                addFolder && setTreeState([...addFolder?.treeData])
            }
        })
        setForm({ id: '', open: false, rowInfo: null })
        setFormData(null)
        setNodeSchema({})
    }

    const handleClose = () => {
        setForm({ id: '', open: false, rowInfo: null })
        setFormData(null)
        setNodeSchema({})
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                onBackdropClick={handleClose}
                onEscapeKeyDown={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <Grid container className={classes.titleContainer}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                            <DialogTitle className={classes.title} id="form-dialog-title">
                                {translate(
                                    'manager.resources.model_manager.node_form_dialog.title'
                                )}
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                className={classes.iconButton}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <DialogContent className={classes.content}>
                    <DialogContentText>
                        {translate('manager.resources.model_manager.node_form_dialog.content')}
                    </DialogContentText>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="select-new-node-type-label">
                            {translate('manager.resources.model_manager.node_form_dialog.type')}
                        </InputLabel>
                        <Select className={classes.formControl} onChange={handleTypeChange}>
                            {types.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {type.length > 0 && nodeSchema && (
                        <Form
                            showErrorList={false}
                            schema={nodeSchema}
                            uiSchema={uiSchema}
                            formData={formData}
                            onSubmit={OnSaveHandler}
                            validator={validator}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddNodeForm
