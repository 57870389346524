import AllInboxIcon from '@material-ui/icons/AllInbox'
import PackageEdit from './PackageEdit'
import PackageList from './PackageList'
import PackageCreate from './PackageCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: PackageList,
    edit: PackageEdit,
    create: PackageCreate,
    icon: AllInboxIcon
}
