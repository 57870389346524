import { COLORS } from 'layout/theme/colors'
import styled from 'styled-components'

export const TreeViewWrapper = styled.section`
    .rst__row {
        position: relative;
        border-left: 1px solid ${COLORS.theme.grey.main};
        text-indent: 0.5rem;

        .rst__rowContents {
            border: 1px solid ${COLORS.theme.grey.main};
            padding: 0 0.5rem;
            box-shadow: none;
            border-left: none;
        }
    }
    .rst__rowWrapper {
        padding: 5px 0;
    }
    .rst__rowLabel {
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
    }

    .rst__rowTitle {
        background-color: transparent;
        cursor: pointer;
        font-weight: normal;
        height: 100%;

        .tree-title {
            height: 100%;
            color: black;
            display: inline-flex;
            align-items: center;
            z-index: 1;
            position: relative;
            text-indent: 0;

            &--is-preset {
                text-indent: 0.5rem;
            }
        }

        .color-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.4;

            &--is-preset {
                background-color: ${COLORS.theme.amber.light};
            }

            &--is-removed {
                background-color: ${COLORS.theme.grey.light};
                opacity: 0.75;
                z-index: 2;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .rst__rowToolbar {
        height: 100%;
        position: relative;
        z-index: 3; // For being able to click "through" overlay

        .rst__toolbarButton {
            height: 100%;
            position: relative; // For AllVisiblity button which has absolute positioned text
            text-indent: 0;
        }
    }
`
