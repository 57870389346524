import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeletePresetNodeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeletePresetNodeMutation = { __typename?: 'Mutation', configuratorDeletePresetNode?: { __typename?: 'configuratorPresetNode', definition: string, parent_node_id: number } | null | undefined };


export const DeletePresetNodeDocument = gql`
    mutation DeletePresetNode($id: ID!) {
  configuratorDeletePresetNode(id: $id) {
    definition
    parent_node_id
  }
}
    `;
export type DeletePresetNodeMutationFn = Apollo.MutationFunction<DeletePresetNodeMutation, DeletePresetNodeMutationVariables>;

/**
 * __useDeletePresetNodeMutation__
 *
 * To run a mutation, you first call `useDeletePresetNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePresetNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePresetNodeMutation, { data, loading, error }] = useDeletePresetNodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePresetNodeMutation(baseOptions?: Apollo.MutationHookOptions<DeletePresetNodeMutation, DeletePresetNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePresetNodeMutation, DeletePresetNodeMutationVariables>(DeletePresetNodeDocument, options);
      }
export type DeletePresetNodeMutationHookResult = ReturnType<typeof useDeletePresetNodeMutation>;
export type DeletePresetNodeMutationResult = Apollo.MutationResult<DeletePresetNodeMutation>;
export type DeletePresetNodeMutationOptions = Apollo.BaseMutationOptions<DeletePresetNodeMutation, DeletePresetNodeMutationVariables>;