import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, DeleteButton, SaveButton } from 'react-admin'

const useToolbarStyles = makeStyles({
    toolbar: {
        justifyContent: 'space-between'
    }
})

// TODO: refactor to be more generic so it is possible to copy paste more easily for a new component, maybe use {{children}}
const PackageProductEditToolbar = (props: any) => {
    const classes = useToolbarStyles()

    return (
        <Toolbar {...props} className={classes.toolbar}>
            <SaveButton />
            <DeleteButton redirect={`/Package/${props.record?.package_id}`} />
        </Toolbar>
    )
}

export default PackageProductEditToolbar
