import React, { useContext } from 'react'
import { useTranslate } from 'react-admin'

import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'

import { GalleryContext } from 'context/gallery/GalleryContext'

interface GalleryImageProps {
    galleryImage: string
    onClick: () => void
    selected: {
        id: string
        file: string
    }
    handleDelete: (from: string, remove: string) => void
}

const useStyles = makeStyles({
    root: {
        height: 275,
        maxWidth: 345,
        width: 230,
        marginBottom: 20
    },
    mediaWrapper: {
        backgroundImage: ` url('/img/checkered-bg.png')`,
        height: 140,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    media: {
        height: 140,
        backgroundSize: 'contain'
    },
    iconWrapper: {
        height: 140,
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column wrap'
    },
    icon: {
        alignSelf: 'center'
    }
})

const GalleryImage: React.FC<GalleryImageProps> = ({
    selected,
    galleryImage,
    onClick,
    handleDelete
}) => {
    const REACT_APP_MEDIA_URL = process.env.REACT_APP_MEDIA_URL
    const { state } = useContext(GalleryContext)
    const classes = useStyles()
    const translate = useTranslate()

    // The reason for this reformatting is that the newly created rootnode is called '/' which adds some issues with the file names concatting duplicate slashes.
    const imageUrl = `${REACT_APP_MEDIA_URL}${
        state?.breadcrumbs.replace('//', '') + '/'
    }${galleryImage}`

    return (
        <Grid item>
            {galleryImage ? (
                <Card
                    className={classes.root}
                    onClick={onClick}
                    style={{
                        border: selected.file === galleryImage ? '2px solid dodgerblue' : ''
                    }}
                >
                    <CardActionArea>
                        <div className={classes.mediaWrapper}>
                            {!REACT_APP_MEDIA_URL ? (
                                <div className={classes.iconWrapper}>
                                    <ImageNotSupportedIcon
                                        className={classes.icon}
                                        fontSize="large"
                                    />
                                </div>
                            ) : (
                                <CardMedia className={classes.media} image={imageUrl} />
                            )}
                        </div>
                        <CardContent>
                            <Typography
                                style={{ wordBreak: 'break-word' }}
                                gutterBottom
                                variant="body2"
                                component="h2"
                            >
                                {galleryImage.split('.')[0]}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => handleDelete(state.breadcrumbs, galleryImage)}
                        >
                            {translate('manager.gallery.delete')}
                        </Button>
                    </CardActions>
                </Card>
            ) : (
                <div>
                    <Skeleton variant="rect" width={210} height={135} />
                    <Skeleton variant="text" width={210} height={40} />
                </div>
            )}
        </Grid>
    )
}

export default GalleryImage
