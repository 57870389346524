import {
    Button,
    createStyles,
    IconButton,
    Theme,
    Typography,
    WithStyles,
    withStyles
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { useTranslate } from 'react-admin'

interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string
    children: React.ReactNode
    onClose: () => void
    toggleUpload?: () => void
    isUploading: boolean
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        uploadButton: {
            position: 'absolute',
            right: theme.spacing(10),
            top: theme.spacing(2),
            color: 'white'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    })

const GalleryPopupTitle = withStyles(styles)(
    ({ children, classes, onClose, toggleUpload, isUploading, ...other }: DialogTitleProps) => {
        const translate = useTranslate()
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {toggleUpload && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => toggleUpload()}
                        className={classes.uploadButton}
                    >
                        {isUploading
                            ? translate('manager.gallery.close')
                            : translate('manager.gallery.upload_media')}
                    </Button>
                )}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        )
    }
)

export default GalleryPopupTitle
