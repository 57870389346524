import { Record, useNotify } from 'ra-core'
import {
    Datagrid,
    FunctionField,
    List,
    ListProps,
    TextField,
    useTranslate,
    Pagination,
    useRedirect
} from 'react-admin'

import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globals'

import datagridStyles from '../../layout/styles/datagridStyles'
import DatagridPublishedColumnContent from 'components/common/DatagridPublishedColumnContent'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import BulkActionButtons from 'components/common/BulkActionButtons'
import PackageGroupListFilter from './PackageGroupListFilters'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import { useDuplicatePackageGroupMutation } from '../../apollo/package_manager/mutations/DuplicatePackageGroup.generated'
import { ChangeEvent, MouseEvent, useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography
} from '@material-ui/core'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import MaterialTextfield from '@material-ui/core/TextField'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'

const useStyles = makeStyles({
    chip: { marginRight: '1px' },
    list: {
        '& .MuiToolbar-gutters': { width: 'auto' }
    },
    listInCollection: {
        width: '100%',
        '& .MuiPaper-root': { border: 0 }
    },
    fileIcon: {
        '&:hover': {
            transform: `scale(1.1)`
        }
    },
    dialogActions: {
        padding: 0,
        paddingBottom: '1rem'
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px'
    }
})

const PackageGroupList = (props: ListProps) => {
    const RESOURCE = 'PackageGroup'
    const translate = useTranslate()
    const datagridClasses = datagridStyles()
    const classes = useStyles()
    const [duplicateId, setDuplicateId] = useState('')
    const [publicName, setPublicName] = useState('')
    const [internalName, setInternalName] = useState('')
    const [open, setOpen] = useState(false)
    const [duplicatePackageGroupMutation, { loading: isLoadingDuplication }] =
        useDuplicatePackageGroupMutation()
    const redirect = useRedirect()
    const notify = useNotify()

    const togglePopup = (): void => {
        setOpen(!open)
    }

    const handlePublicNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublicName(e.target.value)
    }

    const handleInternalNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInternalName(e.target.value)
    }

    const handleDuplicateClick = (e: MouseEvent<SVGSVGElement>, id: string) => {
        e?.stopPropagation() // Stops rowClick from firing
        setPublicName('')
        setInternalName('')
        setDuplicateId(id)
        togglePopup()
    }

    const handlePopupSubmit = () => {
        duplicatePackageGroupMutation({
            variables: { id: duplicateId, internal_name: internalName, public_name: publicName }
        })
            .then((result) => {
                redirect('edit', '/PackageGroup', result.data?.duplicatePackageGroup?.id)
            })
            .catch((error) => notify(error.message, 'error'))
    }

    return (
        <>
            <List
                basePath={`/${RESOURCE}`}
                resource={RESOURCE}
                sort={{ field: 'internal_name', order: 'ASC' }}
                empty={false}
                perPage={DEFAULT_PER_PAGE}
                bulkActionButtons={<BulkActionButtons isWithActionsFlush />}
                filter={props?.filter}
                filters={PackageGroupListFilter}
                className={`${classes.list} ${props?.filter && classes.listInCollection}`}
                pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
            >
                <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                    <TextField
                        label={translate('manager.resources.packageGroup.public_name')}
                        source="public_name"
                    />
                    <TextField
                        label={translate('manager.resources.packageGroup.internal_name')}
                        source="internal_name"
                    />
                    <FunctionField
                        label={translate('manager.resources.packageGroup.product_preset')}
                        render={(record?: Record | undefined) =>
                            record?.presets && record.presets.length > 0 ? (
                                record.presets.map((t) => (
                                    <Chip size="small" label={t.code} className={classes.chip} />
                                ))
                            ) : (
                                <> - </>
                            )
                        }
                    />
                    <FunctionField
                        sortBy="active"
                        source="active"
                        label={translate('manager.resources.general.status')}
                        render={(record?: Record | undefined) => (
                            <DatagridPublishedColumnContent record={record} />
                        )}
                    />
                    <DateFieldCustom
                        label={translate('manager.resources.general.last_updated')}
                        source="updated_at"
                        showTime
                        transform={(value) => formatDateString(value, true)}
                    />
                    <FunctionField
                        label={translate('manager.resources.general.actions')}
                        render={(record?: Record | undefined) =>
                            record?.id && (
                                <Tooltip title={translate('manager.resources.general.duplicate')}>
                                    <FileCopyIcon
                                        className={classes.fileIcon}
                                        onClick={(e: MouseEvent<SVGSVGElement>) =>
                                            handleDuplicateClick(e, record.id.toString())
                                        }
                                    />
                                </Tooltip>
                            )
                        }
                    />
                </Datagrid>
            </List>
            <Dialog fullWidth open={open} onClose={togglePopup}>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.packageGroup.duplicate_package_group')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.package.public_name')}
                        onChange={handlePublicNameChange}
                    />
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.packageGroup.internal_name')}
                        onChange={handleInternalNameChange}
                    />
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={togglePopup} variant="contained" color="default">
                            {translate('manager.resources.packageGroup.package.close')}
                        </Button>
                        <Button
                            onClick={handlePopupSubmit}
                            variant="contained"
                            color="primary"
                            disabled={!publicName || !internalName || isLoadingDuplication}
                        >
                            {translate('manager.resources.packageGroup.package.add_button')}
                            {isLoadingDuplication && (
                                <CircularProgress size={24} className={classes.spinner} />
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default PackageGroupList
