import { List, Datagrid, TextField, ListProps, FunctionField, Pagination } from 'react-admin'
import { useTranslate, Record } from 'ra-core'
import datagridStyles from '../../layout/styles/datagridStyles'
import SchoolYearListFilter from './EducationListFilters'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globals'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'

const EducationList = (props: ListProps) => {
    const datagridClasses = datagridStyles()
    const translate = useTranslate()

    return (
        <List
            {...props}
            sort={{ field: 'name', order: 'DESC' }}
            perPage={DEFAULT_PER_PAGE}
            filters={SchoolYearListFilter}
            bulkActionButtons={<BulkActionButtons />}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
        >
            <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                <TextField source="name" label={translate('manager.resources.education.name')} />
                <FunctionField
                    source="active"
                    label={translate('manager.resources.general.status')}
                    render={(record?: Record | undefined) => (
                        <DatagridPublishedColumnContent record={record} />
                    )}
                />
                <TextField
                    source="priority"
                    label={translate('manager.resources.education.priority')}
                />
                <DateFieldCustom
                    label={translate('manager.resources.general.last_updated')}
                    source="updated_at"
                    showTime
                    transform={(value) => formatDateString(value, true)}
                />
            </Datagrid>
        </List>
    )
}
export default EducationList
