import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateSchoolSchoolTagMutationVariables = Types.Exact<{
  schoolId: Types.Scalars['Int'];
  schoolTagId: Types.Scalars['Int'];
}>;


export type CreateSchoolSchoolTagMutation = { __typename?: 'Mutation', createSchoolSchoolTag?: { __typename?: 'schoolsSchoolSchoolTag', school_id: number, school_tag_id: number } | null | undefined };


export const CreateSchoolSchoolTagDocument = gql`
    mutation CreateSchoolSchoolTag($schoolId: Int!, $schoolTagId: Int!) {
  createSchoolSchoolTag: schoolsCreateSchoolSchoolTag(
    school_id: $schoolId
    school_tag_id: $schoolTagId
  ) {
    school_id
    school_tag_id
  }
}
    `;
export type CreateSchoolSchoolTagMutationFn = Apollo.MutationFunction<CreateSchoolSchoolTagMutation, CreateSchoolSchoolTagMutationVariables>;

/**
 * __useCreateSchoolSchoolTagMutation__
 *
 * To run a mutation, you first call `useCreateSchoolSchoolTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolSchoolTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolSchoolTagMutation, { data, loading, error }] = useCreateSchoolSchoolTagMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      schoolTagId: // value for 'schoolTagId'
 *   },
 * });
 */
export function useCreateSchoolSchoolTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolSchoolTagMutation, CreateSchoolSchoolTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolSchoolTagMutation, CreateSchoolSchoolTagMutationVariables>(CreateSchoolSchoolTagDocument, options);
      }
export type CreateSchoolSchoolTagMutationHookResult = ReturnType<typeof useCreateSchoolSchoolTagMutation>;
export type CreateSchoolSchoolTagMutationResult = Apollo.MutationResult<CreateSchoolSchoolTagMutation>;
export type CreateSchoolSchoolTagMutationOptions = Apollo.BaseMutationOptions<CreateSchoolSchoolTagMutation, CreateSchoolSchoolTagMutationVariables>;