import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HasNodesOfPresetsQueryVariables = Types.Exact<{
  configuratorNodeId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type HasNodesOfPresetsQuery = { __typename?: 'Query', configuratorNode?: { __typename?: 'configuratorNode', has_nodes_of_presets?: Array<{ __typename?: 'configuratorNodePresetNode', preset_id: number } | null | undefined> | null | undefined } | null | undefined };


export const HasNodesOfPresetsDocument = gql`
    query HasNodesOfPresets($configuratorNodeId: ID) {
  configuratorNode(id: $configuratorNodeId) {
    has_nodes_of_presets {
      preset_id
    }
  }
}
    `;

/**
 * __useHasNodesOfPresetsQuery__
 *
 * To run a query within a React component, call `useHasNodesOfPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasNodesOfPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasNodesOfPresetsQuery({
 *   variables: {
 *      configuratorNodeId: // value for 'configuratorNodeId'
 *   },
 * });
 */
export function useHasNodesOfPresetsQuery(baseOptions?: Apollo.QueryHookOptions<HasNodesOfPresetsQuery, HasNodesOfPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasNodesOfPresetsQuery, HasNodesOfPresetsQueryVariables>(HasNodesOfPresetsDocument, options);
      }
export function useHasNodesOfPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasNodesOfPresetsQuery, HasNodesOfPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasNodesOfPresetsQuery, HasNodesOfPresetsQueryVariables>(HasNodesOfPresetsDocument, options);
        }
export type HasNodesOfPresetsQueryHookResult = ReturnType<typeof useHasNodesOfPresetsQuery>;
export type HasNodesOfPresetsLazyQueryHookResult = ReturnType<typeof useHasNodesOfPresetsLazyQuery>;
export type HasNodesOfPresetsQueryResult = Apollo.QueryResult<HasNodesOfPresetsQuery, HasNodesOfPresetsQueryVariables>;