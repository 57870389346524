import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateDirMutationVariables = Types.Exact<{
  parent_dir: Types.Scalars['String'];
  dir: Types.Scalars['String'];
}>;


export type CreateDirMutation = { __typename?: 'Mutation', configuratorMakeDir?: { __typename?: 'configuratorMakeDirResult', id?: string | null | undefined, path?: string | null | undefined } | null | undefined };


export const CreateDirDocument = gql`
    mutation CreateDir($parent_dir: String!, $dir: String!) {
  configuratorMakeDir(from: $parent_dir, add: $dir) {
    id
    path
  }
}
    `;
export type CreateDirMutationFn = Apollo.MutationFunction<CreateDirMutation, CreateDirMutationVariables>;

/**
 * __useCreateDirMutation__
 *
 * To run a mutation, you first call `useCreateDirMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirMutation, { data, loading, error }] = useCreateDirMutation({
 *   variables: {
 *      parent_dir: // value for 'parent_dir'
 *      dir: // value for 'dir'
 *   },
 * });
 */
export function useCreateDirMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirMutation, CreateDirMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDirMutation, CreateDirMutationVariables>(CreateDirDocument, options);
      }
export type CreateDirMutationHookResult = ReturnType<typeof useCreateDirMutation>;
export type CreateDirMutationResult = Apollo.MutationResult<CreateDirMutation>;
export type CreateDirMutationOptions = Apollo.BaseMutationOptions<CreateDirMutation, CreateDirMutationVariables>;