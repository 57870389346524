import { useAuthProvider } from 'context/auth/AuthProvider'
import { MenuItemLink } from 'react-admin'

export const MenuItemLinkWrapper = (props) => {
    const { permissions, isLoading } = useAuthProvider()

    if (isLoading) {
        return <></>
    }

    return permissions?.[props?.name]?.length ? <MenuItemLink {...props} /> : <></>
}
