import { BulkDeleteButton } from 'react-admin'

import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'

// Bulk delete with Confirm
const BulkActionButtons = (props) => {
    const [flushActionsCache] = useFlushActionsCacheMutation()

    const handleDeleteButtonClick = () =>
        props.isWithActionsFlush ? flushActionsCache() : undefined

    return <BulkDeleteButton undoable={false} onClick={handleDeleteButtonClick} {...props} />
}
export default BulkActionButtons
