import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteDirMutationVariables = Types.Exact<{
  dir: Types.Scalars['String'];
  parent_dir: Types.Scalars['String'];
}>;


export type DeleteDirMutation = { __typename?: 'Mutation', configuratorRemoveDir?: boolean | null | undefined };


export const DeleteDirDocument = gql`
    mutation DeleteDir($dir: String!, $parent_dir: String!) {
  configuratorRemoveDir(from: $parent_dir, remove: $dir)
}
    `;
export type DeleteDirMutationFn = Apollo.MutationFunction<DeleteDirMutation, DeleteDirMutationVariables>;

/**
 * __useDeleteDirMutation__
 *
 * To run a mutation, you first call `useDeleteDirMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDirMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDirMutation, { data, loading, error }] = useDeleteDirMutation({
 *   variables: {
 *      dir: // value for 'dir'
 *      parent_dir: // value for 'parent_dir'
 *   },
 * });
 */
export function useDeleteDirMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDirMutation, DeleteDirMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDirMutation, DeleteDirMutationVariables>(DeleteDirDocument, options);
      }
export type DeleteDirMutationHookResult = ReturnType<typeof useDeleteDirMutation>;
export type DeleteDirMutationResult = Apollo.MutationResult<DeleteDirMutation>;
export type DeleteDirMutationOptions = Apollo.BaseMutationOptions<DeleteDirMutation, DeleteDirMutationVariables>;