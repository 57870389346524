import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GalleryStructureQueryVariables = Types.Exact<{
  from: Types.Scalars['String'];
}>;


export type GalleryStructureQuery = { __typename?: 'Query', configuratorGalleryStructure?: string | null | undefined };


export const GalleryStructureDocument = gql`
    query GalleryStructure($from: String!) {
  configuratorGalleryStructure(from: $from)
}
    `;

/**
 * __useGalleryStructureQuery__
 *
 * To run a query within a React component, call `useGalleryStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryStructureQuery({
 *   variables: {
 *      from: // value for 'from'
 *   },
 * });
 */
export function useGalleryStructureQuery(baseOptions: Apollo.QueryHookOptions<GalleryStructureQuery, GalleryStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GalleryStructureQuery, GalleryStructureQueryVariables>(GalleryStructureDocument, options);
      }
export function useGalleryStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GalleryStructureQuery, GalleryStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GalleryStructureQuery, GalleryStructureQueryVariables>(GalleryStructureDocument, options);
        }
export type GalleryStructureQueryHookResult = ReturnType<typeof useGalleryStructureQuery>;
export type GalleryStructureLazyQueryHookResult = ReturnType<typeof useGalleryStructureLazyQuery>;
export type GalleryStructureQueryResult = Apollo.QueryResult<GalleryStructureQuery, GalleryStructureQueryVariables>;