import React, { useState, useEffect } from 'react'
import { Admin, Loading } from 'react-admin' // eslint-disable-line import/no-unresolved
import { useApolloClient } from '@apollo/client'
import buildGraphQLProvider from 'ra-data-graphql-simple'
import { DataProvider } from 'ra-core'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { useAuth0 } from '@auth0/auth0-react'

import customRoutes from './routes'
import { introspection, introspectionOperationNames } from './apollo/introspection'
import { queryBuilder } from './apollo/queryBuilder'
import AuthProvider from './AuthProvider'
import englishMessages from './i18n/en'
import themeReducer from './themeReducer'
import layout from './layout/Layout'
import Dashboard from './dashboard/dashboard'
import Login from './layout/Login'

import { ErrorProvider } from './context/error/ErrorContext'
import { ModelProvider } from './context/model/ModelContext'
import { GalleryProvider } from './context/gallery/GalleryContext'
import { AuthProvider as AuthProviderWrapper } from './context/auth/AuthProvider'

import { ResourceWrapper } from 'components/common/ResourceWrapper'

// Resources
import collections from './resources/packageGroupCollection'
import packageGroup from 'resources/packageGroup'
import packages from './resources/packages'
import packageGalleryItem from 'resources/packageGalleryItem'
import products from './resources/packageProduct'
import school from './resources/schools'
import schoolClass from './resources/schoolClass'
import schoolYear from './resources/schoolYear'
import schoolClassRepresentative from './resources/schoolClassRepresentative'
import schoolPeriod from './resources/schoolPeriod'
import education from './resources/education'
import schoolTag from './resources/schoolTag'
import presets from './resources/presets'
import rule from 'resources/personalizationRule'
import packageGroupCollectionGalleryItem from 'resources/packageGroupCollectionGalleryItem'
import packageGroupGalleryItem from 'resources/packageGroupGalleryItem'
import { Head } from 'Head'

const i18nProvider = polyglotI18nProvider((locale) => {
    return englishMessages // Fallback on english
}, 'en')

const App = (): React.ReactElement => {
    const { isLoading: isAuth0Loading } = useAuth0()
    const [dataProvider, setDataProvider] = useState<DataProvider>()
    const client = useApolloClient()
    const CLIENT_OPTIONS = {
        client: client,
        buildQuery: queryBuilder,
        resolveIntrospection: introspection,
        introspection: {
            operationNames: introspectionOperationNames,
            exclude: undefined,
            include: undefined
        }
    }

    useEffect(() => {
        buildGraphQLProvider(CLIENT_OPTIONS).then((graphQlDataProvider: DataProvider) =>
            setDataProvider(() => graphQlDataProvider)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!dataProvider || isAuth0Loading) {
        return (
            <Loading
                loadingPrimary="Loading"
                loadingSecondary="The page is loading, just a moment please"
            />
        )
    }

    return (
        <ErrorProvider>
            <AuthProviderWrapper>
                <ModelProvider>
                    <GalleryProvider>
                        <Admin
                            customReducers={{ theme: themeReducer }}
                            customRoutes={customRoutes}
                            dataProvider={dataProvider}
                            authProvider={AuthProvider}
                            i18nProvider={i18nProvider}
                            dashboard={Dashboard}
                            layout={layout}
                            loginPage={Login}
                        >
                            <Head />
                            <ResourceWrapper name="Education" {...education} />
                            <ResourceWrapper name="Package" {...packages} />
                            <ResourceWrapper name="PackageGalleryItem" {...packageGalleryItem} />
                            <ResourceWrapper name="PackageGroupCollection" {...collections} />
                            <ResourceWrapper name="PackageProduct" {...products} />
                            <ResourceWrapper name="PackageGroup" {...packageGroup} />
                            <ResourceWrapper
                                name="PackageGroupCollectionGalleryItem"
                                {...packageGroupCollectionGalleryItem}
                            />
                            <ResourceWrapper
                                name="PackageGroupGalleryItem"
                                {...packageGroupGalleryItem}
                            />
                            <ResourceWrapper name="Rule" {...rule} />
                            <ResourceWrapper name="Preset" {...presets} />
                            <ResourceWrapper name="School" {...school} />
                            <ResourceWrapper name="SchoolClass" {...schoolClass} />
                            <ResourceWrapper
                                name="SchoolClassRepresentative"
                                {...schoolClassRepresentative}
                            />
                            <ResourceWrapper name="SchoolPeriod" {...schoolPeriod} />
                            <ResourceWrapper name="SchoolTag" {...schoolTag} />
                            <ResourceWrapper name="SchoolYear" {...schoolYear} />
                        </Admin>
                    </GalleryProvider>
                </ModelProvider>
            </AuthProviderWrapper>
        </ErrorProvider>
    )
}

export default App
