import { useState } from 'react'
import { Edit, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import PackageGroupForm from './PackageGroupForm'

const PackageGroupEdit = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} backToRouteURL="/packageGroup" />
            <Edit
                title={translate('manager.resources.packageGroup.edit')}
                undoable={false}
                {...props}
            >
                <PackageGroupForm
                    isEdit
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Edit>
        </>
    )
}

export default PackageGroupEdit
