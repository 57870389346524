import { useAuthProvider } from 'context/auth/AuthProvider'
import { useContext, useEffect } from 'react'
import { GalleryContext } from '../../../context/gallery/GalleryContext'
import Manager from '../organisms/Manager'

const HomepageTemplate = () => {
    const { state, setGalleryState } = useContext(GalleryContext)
    const { permissions, isLoading } = useAuthProvider()

    useEffect(() => {
        setGalleryState({ ...state, isUploading: false })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // disabled because this breaks selecting a model

    if (isLoading) {
        return <></>
    }

    return permissions?.['Model']?.length ? <Manager /> : <></>
}

export default HomepageTemplate
