import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PersonalizationUpdateRulesPathsMutationVariables = Types.Exact<{
  updates: Array<Types.PersonalizationRulePathUpdateInput> | Types.PersonalizationRulePathUpdateInput;
}>;


export type PersonalizationUpdateRulesPathsMutation = { __typename?: 'Mutation', personalizationUpdateRulesPaths?: boolean | null | undefined };


export const PersonalizationUpdateRulesPathsDocument = gql`
    mutation PersonalizationUpdateRulesPaths($updates: [personalizationRulePathUpdateInput!]!) {
  personalizationUpdateRulesPaths(updates: $updates)
}
    `;
export type PersonalizationUpdateRulesPathsMutationFn = Apollo.MutationFunction<PersonalizationUpdateRulesPathsMutation, PersonalizationUpdateRulesPathsMutationVariables>;

/**
 * __usePersonalizationUpdateRulesPathsMutation__
 *
 * To run a mutation, you first call `usePersonalizationUpdateRulesPathsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonalizationUpdateRulesPathsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personalizationUpdateRulesPathsMutation, { data, loading, error }] = usePersonalizationUpdateRulesPathsMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function usePersonalizationUpdateRulesPathsMutation(baseOptions?: Apollo.MutationHookOptions<PersonalizationUpdateRulesPathsMutation, PersonalizationUpdateRulesPathsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PersonalizationUpdateRulesPathsMutation, PersonalizationUpdateRulesPathsMutationVariables>(PersonalizationUpdateRulesPathsDocument, options);
      }
export type PersonalizationUpdateRulesPathsMutationHookResult = ReturnType<typeof usePersonalizationUpdateRulesPathsMutation>;
export type PersonalizationUpdateRulesPathsMutationResult = Apollo.MutationResult<PersonalizationUpdateRulesPathsMutation>;
export type PersonalizationUpdateRulesPathsMutationOptions = Apollo.BaseMutationOptions<PersonalizationUpdateRulesPathsMutation, PersonalizationUpdateRulesPathsMutationVariables>;