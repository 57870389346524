import {
    ApolloClient,
    ApolloLink,
    from,
    InMemoryCache,
    NormalizedCacheObject
} from '@apollo/client'
import possibleTypes from '../possibleTypes.json'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { setContext } from 'apollo-link-context'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

function createNewApolloClient() {
    const httpLink = createUploadLink({
        uri: process.env.REACT_APP_PUBLIC_GRAPHQL_URL
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            )
        if (networkError) console.log(`[Network error]: ${networkError}`)
    })

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('token')
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `${token}` : ''
            }
        }
    })

    const link = from([
        errorLink,
        authLink as unknown as ApolloLink,
        httpLink as unknown as ApolloLink
    ])

    return new ApolloClient({
        link,
        cache: new InMemoryCache({ possibleTypes: possibleTypes?.possibleTypes }).restore({})
    })
}

export default function initApollo() {
    if (!apolloClient) {
        apolloClient = createNewApolloClient()
    }

    return apolloClient
}
