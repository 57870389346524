import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsSchoolYearsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SchoolsSchoolYearsQuery = { __typename?: 'Query', schoolsSchoolYears?: { __typename?: 'schoolsSchoolYearPaginator', data: Array<{ __typename?: 'schoolsSchoolYear', name: string, id: number }> } | null | undefined };


export const SchoolsSchoolYearsDocument = gql`
    query SchoolsSchoolYears {
  schoolsSchoolYears {
    data {
      name
      id
    }
  }
}
    `;

/**
 * __useSchoolsSchoolYearsQuery__
 *
 * To run a query within a React component, call `useSchoolsSchoolYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsSchoolYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsSchoolYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolsSchoolYearsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsSchoolYearsQuery, SchoolsSchoolYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsSchoolYearsQuery, SchoolsSchoolYearsQueryVariables>(SchoolsSchoolYearsDocument, options);
      }
export function useSchoolsSchoolYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsSchoolYearsQuery, SchoolsSchoolYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsSchoolYearsQuery, SchoolsSchoolYearsQueryVariables>(SchoolsSchoolYearsDocument, options);
        }
export type SchoolsSchoolYearsQueryHookResult = ReturnType<typeof useSchoolsSchoolYearsQuery>;
export type SchoolsSchoolYearsLazyQueryHookResult = ReturnType<typeof useSchoolsSchoolYearsLazyQuery>;
export type SchoolsSchoolYearsQueryResult = Apollo.QueryResult<SchoolsSchoolYearsQuery, SchoolsSchoolYearsQueryVariables>;