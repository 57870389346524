import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DuplicatePackageMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  public_name: Types.Scalars['String'];
  ajat_id: Types.Scalars['String'];
}>;


export type DuplicatePackageMutation = { __typename?: 'Mutation', duplicatePackage?: { __typename?: 'packagesPackage', active: boolean, base_price?: number | null | undefined, created_at: any, definition: string, final_price: number, id: string, public_name: string, updated_at: any, type: string, short_description?: string | null | undefined } | null | undefined };


export const DuplicatePackageDocument = gql`
    mutation DuplicatePackage($id: ID!, $public_name: String!, $ajat_id: String!) {
  duplicatePackage: packagesDuplicatePackage(
    id: $id
    public_name: $public_name
    ajat_id: $ajat_id
  ) {
    active
    base_price
    created_at
    definition
    final_price
    id
    public_name
    updated_at
    type
    short_description
  }
}
    `;
export type DuplicatePackageMutationFn = Apollo.MutationFunction<DuplicatePackageMutation, DuplicatePackageMutationVariables>;

/**
 * __useDuplicatePackageMutation__
 *
 * To run a mutation, you first call `useDuplicatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePackageMutation, { data, loading, error }] = useDuplicatePackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      public_name: // value for 'public_name'
 *      ajat_id: // value for 'ajat_id'
 *   },
 * });
 */
export function useDuplicatePackageMutation(baseOptions?: Apollo.MutationHookOptions<DuplicatePackageMutation, DuplicatePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicatePackageMutation, DuplicatePackageMutationVariables>(DuplicatePackageDocument, options);
      }
export type DuplicatePackageMutationHookResult = ReturnType<typeof useDuplicatePackageMutation>;
export type DuplicatePackageMutationResult = Apollo.MutationResult<DuplicatePackageMutation>;
export type DuplicatePackageMutationOptions = Apollo.BaseMutationOptions<DuplicatePackageMutation, DuplicatePackageMutationVariables>;