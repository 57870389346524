import QueryStatsIcon from '@mui/icons-material/QueryStats'
import PersonalizationRuleList from './PersonalizationRuleList'
import PersonalizationRuleEdit from './PersonalizationRuleEdit'
import PersonalizationRuleCreate from './PersonalizationRuleCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: PersonalizationRuleList,
    edit: PersonalizationRuleEdit,
    create: PersonalizationRuleCreate,
    icon: QueryStatsIcon
}
