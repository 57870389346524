import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import AddIcon from '@material-ui/icons/Add'
import { IChangeEvent } from '@rjsf/core/dist'
import Form from '@rjsf/material-ui/dist'
import React, { useContext, useState } from 'react'
import { ModelContext } from 'context/model/ModelContext'
import { ErrorContext } from 'context/error/ErrorContext'
import { getSchemaByNode } from 'utils/Schema'
import { useCreateNodeMutation } from 'apollo/configurator/mutations/CreateNode.generated'
import { useModelSchemaQuery } from 'apollo/configurator/queries/ModelSchema.generated'
import { useRootNodesQuery } from 'apollo/configurator/queries/RootNodes.generated'
import { RJSFSchema } from '@rjsf/utils/dist'
import validator from '@rjsf/validator-ajv8/dist'

const useStyles = makeStyles(() => ({
    gallery: {
        display: 'grid'
    }
}))

const NewModelButton = () => {
    const { state, setState } = useContext(ModelContext)
    const { setErrorState } = useContext(ErrorContext)
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const { data: { configuratorModelSchema: modelSchema } = {} } = useModelSchemaQuery()

    const modelSchemaObject = modelSchema && JSON.parse(modelSchema)
    const node = { body: '{}', node_attributes: '{}', parent_id: null }
    const nodeSchema =
        modelSchemaObject && node && getSchemaByNode(modelSchemaObject, '', false, true)
    const [createNode] = useCreateNodeMutation({ variables: { node_attributes: '' } })
    const def = { version: '1.0.0' }
    const [formData, setFormData] = useState<unknown>(def)
    const uiSchema = {
        version: { 'ui:readonly': true }
    }
    const { data: { configuratorNodes: nodes } = {} } = useRootNodesQuery()

    const modelsCodes = nodes && nodes.map((node) => node?.code)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    if (nodeSchema && nodeSchema.properties) {
        delete nodeSchema.properties['labelling']
        delete nodeSchema.properties['3d_model']
        delete nodeSchema.properties['camera']

        delete nodeSchema.properties['constraints']
        delete nodeSchema.properties['upsell']
        delete nodeSchema.properties['valueGroups']
    }

    const OnSaveHandler = (e: IChangeEvent<any, RJSFSchema, any>) => {
        const formDataEvt: any = e.formData
        const code = formDataEvt['code']
        if (modelsCodes?.includes(code)) {
            setErrorState({ hasError: true, message: `Code ${code} already exists` })
            return
        }

        createNode({
            variables: {
                node_attributes: JSON.stringify(formDataEvt)
            }
        })
            .then(async (res) => {
                if (!res) {
                    return
                }
                const createNode = res?.data?.configuratorCreateNode
                setState({ ...state, currentModel: createNode?.id || '' })
                setFormData(def)
                setOpen(false)
            })
            .then(() => {
                window.location.reload()
            })
            .catch((error) => {
                setErrorState({ hasError: true, message: error.message })
            })
    }

    const OnChangeHandler = (e: IChangeEvent<any, RJSFSchema, any>) => {
        setFormData(e.formData)
    }

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                <AddIcon />
                Add Model
            </Button>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{'Create New Model'}</DialogTitle>
                <DialogContent>
                    <div className={classes.gallery}>
                        {nodeSchema && (
                            <>
                                <Form
                                    showErrorList={false}
                                    schema={nodeSchema}
                                    uiSchema={uiSchema}
                                    formData={formData}
                                    onSubmit={(e) => OnSaveHandler(e)}
                                    onChange={(e) => OnChangeHandler(e)}
                                    onError={(a) => console.log('errors', a)}
                                    validator={validator}
                                />
                            </>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewModelButton
