import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteClassRepresentativeMutationVariables = Types.Exact<{
  representativeId: Types.Scalars['Int'];
  schoolClassId: Types.Scalars['Int'];
}>;


export type DeleteClassRepresentativeMutation = { __typename?: 'Mutation', schoolsDeleteSchoolClassSchoolClassRepresentative?: { __typename?: 'schoolsSchoolClassSchoolClassRepresentative', id: string, representative_id: number, school_class_id: number } | null | undefined };


export const DeleteClassRepresentativeDocument = gql`
    mutation DeleteClassRepresentative($representativeId: Int!, $schoolClassId: Int!) {
  schoolsDeleteSchoolClassSchoolClassRepresentative(
    representative_id: $representativeId
    school_class_id: $schoolClassId
  ) {
    id
    representative_id
    school_class_id
  }
}
    `;
export type DeleteClassRepresentativeMutationFn = Apollo.MutationFunction<DeleteClassRepresentativeMutation, DeleteClassRepresentativeMutationVariables>;

/**
 * __useDeleteClassRepresentativeMutation__
 *
 * To run a mutation, you first call `useDeleteClassRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassRepresentativeMutation, { data, loading, error }] = useDeleteClassRepresentativeMutation({
 *   variables: {
 *      representativeId: // value for 'representativeId'
 *      schoolClassId: // value for 'schoolClassId'
 *   },
 * });
 */
export function useDeleteClassRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassRepresentativeMutation, DeleteClassRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassRepresentativeMutation, DeleteClassRepresentativeMutationVariables>(DeleteClassRepresentativeDocument, options);
      }
export type DeleteClassRepresentativeMutationHookResult = ReturnType<typeof useDeleteClassRepresentativeMutation>;
export type DeleteClassRepresentativeMutationResult = Apollo.MutationResult<DeleteClassRepresentativeMutation>;
export type DeleteClassRepresentativeMutationOptions = Apollo.BaseMutationOptions<DeleteClassRepresentativeMutation, DeleteClassRepresentativeMutationVariables>;