import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DuplicateRuleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DuplicateRuleMutation = { __typename?: 'Mutation', duplicateRule: { __typename?: 'personalizationRule', id: string, active: boolean, label: string, rules: string, actions: string, break?: boolean | null | undefined, priority?: number | null | undefined, created_at: any, updated_at: any } };


export const DuplicateRuleDocument = gql`
    mutation DuplicateRule($id: ID!) {
  duplicateRule: personalizationDuplicateRule(id: $id) {
    id
    active
    label
    rules
    actions
    break
    priority
    created_at
    updated_at
  }
}
    `;
export type DuplicateRuleMutationFn = Apollo.MutationFunction<DuplicateRuleMutation, DuplicateRuleMutationVariables>;

/**
 * __useDuplicateRuleMutation__
 *
 * To run a mutation, you first call `useDuplicateRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRuleMutation, { data, loading, error }] = useDuplicateRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateRuleMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateRuleMutation, DuplicateRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateRuleMutation, DuplicateRuleMutationVariables>(DuplicateRuleDocument, options);
      }
export type DuplicateRuleMutationHookResult = ReturnType<typeof useDuplicateRuleMutation>;
export type DuplicateRuleMutationResult = Apollo.MutationResult<DuplicateRuleMutation>;
export type DuplicateRuleMutationOptions = Apollo.BaseMutationOptions<DuplicateRuleMutation, DuplicateRuleMutationVariables>;