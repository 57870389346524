import { DialogContent, Snackbar } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AddIcon from '@material-ui/icons/Add'
import { Alert } from '@material-ui/lab'
import { useImportModelMutation } from 'apollo/configurator/mutations/ImportModel.generated'
import { useRootNodesQuery } from 'apollo/configurator/queries/RootNodes.generated'
import {
    executeImport,
    handleImportModel
} from 'components/modelManager/molecules/manager/helpers/ImportExportModel'
import { Loading } from 'ra-ui-materialui'
import { useState } from 'react'
import MiniPresetList from './helpers/MiniPresetList'

const ImportModelButton = () => {
    const [duplicateCode, setDuplicateCode] = useState(false)

    const [preImportModel, setPreImportModel] = useState<any | null>(null)

    const [importModelMutation, { loading }] = useImportModelMutation({
        variables: {
            json: '' // value for 'json'
        },
        fetchPolicy: 'network-only'
    })
    const { data: { configuratorNodes: nodes } = {} } = useRootNodesQuery()

    const modelsCodes = nodes && nodes.map((node) => node?.code)

    return (
        <>
            <Button
                style={{ marginLeft: '10px' }}
                variant="contained"
                color="primary"
                onClick={() => handleImportModel(modelsCodes, setDuplicateCode, setPreImportModel)}
            >
                <AddIcon />
                Import model
            </Button>
            {/* Import spinner */}
            <Dialog
                open={loading || preImportModel !== null}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent
                    style={{
                        textAlign: 'center',
                        display: preImportModel == null ? 'block' : 'none'
                    }}
                >
                    <Loading
                        loadingPrimary="Import in progress"
                        loadingSecondary="Do not refresh or close the page"
                    />
                </DialogContent>
                <DialogContent
                    style={{
                        textAlign: 'left',
                        display: preImportModel == null ? 'none' : 'block',
                        padding: '40px 40px 20px 40px'
                    }}
                >
                    <MiniPresetList
                        presets={preImportModel?.presets}
                        model={preImportModel?.model}
                    ></MiniPresetList>
                    <br />
                    <Button
                        style={{ margin: '10px' }}
                        variant="contained"
                        color="secondary"
                        onClick={() => setPreImportModel(null)}
                    >
                        Cancel
                    </Button>

                    <Button
                        style={{ margin: '10px' }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            executeImport(JSON.stringify(preImportModel), importModelMutation)
                            setPreImportModel(null)
                        }}
                    >
                        Confirm import
                    </Button>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={duplicateCode}
                onClose={() => setDuplicateCode(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                key={Date.now()}
            >
                <Alert onClose={() => setDuplicateCode(false)} severity="error">
                    There is a model with the same Model Code!
                </Alert>
            </Snackbar>
        </>
    )
}

export default ImportModelButton
