import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolSalesTeamsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SchoolSalesTeamsQuery = { __typename?: 'Query', schoolsSchoolSalesTeams?: { __typename?: 'schoolsSchoolSalesTeamPaginator', data: Array<{ __typename?: 'schoolsSchoolSalesTeam', id: string, name: string, active: boolean }> } | null | undefined };


export const SchoolSalesTeamsDocument = gql`
    query SchoolSalesTeams {
  schoolsSchoolSalesTeams {
    data {
      id
      name
      active
    }
  }
}
    `;

/**
 * __useSchoolSalesTeamsQuery__
 *
 * To run a query within a React component, call `useSchoolSalesTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolSalesTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolSalesTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolSalesTeamsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolSalesTeamsQuery, SchoolSalesTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolSalesTeamsQuery, SchoolSalesTeamsQueryVariables>(SchoolSalesTeamsDocument, options);
      }
export function useSchoolSalesTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolSalesTeamsQuery, SchoolSalesTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolSalesTeamsQuery, SchoolSalesTeamsQueryVariables>(SchoolSalesTeamsDocument, options);
        }
export type SchoolSalesTeamsQueryHookResult = ReturnType<typeof useSchoolSalesTeamsQuery>;
export type SchoolSalesTeamsLazyQueryHookResult = ReturnType<typeof useSchoolSalesTeamsLazyQuery>;
export type SchoolSalesTeamsQueryResult = Apollo.QueryResult<SchoolSalesTeamsQuery, SchoolSalesTeamsQueryVariables>;