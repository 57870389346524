import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConfiguratorModelTreeStructureQueryVariables = Types.Exact<{
  code: Types.Scalars['ID'];
}>;


export type ConfiguratorModelTreeStructureQuery = { __typename?: 'Query', configuratorModelTreeStructure?: string | null | undefined };


export const ConfiguratorModelTreeStructureDocument = gql`
    query ConfiguratorModelTreeStructure($code: ID!) {
  configuratorModelTreeStructure(code: $code)
}
    `;

/**
 * __useConfiguratorModelTreeStructureQuery__
 *
 * To run a query within a React component, call `useConfiguratorModelTreeStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguratorModelTreeStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguratorModelTreeStructureQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfiguratorModelTreeStructureQuery(baseOptions: Apollo.QueryHookOptions<ConfiguratorModelTreeStructureQuery, ConfiguratorModelTreeStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfiguratorModelTreeStructureQuery, ConfiguratorModelTreeStructureQueryVariables>(ConfiguratorModelTreeStructureDocument, options);
      }
export function useConfiguratorModelTreeStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfiguratorModelTreeStructureQuery, ConfiguratorModelTreeStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfiguratorModelTreeStructureQuery, ConfiguratorModelTreeStructureQueryVariables>(ConfiguratorModelTreeStructureDocument, options);
        }
export type ConfiguratorModelTreeStructureQueryHookResult = ReturnType<typeof useConfiguratorModelTreeStructureQuery>;
export type ConfiguratorModelTreeStructureLazyQueryHookResult = ReturnType<typeof useConfiguratorModelTreeStructureLazyQuery>;
export type ConfiguratorModelTreeStructureQueryResult = Apollo.QueryResult<ConfiguratorModelTreeStructureQuery, ConfiguratorModelTreeStructureQueryVariables>;