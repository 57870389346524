import { ReactNode } from 'react'

// MUI
import { Button, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslate } from 'react-admin'

interface IButton {
    onClick: () => void
    key: ReactNode | null
    className?: string
    style?: any
    visible?: boolean
}

const AddButton = ({ onClick, className }: IButton) => {
    return (
        <Button onClick={onClick} aria-label="Add" className={className}>
            <AddIcon />
        </Button>
    )
}

const RemoveButton = ({ onClick, className }: IButton) => {
    const translate = useTranslate()
    return (
        <Tooltip
            arrow
            placement="left"
            title={translate('manager.resources.preset.manager.delete_preset_node')}
        >
            <Button onClick={onClick} aria-label="Remove" className={className}>
                <DeleteIcon />
            </Button>
        </Tooltip>
    )
}

export { AddButton, RemoveButton }
