import React, { createContext, useState } from 'react'
import { TreeItem } from 'react-sortable-tree'
import { INodeWithPreset } from 'ts/interfaces'

export type PresetModelInitialStateProps = {
    hasChanges: boolean
    hasEdited: boolean
    currentModel: string
    currentModelCode: string
    updatedModel: boolean
    showEditedMessage: boolean
    treeState: TreeItem[]
    selectedNode: INodeWithPreset
    reRenderForm: boolean
    reRenderTree: boolean
}

const presetModelInitialState = {
    state: {
        hasChanges: false,
        hasEdited: false,
        currentModel: '',
        currentModelCode: '',
        updatedModel: false,
        showEditedMessage: false,
        treeState: [] as TreeItem[],
        selectedNode: {} as INodeWithPreset,
        reRenderForm: false,
        reRenderTree: false
    },
    setState: (_state: PresetModelInitialStateProps) => {}
}

const PresetModelContext = createContext(presetModelInitialState)
const PresetModelProvider: React.FC = ({ children }): React.ReactElement => {
    const [state, setState] = useState(presetModelInitialState.state)

    return (
        <PresetModelContext.Provider value={{ state, setState }}>
            {children}
        </PresetModelContext.Provider>
    )
}

export { PresetModelProvider, PresetModelContext, presetModelInitialState }
