import { Datagrid, FunctionField, TextField, useTranslate, ArrayField } from 'react-admin'
import { Record } from 'ra-core'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import datagridStyles from '../../layout/styles/datagridStyles'
import { Typography } from '@material-ui/core'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'

const useStyles = makeStyles({
    chip: { margin: '2px' }
})

const SchoolClassRepresentativeListAsArrayField = (props) => {
    const translate = useTranslate()
    const classes = useStyles()
    const datagridClasses = datagridStyles()

    return (
        <ListWrapper>
            <Typography className="title" variant="h6" gutterBottom>
                {translate('manager.resources.schoolClass.representatives')}
            </Typography>
            {props?.record?.representatives?.length > 0 ? (
                <ArrayField source="representatives">
                    <Datagrid
                        className="table"
                        classes={datagridClasses}
                        rowClick={(_, __, record) => `/schoolClassRepresentative/${record.id}`}
                    >
                        <TextField
                            source="name"
                            label={translate('manager.resources.contactPersons.name')}
                        />
                        <TextField
                            source="email"
                            label={translate('manager.resources.contactPersons.email')}
                        />
                        <TextField
                            source="ssn"
                            label={translate('manager.resources.contactPersons.ssn')}
                        />
                        <TextField
                            source="telephone"
                            label={translate('manager.resources.contactPersons.phone')}
                        />
                        <FunctionField
                            label={translate('manager.resources.contactPersons.schools')}
                            render={(record?: Record | undefined) => {
                                return record?.schools && record.schools.length > 0 ? (
                                    record.schools.map((school) => (
                                        <Chip
                                            size="small"
                                            label={school.name}
                                            className={classes.chip}
                                        />
                                    ))
                                ) : (
                                    <> — </>
                                )
                            }}
                        />
                        <FunctionField
                            label={translate('manager.resources.contactPersons.classes')}
                            render={(record?: Record | undefined) => {
                                return record?.classes && record.classes.length > 0 ? (
                                    record.classes.map((_classes) => (
                                        <Chip
                                            size="small"
                                            label={_classes.name}
                                            className={classes.chip}
                                        />
                                    ))
                                ) : (
                                    <> — </>
                                )
                            }}
                        />
                        <TextField
                            source="discount_amount"
                            label={translate('manager.resources.contactPersons.discount_amount')}
                        />
                        <TextField
                            source="discount_percentage"
                            label={translate(
                                'manager.resources.contactPersons.discount_percentage'
                            )}
                        />
                        <TextField
                            source="discount_used"
                            label={translate(
                                'manager.resources.school.representative_discount_used'
                            )}
                        />

                        <DateFieldCustom
                            label={translate('manager.resources.general.last_updated')}
                            source="updated_at"
                            showTime
                            transform={(value) => formatDateString(value, true)}
                        />
                    </Datagrid>
                </ArrayField>
            ) : (
                <Typography variant="body1" gutterBottom>
                    {translate('manager.resources.schoolClass.no_contacts')}
                </Typography>
            )}
        </ListWrapper>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    .title {
        width: 100%;
    }
    & .MuiToolbar-root {
        > form {
            flex-wrap: initial;
        }
        > div.MuiToolbar-root {
            width: auto;
        }
    }
`

export default SchoolClassRepresentativeListAsArrayField
