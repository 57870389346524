import {
    addNodeUnderParent,
    ExtendedNodeData,
    getNodeAtPath,
    removeNodeAtPath,
    TreeItem
} from 'react-sortable-tree'
import { IFsNode, INode } from 'ts/interfaces'

/**
 * Fragment to prevent duplicate code for formatTreeState
 * @param {INode} node The tree node that needs to be formatted.
 * @param {boolean} expanded Expand the node and it's children.
 */
const formatTreeSingleNode = (node: INode, expanded?: boolean) => {
    const attributes =
        typeof node?.node_attributes === 'string'
            ? JSON.parse(node.node_attributes)
            : node.node_attributes

    return {
        id: node.id,
        parent_id: node.parent_id,
        node_attributes: attributes,
        title: attributes?.code,
        expanded: expanded
    }
}

/**
 * @param {INode} node The tree node that needs to be formatted.
 * @param {boolean} expanded Expand the node and it's children.
 */
export const formatTreeState = (node: INode, expanded?: boolean) => {
    // TODO: use _.cloneDeepWith

    return {
        ...formatTreeSingleNode(node, expanded),
        children: node?.children?.map((child: INode) => ({
            ...formatTreeSingleNode(child, expanded),
            children: child?.children?.map((subChild: INode) => ({
                ...formatTreeSingleNode(subChild, expanded),
                children: subChild?.children?.map((subSubChild: INode) => ({
                    ...formatTreeSingleNode(subSubChild, expanded),
                    children: subSubChild?.children?.map((subSubSubChild: INode) => ({
                        ...formatTreeSingleNode(subSubSubChild, expanded),
                        children: subSubSubChild?.children?.map((subSubSubSubChild: INode) => ({
                            ...formatTreeSingleNode(subSubSubSubChild, expanded)
                        }))
                    }))
                }))
            }))
        }))
    }
}

/**
 * @param {IFsNode} node The tree node that needs to be formatted.
 * @param {boolean} expanded Expand the node and it's children.
 */

export const formatFSTreeState = (node: IFsNode, expanded?: boolean) => ({
    title: node?.title,
    isDirectory: node?.isDirectory,
    id: node.id,
    expanded: expanded,
    children: node?.children?.map((child: IFsNode) => ({
        title: child.title,
        isDirectory: child.isDirectory,
        id: child.id,
        expanded: expanded,
        children: child?.children?.map((subchild: IFsNode) => ({
            title: subchild.title,
            isDirectory: subchild.isDirectory,
            id: node.id,
            children: subchild?.children,
            expanded: expanded
        }))
    }))
})

/**
 * @param {TreeItem[]} tree The tree node that needs to be updated.
 * @param {number | string} id The id to be found inside the tree, used to update the node.
 * @param {INode | IFsNode} nodeData The node you want to be updated
 */
export const updateTree = (tree: TreeItem[], id: number | string, nodeData: INode | IFsNode) => {
    if (nodeData?.children?.length) {
        return tree?.some(function iter(o) {
            if (o.id === id) {
                if ('node_attributes' in nodeData) {
                    o.children = nodeData?.children?.map((child: INode) => formatTreeState(child))
                    return true
                } else {
                    o.children = nodeData?.children
                    return true
                }
            }
            return Array.isArray(o.children) && o.children?.some(iter)
        })
    }
}

/**
 * @param {TreeItem[]} treeState The tree node that needs to be updated.
 * @param {INode | IFsNode} newNode The node you want to be updated
 * @param {ExtendedNodeData} extendedNode The node data provided by react sortable tree
 * @param {number | null} parentId The id of the node it's parent.
 */

export const addNode = (
    treeState: TreeItem[],
    newNode: INode | IFsNode,
    extendedNode: ExtendedNodeData,
    parentId: number | null
) => {
    const { path } = extendedNode
    path.pop()

    if (treeState) {
        // Check if there is a parentNode available in the model tree
        const parentNode = getNodeAtPath({
            treeData: treeState,
            path: path,
            getNodeKey: ({ node }) => node.id,
            ignoreCollapsed: false
        })

        if (parentNode) {
            let parentKey = parentId || undefined
            if (parentKey === -1) {
                parentKey = undefined
            }

            // Create a new tree by adding a node under the found parent.
            const newTree = addNodeUnderParent({
                treeData: treeState,
                newNode: {
                    ...newNode,
                    node_attributes:
                        'node_attributes' in newNode ? JSON.parse(newNode?.node_attributes) : {},
                    title:
                        'node_attributes' in newNode
                            ? JSON.parse(newNode?.node_attributes).code
                            : newNode.title
                },
                expandParent: true,
                parentKey: parentKey?.toString(),
                getNodeKey: ({ node }) => node.id,
                ignoreCollapsed: false
            })

            return newTree
        }
    }
}

/**
 * @param {TreeItem[]} treeState The tree node that needs to be updated.
 * @param {(data: TreeItem[]) => void} setTreeState The functionality to set the new tree
 * @param {ExtendedNodeData} extendedNode The node data provided by react sortable tree
 * @param {() => void} handleUndefined The callback for when the node is not defined
 */

export const deleteNodeInTree = (
    treeState: TreeItem[],
    setTreeState: (data: TreeItem[]) => void,
    extendedNode: ExtendedNodeData,
    handleUndefined?: () => void
) => {
    const { path } = extendedNode

    const newTree = {
        treeData: removeNodeAtPath({
            treeData: treeState,
            path: path, // You can use path from here
            getNodeKey: ({ node }) => node.id,
            ignoreCollapsed: false
        })
    }
    if (!newTree.treeData) {
        setTreeState([])

        // If the rootnode is removed we need to handle an undefined response.
        handleUndefined && handleUndefined()
    } else {
        setTreeState(newTree.treeData)
    }
}
