import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateNodeMutationVariables = Types.Exact<{
  parent_id?: Types.InputMaybe<Types.Scalars['Int']>;
  node_attributes: Types.Scalars['String'];
}>;


export type CreateNodeMutation = { __typename?: 'Mutation', configuratorCreateNode?: { __typename?: 'configuratorNode', parent_id?: number | null | undefined, node_attributes?: string | null | undefined, id: string } | null | undefined };


export const CreateNodeDocument = gql`
    mutation CreateNode($parent_id: Int, $node_attributes: String!) {
  configuratorCreateNode(parent_id: $parent_id, node_attributes: $node_attributes) {
    parent_id
    node_attributes
    id
  }
}
    `;
export type CreateNodeMutationFn = Apollo.MutationFunction<CreateNodeMutation, CreateNodeMutationVariables>;

/**
 * __useCreateNodeMutation__
 *
 * To run a mutation, you first call `useCreateNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNodeMutation, { data, loading, error }] = useCreateNodeMutation({
 *   variables: {
 *      parent_id: // value for 'parent_id'
 *      node_attributes: // value for 'node_attributes'
 *   },
 * });
 */
export function useCreateNodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateNodeMutation, CreateNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNodeMutation, CreateNodeMutationVariables>(CreateNodeDocument, options);
      }
export type CreateNodeMutationHookResult = ReturnType<typeof useCreateNodeMutation>;
export type CreateNodeMutationResult = Apollo.MutationResult<CreateNodeMutation>;
export type CreateNodeMutationOptions = Apollo.BaseMutationOptions<CreateNodeMutation, CreateNodeMutationVariables>;