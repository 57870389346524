import 'react-awesome-query-builder/lib/css/styles.css'
import { useEffect, useState } from 'react'
import {
    Builder,
    BuilderProps,
    Config,
    ImmutableTree,
    JsonLogicTree,
    Query,
    Utils as QbUtils
} from 'react-awesome-query-builder'
import MaterialConfig from 'react-awesome-query-builder/lib/config/material'
import _ from 'lodash'
import { generateRandomString } from 'utils/generateRandomString'
import axios from 'axios'

interface PersonalizationRuleQueryBuilderProps {
    rules: string
    alwaysApply: boolean
    builderConfig: any
    setRulesCallBack: (rules: string) => void
}

const PersonalizationRuleQueryBuilder = ({
    rules,
    alwaysApply,
    builderConfig,
    setRulesCallBack
}: PersonalizationRuleQueryBuilderProps) => {
    const [uniqueKey, setUniqueKey] = useState('')
    const jsonRules = JSON.parse(rules)
    const logic: JsonLogicTree | undefined =
        jsonRules && Object.keys(jsonRules).length > 0 ? (jsonRules as JsonLogicTree) : undefined
    const fields =
        builderConfig?.personalizationRuleBuilderConfig &&
        JSON.parse(builderConfig.personalizationRuleBuilderConfig, (key, value) => {
            if (key === 'fieldSettings') {
                if (value?.asyncFetch) {
                    const query = value.asyncFetch.url
                    const mapping = value.asyncFetch.mapping
                    value.asyncFetch = async (search, offset) => {
                        const toSearch = _.isEmpty(search) ? '' : search
                        const response = await axios.get(`${query}${toSearch}`)
                        const values = response.data.map((val) => ({
                            title: val[mapping.title],
                            value: val[mapping.value].toString()
                        }))
                        let hasMore = false
                        return {
                            values,
                            hasMore
                        }
                    }
                }
            }
            return value
        })
    const initialConfig = {
        ...MaterialConfig,
        fields: fields
    }

    // Set state
    const [config, setConfig] = useState<Config>(initialConfig)
    const [tree, setTree] = useState<ImmutableTree>(
        QbUtils.checkTree(QbUtils.loadFromJsonLogic(logic, config), config)
    )

    // Update state and thus form field "rules"
    useEffect(() => {
        const rules = !!tree && JSON.stringify(QbUtils.jsonLogicFormat(tree, config).logic)
        if (rules) setRulesCallBack(rules)
        setUniqueKey(generateRandomString())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tree, config])

    // Update tree and config state based on changes made in the querybuilder
    const onChange = (immutableTree: ImmutableTree, config: Config) => {
        setTree(immutableTree)
        setConfig(config)
    }

    if (alwaysApply) return <></>
    return (
        <Query
            {...config}
            key={uniqueKey}
            value={tree}
            onChange={onChange}
            renderBuilder={(props: BuilderProps) => <Builder {...props} />}
        />
    )
}
export default PersonalizationRuleQueryBuilder
