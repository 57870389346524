import {
    useRedirect,
    Edit,
    SimpleForm,
    useTranslate,
    TextInput,
    Loading,
    required
} from 'react-admin'

import { usePackagesPackageGalleryItemQuery } from 'apollo/package_manager/queries/PackageGalleryItem.generated'

import { Typography } from '@material-ui/core'

import UploadField from 'components/formfields/uploadField/UploadField'
import PackageGalleryItemEditToolbar from './PackageGalleryItemEditToolbar'

const PackageGalleryItemEdit = (props) => {
    const RESOURCE = 'PackageGalleryItem'
    const id = props?.match?.params?.id ?? ''
    const translate = useTranslate()
    const redirect = useRedirect()
    const onSuccess = (response: any) => {
        redirect('edit', `/Package/${response.data?.package_id}/images`, '')
    }

    const record = usePackagesPackageGalleryItemQuery({
        variables: {
            packagesPackageGalleryItemId: id
        }
    })

    if (!record.data?.packagesPackageGalleryItem) return <Loading />
    return (
        <Edit
            {...props}
            undoable={false}
            resource={RESOURCE}
            onSuccess={onSuccess}
            basePath={props.basepath}
            title={translate(`manager.resources.${RESOURCE}.edit`)}
        >
            <SimpleForm
                initialValues={{ id: props?.location?.state?.id }}
                toolbar={<PackageGalleryItemEditToolbar resource={RESOURCE} />}
            >
                <Typography variant="h6" gutterBottom>
                    {translate(`manager.resources.${RESOURCE}.edit`)}
                </Typography>
                <TextInput
                    source="title"
                    variant="outlined"
                    label={translate(`manager.resources.${RESOURCE}.title`)}
                    validate={requiredValidate}
                />
                <TextInput
                    source="alt"
                    variant="outlined"
                    label={translate(`manager.resources.${RESOURCE}.alt`)}
                    validate={requiredValidate}
                />
                <UploadField
                    source="path"
                    label={translate(`manager.resources.${RESOURCE}.path`)}
                    data={record.data?.packagesPackageGalleryItem?.path}
                    url={record.data?.packagesPackageGalleryItem?.path ?? ''}
                    validateRequired
                />
            </SimpleForm>
        </Edit>
    )
}

const requiredValidate = [required()]

export default PackageGalleryItemEdit
