import { useContext, useState } from 'react'
import { useTranslate } from 'react-admin'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@material-ui/core'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import Fab from '@material-ui/core/Fab'
import PublishIcon from '@mui/icons-material/Publish'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { useBatchExportModelPresetsMutation } from 'apollo/configurator/mutations/BatchExportModelPresets.generated'
import WarningDialog from 'components/modelManager/molecules/popups/WarningDialog'
import { ErrorContext } from 'context/error/ErrorContext'

interface IUpdateAffectedPresetsButton {
    id: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 25
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative'
        },
        fabProgress: {
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1
        }
    })
)

const UpdateAffectedPresetsButton = ({ id }: IUpdateAffectedPresetsButton) => {
    const { setErrorState } = useContext(ErrorContext)

    const [isActiveWarningPopup, setIsActiveWarningPopup] = useState(false)
    const [isActiveResultsPopup, setIsActiveResultsPopup] = useState(false)

    const translate = useTranslate()
    const classes = useStyles()

    const [batchExportModelPresetsResponseData, { data, loading, error }] =
        useBatchExportModelPresetsMutation({
            variables: { model: id as any }
        })

    const handleFadButtonClick = () => {
        setIsActiveWarningPopup(true)
    }

    const handleCloseWarningPopup = () => {
        setIsActiveWarningPopup(false)
    }

    const handleCloseResultsPopup = () => {
        setIsActiveResultsPopup(false)
    }

    const handleContinueWarningPopup = () => {
        batchExportModelPresetsResponseData()
            .then((data) => {
                if (data) {
                    setIsActiveResultsPopup(true)
                }
                if (error) setErrorState({ hasError: true, message: error.message })
            })
            .catch((error) => {
                setErrorState({ hasError: true, message: error.message })
                console.error(error)
            })
        setIsActiveWarningPopup(false)
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="update affected presets"
                        color="secondary"
                        onClick={handleFadButtonClick}
                    >
                        <PublishIcon />
                    </Fab>
                    {loading && (
                        <CircularProgress
                            color="secondary"
                            size={68}
                            className={classes.fabProgress}
                        />
                    )}
                </div>
            </div>

            <WarningDialog
                open={isActiveWarningPopup}
                handleClose={handleCloseWarningPopup}
                handleContinue={handleContinueWarningPopup}
                title={translate(
                    'manager.resources.model_manager.update_affected_presets_dialogs.warning_dialog.title'
                )}
                content={translate(
                    'manager.resources.model_manager.update_affected_presets_dialogs.warning_dialog.content'
                )}
            />

            <Dialog
                open={isActiveResultsPopup}
                keepMounted
                onClose={handleCloseResultsPopup}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {translate(
                        'manager.resources.model_manager.update_affected_presets_dialogs.results_dialog.title'
                    )}
                </DialogTitle>
                <DialogContent>
                    {data?.configuratorBatchExportModelPresets.length ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="Affected presets table">
                                <TableBody>
                                    {data?.configuratorBatchExportModelPresets.map((item) => (
                                        <TableRow
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 }
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {item?.success ? '✅' : null}
                                                {item?.error ? '❌' : null}
                                                &nbsp;
                                                {item?.internal_name}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        translate(
                            'manager.resources.model_manager.update_affected_presets_dialogs.results_dialog.content_not_found'
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseResultsPopup} variant="contained" color="primary">
                        {translate(
                            'manager.resources.model_manager.update_affected_presets_dialogs.results_dialog.close'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UpdateAffectedPresetsButton
