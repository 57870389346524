import { useState } from 'react'
import { Edit, useTranslate } from 'react-admin'

import { BackFromEditorButton } from 'components/common/BackFromEditorButton'

import SchoolClassRepresentativeForm from './SchoolClassRepresentativeForm'

const SchoolClassRepresentativeEdit = (props) => {
    const translate = useTranslate()
    const [isPristineForm, setIsPristineForm] = useState(true)

    return (
        <>
            <BackFromEditorButton isPristineForm={isPristineForm} />
            <Edit title={translate('manager.resources.contactPersons.edit')} {...props}>
                <SchoolClassRepresentativeForm
                    isEdit
                    isPristineForm={isPristineForm}
                    setIsPristineFormCallback={setIsPristineForm}
                />
            </Edit>
        </>
    )
}

export default SchoolClassRepresentativeEdit
