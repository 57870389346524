import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { amber } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import Input from '@material-ui/core/Input'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import clsx from 'clsx'
import React, { ChangeEvent, useContext } from 'react'
import { ErrorContext } from 'context/error/ErrorContext'
import { useDuplicateModelMutation } from 'apollo/configurator/mutations/DuplicateModel.generated'
import { RootNodesDocument } from 'apollo/configurator/queries/RootNodes.generated'

interface IDuplicateModelButton {
    id: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 25
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative'
        },
        buttonSuccess: {},
        buttonNotExported: {
            backgroundColor: amber[500],
            '&:hover': {
                backgroundColor: amber[700]
            }
        },
        fabProgress: {
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        }
    })
)

const DuplicateModelButton = ({ id }: IDuplicateModelButton) => {
    const { setErrorState } = useContext(ErrorContext)
    const [inputValue, setInputValue] = React.useState('')
    const [inputError, setInputError] = React.useState(true)

    const [duplicateModel, { loading }] = useDuplicateModelMutation({
        onError(error) {
            setErrorState({ hasError: true, message: error.message })
        },
        refetchQueries: [{ query: RootNodesDocument }]
    })
    const classes = useStyles()
    const buttonClassname = clsx({
        [classes.buttonSuccess]: true
    })
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        const pattern = /^[\w]{3,}$/
        if (pattern.test(value)) {
            setInputError(false)
            setInputValue(value)
        } else {
            setInputError(true)
            setInputValue(value)
        }
    }

    const handleConfirm = () => {
        if (inputError) return
        duplicateModel({
            variables: { source: +id as unknown as number, destination: inputValue }
        }).then(() => {
            setInputValue('')
            setInputError(true)
        })
        setOpen(false)
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        onClick={handleClickOpen}
                    >
                        <FileCopyIcon />
                    </Fab>
                    {loading && <CircularProgress size={68} className={classes.fabProgress} />}
                </div>
            </div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {'Please add a code for the new model'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Only letters and underscores allowed.
                        <br />
                        Duplication process can take up to a few minutes depending on the complexity
                        of the model.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-input-wrapper">
                        <Input
                            defaultValue=""
                            value={inputValue}
                            onChange={inputChange}
                            inputProps={{ 'aria-label': 'new model code' }}
                            error={inputError}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="default">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary">
                        Duplicate
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DuplicateModelButton
