import { useState } from 'react'
import { Create, Datagrid, List, ListProps, SimpleForm, TextField, TextInput } from 'react-admin'
import { required, useRefresh, useTranslate } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog } from '@material-ui/core'
import styled from 'styled-components'

import datagridStyles from 'layout/styles/datagridStyles'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import UploadField from 'components/formfields/uploadField/UploadField'
import RelatedResourceToolbar from 'components/common/RelatedResourceToolbar'

const styles = makeStyles({
    list: {
        width: '100%',
        margin: '2rem 0'
    },
    simpleForm: {
        width: 600
    }
})

const PackageGalleryItemList = (props: ListProps) => {
    const RESOURCE = 'PackageGalleryItem'
    const [open, setOpen] = useState(false)
    const reload = useRefresh()
    const classes = styles()
    const translate = useTranslate()
    const datagridClasses = datagridStyles()
    const package_id = props?.filter?.package_id

    const toggleModal = (): void => setOpen(!open)
    const handleSuccess = () => {
        toggleModal()
        reload()
    }

    return (
        <>
            <ListWrapper>
                <List
                    basePath={`/${RESOURCE}`}
                    resource={RESOURCE}
                    actions={
                        <RelatedResourceToolbar
                            resource={RESOURCE}
                            handleCreateCallback={() => toggleModal()}
                        />
                    }
                    empty={false}
                    pagination={false}
                    className={classes.list}
                    bulkActionButtons={false}
                    sort={{ field: 'package_id', order: 'ASC' }}
                    filter={props.filter}
                >
                    <Datagrid
                        body={<CustomDatagridBody />}
                        classes={datagridClasses}
                        rowClick="edit"
                    >
                        <TextField
                            sortable={false}
                            source="title"
                            label={translate(`manager.resources.${RESOURCE}.title`)}
                        />
                        <TextField
                            sortable={false}
                            source="alt"
                            label={translate(`manager.resources.${RESOURCE}.alt`)}
                        />
                        <TextField
                            sortable={false}
                            source="path"
                            label={translate(`manager.resources.${RESOURCE}.path`)}
                        />
                    </Datagrid>
                </List>
            </ListWrapper>

            <Dialog open={open} onClose={() => toggleModal()}>
                <Create resource={RESOURCE} basePath={`/${RESOURCE}`} onSuccess={handleSuccess}>
                    <SimpleForm className={classes.simpleForm} initialValues={{ package_id }}>
                        <TextInput
                            fullWidth
                            source="title"
                            variant="outlined"
                            label={translate(`manager.resources.${RESOURCE}.title`)}
                            validate={requiredValidate}
                        />
                        <TextInput
                            fullWidth
                            source="alt"
                            variant="outlined"
                            label={translate(`manager.resources.${RESOURCE}.alt`)}
                            validate={requiredValidate}
                        />
                        <UploadField
                            url=""
                            data=""
                            source="path"
                            label={translate(`manager.resources.${RESOURCE}.path`)}
                            validateRequired
                        />
                    </SimpleForm>
                </Create>
            </Dialog>
        </>
    )
}

const ListWrapper = styled.section`
    width: 100%;
    & .MuiPaper-root {
        border: 0;
    }
`

const requiredValidate = [required()]

export default PackageGalleryItemList
