import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AvailableMentionsQueryVariables = Types.Exact<{
  type?: Types.InputMaybe<Types.PersonalizationAvailableMentionsTypes>;
  withEntity?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type AvailableMentionsQuery = { __typename?: 'Query', personalizationAvailableMentions?: string | null | undefined };


export const AvailableMentionsDocument = gql`
    query AvailableMentions($type: personalizationAvailableMentionsTypes, $withEntity: Boolean) {
  personalizationAvailableMentions(type: $type, withEntity: $withEntity)
}
    `;

/**
 * __useAvailableMentionsQuery__
 *
 * To run a query within a React component, call `useAvailableMentionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableMentionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableMentionsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      withEntity: // value for 'withEntity'
 *   },
 * });
 */
export function useAvailableMentionsQuery(baseOptions?: Apollo.QueryHookOptions<AvailableMentionsQuery, AvailableMentionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableMentionsQuery, AvailableMentionsQueryVariables>(AvailableMentionsDocument, options);
      }
export function useAvailableMentionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableMentionsQuery, AvailableMentionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableMentionsQuery, AvailableMentionsQueryVariables>(AvailableMentionsDocument, options);
        }
export type AvailableMentionsQueryHookResult = ReturnType<typeof useAvailableMentionsQuery>;
export type AvailableMentionsLazyQueryHookResult = ReturnType<typeof useAvailableMentionsLazyQuery>;
export type AvailableMentionsQueryResult = Apollo.QueryResult<AvailableMentionsQuery, AvailableMentionsQueryVariables>;