import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RootNodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RootNodesQuery = { __typename?: 'Query', configuratorNodes: Array<{ __typename?: 'configuratorNode', id: string, node_attributes?: string | null | undefined, parent_id?: number | null | undefined, code?: string | null | undefined, is_edited?: boolean | null | undefined, children: Array<{ __typename?: 'configuratorNode', id: string, node_attributes?: string | null | undefined, parent_id?: number | null | undefined, code?: string | null | undefined, children: Array<{ __typename?: 'configuratorNode', id: string, node_attributes?: string | null | undefined, parent_id?: number | null | undefined, code?: string | null | undefined }> }> }> };


export const RootNodesDocument = gql`
    query RootNodes {
  configuratorNodes(parent_id: null) {
    id
    node_attributes
    parent_id
    code
    is_edited
    children {
      id
      node_attributes
      parent_id
      code
      children {
        id
        node_attributes
        parent_id
        code
      }
    }
  }
}
    `;

/**
 * __useRootNodesQuery__
 *
 * To run a query within a React component, call `useRootNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootNodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRootNodesQuery(baseOptions?: Apollo.QueryHookOptions<RootNodesQuery, RootNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RootNodesQuery, RootNodesQueryVariables>(RootNodesDocument, options);
      }
export function useRootNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RootNodesQuery, RootNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RootNodesQuery, RootNodesQueryVariables>(RootNodesDocument, options);
        }
export type RootNodesQueryHookResult = ReturnType<typeof useRootNodesQuery>;
export type RootNodesLazyQueryHookResult = ReturnType<typeof useRootNodesLazyQuery>;
export type RootNodesQueryResult = Apollo.QueryResult<RootNodesQuery, RootNodesQueryVariables>;