import { Theme, withStyles } from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'

const GalleryPopupActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions)

export default GalleryPopupActions
