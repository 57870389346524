import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PackagesPackageGroupGalleryItemQueryVariables = Types.Exact<{
  packagesPackageGroupGalleryItemId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type PackagesPackageGroupGalleryItemQuery = { __typename?: 'Query', packagesPackageGroupGalleryItem?: { __typename?: 'packagesPackageGroupGalleryItem', alt: string, group_id: number, id: string, path: string, title: string } | null | undefined };


export const PackagesPackageGroupGalleryItemDocument = gql`
    query PackagesPackageGroupGalleryItem($packagesPackageGroupGalleryItemId: ID) {
  packagesPackageGroupGalleryItem(id: $packagesPackageGroupGalleryItemId) {
    alt
    group_id
    id
    path
    title
  }
}
    `;

/**
 * __usePackagesPackageGroupGalleryItemQuery__
 *
 * To run a query within a React component, call `usePackagesPackageGroupGalleryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesPackageGroupGalleryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesPackageGroupGalleryItemQuery({
 *   variables: {
 *      packagesPackageGroupGalleryItemId: // value for 'packagesPackageGroupGalleryItemId'
 *   },
 * });
 */
export function usePackagesPackageGroupGalleryItemQuery(baseOptions?: Apollo.QueryHookOptions<PackagesPackageGroupGalleryItemQuery, PackagesPackageGroupGalleryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesPackageGroupGalleryItemQuery, PackagesPackageGroupGalleryItemQueryVariables>(PackagesPackageGroupGalleryItemDocument, options);
      }
export function usePackagesPackageGroupGalleryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesPackageGroupGalleryItemQuery, PackagesPackageGroupGalleryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesPackageGroupGalleryItemQuery, PackagesPackageGroupGalleryItemQueryVariables>(PackagesPackageGroupGalleryItemDocument, options);
        }
export type PackagesPackageGroupGalleryItemQueryHookResult = ReturnType<typeof usePackagesPackageGroupGalleryItemQuery>;
export type PackagesPackageGroupGalleryItemLazyQueryHookResult = ReturnType<typeof usePackagesPackageGroupGalleryItemLazyQuery>;
export type PackagesPackageGroupGalleryItemQueryResult = Apollo.QueryResult<PackagesPackageGroupGalleryItemQuery, PackagesPackageGroupGalleryItemQueryVariables>;