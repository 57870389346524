import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SingleUploadMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload'];
  fileName: Types.Scalars['String'];
  path: Types.Scalars['String'];
}>;


export type SingleUploadMutation = { __typename?: 'Mutation', singleUpload?: { __typename?: 'UploadResponse', data?: { __typename?: 'UploadData', uploadModelMedia?: { __typename?: 'File', relativePath?: string | null | undefined, url?: string | null | undefined } | null | undefined } | null | undefined, errors?: Array<{ __typename?: 'ErrorArrayEntry', message?: string | null | undefined, path?: Array<string | null | undefined> | null | undefined, extensions?: { __typename?: 'Extensions', original_message?: string | null | undefined } | null | undefined, locations?: Array<{ __typename?: 'Locations', line?: number | null | undefined, column?: number | null | undefined } | null | undefined> | null | undefined, trace?: Array<{ __typename?: 'StackTrace', line?: number | null | undefined, file?: string | null | undefined, call?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined> | null | undefined } | null | undefined };


export const SingleUploadDocument = gql`
    mutation SingleUpload($file: Upload!, $fileName: String!, $path: String!) {
  singleUpload(file: $file, fileName: $fileName, path: $path) {
    data {
      uploadModelMedia {
        relativePath
        url
      }
    }
    errors {
      extensions {
        original_message
      }
      locations {
        line
        column
      }
      message
      path
      trace {
        line
        file
        call
      }
    }
  }
}
    `;
export type SingleUploadMutationFn = Apollo.MutationFunction<SingleUploadMutation, SingleUploadMutationVariables>;

/**
 * __useSingleUploadMutation__
 *
 * To run a mutation, you first call `useSingleUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleUploadMutation, { data, loading, error }] = useSingleUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *      fileName: // value for 'fileName'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useSingleUploadMutation(baseOptions?: Apollo.MutationHookOptions<SingleUploadMutation, SingleUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SingleUploadMutation, SingleUploadMutationVariables>(SingleUploadDocument, options);
      }
export type SingleUploadMutationHookResult = ReturnType<typeof useSingleUploadMutation>;
export type SingleUploadMutationResult = Apollo.MutationResult<SingleUploadMutation>;
export type SingleUploadMutationOptions = Apollo.BaseMutationOptions<SingleUploadMutation, SingleUploadMutationVariables>;