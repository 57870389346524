import { IChangeEvent } from '@rjsf/core'
import Form from '@rjsf/material-ui'
import { useState } from 'react'
import { useForm } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { formStyles } from 'layout/styles/formStyles'
import validator from '@rjsf/validator-ajv8'

interface PackageSchemaFormProps {
    packageSchema: string
    definition: string
}

const useStyles = makeStyles(formStyles)

const PackageSchemaForm = ({ packageSchema, definition }: PackageSchemaFormProps) => {
    const formStyle = useStyles()
    const { change } = useForm()
    const [formData] = useState<unknown>(definition && JSON.parse(definition))

    const onChangeHandler = (e: IChangeEvent<unknown>) => {
        // 1. Add stringified jsonSchema Formdata (as definition field) to main react-admin Form
        change('definition', JSON.stringify(e.formData))
    }

    const packageSchemaObject = packageSchema && JSON.parse(packageSchema)
    const uiSchema = {
        package_config: {
            code: {
                'ui:widget': 'hidden'
            }
        }
    }

    // Unset gallery
    delete packageSchemaObject.properties.gallery

    return (
        <Form
            schema={packageSchemaObject}
            uiSchema={uiSchema}
            formData={formData}
            onChange={(e) => onChangeHandler(e)}
            onError={(e) => console.error('errors', e)}
            validator={validator}
        >
            <button className={formStyle.hidden} type="submit" />
        </Form>
    )
}

export default PackageSchemaForm
