interface ErrorProps {
    statusNumber: number
}

const AuthProvider = {
    // called when the user attempts to log in
    login: () => Promise.resolve(),
    // called when the user clicks on the logout button
    logout: () => {
        //DELETE AUTH0 COOKIES
        document.cookie.split(';').forEach((c) => {
            if (c?.includes('auth0')) {
                document.cookie = c
                    .replace(/^ +/, '')
                    .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
            }
        })

        localStorage.removeItem('token')
        return Promise.resolve()
    },
    // called when the API returns an error
    checkError: ({ statusNumber }: ErrorProps) => {
        if (statusNumber === 401 || statusNumber === 403) {
            localStorage.removeItem('token')
            return Promise.reject()
        }

        return Promise.resolve()
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        if (localStorage.getItem('token')) {
            return Promise.resolve()
        }

        //If fails, try again to make sure BE can pickup JWT
        await new Promise((r) => setTimeout(r, 500))
        if (localStorage.getItem('token')) {
            return Promise.resolve()
        }

        return Promise.reject()
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        return Promise.resolve()
    }
}

export default AuthProvider
