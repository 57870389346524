import GroupIcon from '@mui/icons-material/GroupWork'
import PackageGroupCreate from './PackageGroupCreate'
import PackageGroupEdit from './PackageGroupEdit'
import PackageGroupList from './PackageGroupList'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: PackageGroupList,
    create: PackageGroupCreate,
    edit: PackageGroupEdit,
    icon: GroupIcon
}
