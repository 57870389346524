import { required, SelectInput, useTranslate } from 'react-admin'
import { ChoiceType } from 'types'

import { useConfiguratorPresetsQuery } from 'apollo/configurator/queries/ConfiguratorPresets.generated'

interface ConfiguratorNodesSelectProps {
    label?: string
    disabled?: boolean
    required?: boolean
    setIsSelectedPreset?: (event) => void
    parentModelCode?: string | null
    isFilteredPresetsByModelCode?: boolean
}

const ConfiguratorPresetsSelect = ({
    label,
    disabled = false,
    required = false,
    setIsSelectedPreset,
    parentModelCode = null,
    isFilteredPresetsByModelCode = false
}: ConfiguratorNodesSelectProps) => {
    const translate = useTranslate()
    const { data, loading } = useConfiguratorPresetsQuery({
        variables: {
            first: 99999
        }
    })

    const filteredData = isFilteredPresetsByModelCode
        ? data?.configuratorPresets?.data?.filter((item) => item?.model?.code === parentModelCode)
        : data?.configuratorPresets?.data

    const choices = filteredData?.map(
        (item): ChoiceType => ({
            id: item?.code,
            name: item?.internal_name ?? ''
        })
    )

    return (
        <>
            <SelectInput
                fullWidth
                variant="outlined"
                source="preset_code"
                label={label}
                choices={choices ?? []}
                validate={required && requiredValidate}
                onChange={setIsSelectedPreset}
                disabled={disabled || loading || filteredData?.length === 0}
                helperText={
                    loading
                        ? translate('manager.loading')
                        : filteredData?.length === 0
                        ? translate('manager.resources.package.products.hints.no_presets_for_model')
                        : null
                }
            />
        </>
    )
}

const requiredValidate = [required()]

export default ConfiguratorPresetsSelect
