import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DuplicateModelMutationVariables = Types.Exact<{
  source: Types.Scalars['Int'];
  destination: Types.Scalars['String'];
}>;


export type DuplicateModelMutation = { __typename?: 'Mutation', configuratorDuplicateModel?: number | null | undefined };


export const DuplicateModelDocument = gql`
    mutation DuplicateModel($source: Int!, $destination: String!) {
  configuratorDuplicateModel(source: $source, destination: $destination)
}
    `;
export type DuplicateModelMutationFn = Apollo.MutationFunction<DuplicateModelMutation, DuplicateModelMutationVariables>;

/**
 * __useDuplicateModelMutation__
 *
 * To run a mutation, you first call `useDuplicateModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateModelMutation, { data, loading, error }] = useDuplicateModelMutation({
 *   variables: {
 *      source: // value for 'source'
 *      destination: // value for 'destination'
 *   },
 * });
 */
export function useDuplicateModelMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateModelMutation, DuplicateModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateModelMutation, DuplicateModelMutationVariables>(DuplicateModelDocument, options);
      }
export type DuplicateModelMutationHookResult = ReturnType<typeof useDuplicateModelMutation>;
export type DuplicateModelMutationResult = Apollo.MutationResult<DuplicateModelMutation>;
export type DuplicateModelMutationOptions = Apollo.BaseMutationOptions<DuplicateModelMutation, DuplicateModelMutationVariables>;