export const resourceMap = {
    Education: {
        servicePrefix: 'schools'
    },
    Package: {
        servicePrefix: 'packages'
    },
    PackageProduct: {
        servicePrefix: 'packages'
    },
    PackageGalleryItem: {
        servicePrefix: 'packages'
    },
    PackageGroup: {
        servicePrefix: 'packages'
    },
    PackageGroupGalleryItem: {
        servicePrefix: 'packages'
    },
    PackageGroupCollection: {
        servicePrefix: 'packages'
    },
    PackageGroupCollectionGalleryItem: {
        servicePrefix: 'packages'
    },
    Rule: {
        servicePrefix: 'personalization'
    },
    Preset: {
        servicePrefix: 'configurator'
    },
    School: {
        servicePrefix: 'schools'
    },
    SchoolClass: {
        servicePrefix: 'schools'
    },
    SchoolClassRepresentative: {
        servicePrefix: 'schools'
    },
    SchoolPeriod: {
        servicePrefix: 'schools'
    },
    SchoolTag: {
        servicePrefix: 'schools'
    },
    SchoolYear: {
        servicePrefix: 'schools'
    }
}
