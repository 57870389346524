import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PersonalizationRulesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PersonalizationRuleFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  orderBy?: Types.InputMaybe<Array<Types.PersonalizationQueryRulesOrderByOrderByClause> | Types.PersonalizationQueryRulesOrderByOrderByClause>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type PersonalizationRulesQuery = { __typename?: 'Query', personalizationRules?: { __typename?: 'personalizationRulePaginator', data: Array<{ __typename?: 'personalizationRule', actions: string, active: boolean, break?: boolean | null | undefined, created_at: any, id: string, label: string, priority?: number | null | undefined, rules: string, updated_at: any }>, paginatorInfo: { __typename?: 'personalizationPaginatorInfo', count: number, currentPage: number, firstItem?: number | null | undefined, hasMorePages: boolean, lastItem?: number | null | undefined, lastPage: number, perPage: number, total: number } } | null | undefined };


export const PersonalizationRulesDocument = gql`
    query PersonalizationRules($filter: personalizationRuleFilterInput, $first: Int, $orderBy: [personalizationQueryRulesOrderByOrderByClause!], $page: Int) {
  personalizationRules(
    filter: $filter
    first: $first
    orderBy: $orderBy
    page: $page
  ) {
    data {
      actions
      active
      break
      created_at
      id
      label
      priority
      rules
      updated_at
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
    `;

/**
 * __usePersonalizationRulesQuery__
 *
 * To run a query within a React component, call `usePersonalizationRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalizationRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalizationRulesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePersonalizationRulesQuery(baseOptions?: Apollo.QueryHookOptions<PersonalizationRulesQuery, PersonalizationRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalizationRulesQuery, PersonalizationRulesQueryVariables>(PersonalizationRulesDocument, options);
      }
export function usePersonalizationRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalizationRulesQuery, PersonalizationRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalizationRulesQuery, PersonalizationRulesQueryVariables>(PersonalizationRulesDocument, options);
        }
export type PersonalizationRulesQueryHookResult = ReturnType<typeof usePersonalizationRulesQuery>;
export type PersonalizationRulesLazyQueryHookResult = ReturnType<typeof usePersonalizationRulesLazyQuery>;
export type PersonalizationRulesQueryResult = Apollo.QueryResult<PersonalizationRulesQuery, PersonalizationRulesQueryVariables>;