import { useAuthProvider } from 'context/auth/AuthProvider'
import { Resource } from 'react-admin'

export const ResourceWrapper = (props) => {
    const { name } = props
    const { permissions, isLoading } = useAuthProvider()

    if (isLoading) {
        return <></>
    }

    return permissions?.[name]?.length ? <Resource name={name} {...props} /> : <></>
}
