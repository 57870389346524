import GroupsIcon from '@mui/icons-material/Groups'
import SchoolClassList from './SchoolClassList'
import SchoolClassEdit from './SchoolClassEdit'
import SchoolClassCreate from './SchoolClassCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: SchoolClassList,
    edit: SchoolClassEdit,
    create: SchoolClassCreate,
    icon: GroupsIcon
}
