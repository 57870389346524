import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BatchExportModelPresetsMutationVariables = Types.Exact<{
  model: Types.Scalars['ID'];
}>;


export type BatchExportModelPresetsMutation = { __typename?: 'Mutation', configuratorBatchExportModelPresets: Array<{ __typename?: 'configuratorPresetExportedItem', error?: string | null | undefined, success: boolean, internal_name: string } | null | undefined> };


export const BatchExportModelPresetsDocument = gql`
    mutation BatchExportModelPresets($model: ID!) {
  configuratorBatchExportModelPresets(model: $model) {
    error
    success
    internal_name
  }
}
    `;
export type BatchExportModelPresetsMutationFn = Apollo.MutationFunction<BatchExportModelPresetsMutation, BatchExportModelPresetsMutationVariables>;

/**
 * __useBatchExportModelPresetsMutation__
 *
 * To run a mutation, you first call `useBatchExportModelPresetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchExportModelPresetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchExportModelPresetsMutation, { data, loading, error }] = useBatchExportModelPresetsMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useBatchExportModelPresetsMutation(baseOptions?: Apollo.MutationHookOptions<BatchExportModelPresetsMutation, BatchExportModelPresetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchExportModelPresetsMutation, BatchExportModelPresetsMutationVariables>(BatchExportModelPresetsDocument, options);
      }
export type BatchExportModelPresetsMutationHookResult = ReturnType<typeof useBatchExportModelPresetsMutation>;
export type BatchExportModelPresetsMutationResult = Apollo.MutationResult<BatchExportModelPresetsMutation>;
export type BatchExportModelPresetsMutationOptions = Apollo.BaseMutationOptions<BatchExportModelPresetsMutation, BatchExportModelPresetsMutationVariables>;