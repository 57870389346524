import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteSchoolRepresentativeMutationVariables = Types.Exact<{
  representativeId: Types.Scalars['Int'];
  schoolId: Types.Scalars['Int'];
}>;


export type DeleteSchoolRepresentativeMutation = { __typename?: 'Mutation', schoolsDeleteSchoolSchoolClassRepresentative?: { __typename?: 'schoolsSchoolSchoolClassRepresentative', id: string, representative_id: number, school_id: number } | null | undefined };


export const DeleteSchoolRepresentativeDocument = gql`
    mutation DeleteSchoolRepresentative($representativeId: Int!, $schoolId: Int!) {
  schoolsDeleteSchoolSchoolClassRepresentative(
    representative_id: $representativeId
    school_id: $schoolId
  ) {
    id
    representative_id
    school_id
  }
}
    `;
export type DeleteSchoolRepresentativeMutationFn = Apollo.MutationFunction<DeleteSchoolRepresentativeMutation, DeleteSchoolRepresentativeMutationVariables>;

/**
 * __useDeleteSchoolRepresentativeMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolRepresentativeMutation, { data, loading, error }] = useDeleteSchoolRepresentativeMutation({
 *   variables: {
 *      representativeId: // value for 'representativeId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useDeleteSchoolRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchoolRepresentativeMutation, DeleteSchoolRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchoolRepresentativeMutation, DeleteSchoolRepresentativeMutationVariables>(DeleteSchoolRepresentativeDocument, options);
      }
export type DeleteSchoolRepresentativeMutationHookResult = ReturnType<typeof useDeleteSchoolRepresentativeMutation>;
export type DeleteSchoolRepresentativeMutationResult = Apollo.MutationResult<DeleteSchoolRepresentativeMutation>;
export type DeleteSchoolRepresentativeMutationOptions = Apollo.BaseMutationOptions<DeleteSchoolRepresentativeMutation, DeleteSchoolRepresentativeMutationVariables>;