import { useSelector } from 'react-redux'
import { Layout, LayoutProps } from 'react-admin'

import AppBar from './AppBar'
import { AppState } from '../types'
import { Menu } from '.'
import { darkTheme } from './theme/DarkTheme'
import { lightTheme } from './theme/LightTheme'
import { proudNerdsTheme } from './theme/ProudNerdsTheme'
import { ErrorContext } from '../context/error/ErrorContext'
import { useContext } from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import GalleryPopup from 'components/modelManager/molecules/gallery/GalleryPopup'

const CustomLayout = (props: LayoutProps) => {
    const theme = useSelector((state: AppState) => {
        let newTheme

        switch (state.theme || process.env.REACT_APP_THEME) {
            case 'dark':
                newTheme = darkTheme
                break
            case 'light':
                newTheme = lightTheme
                break
            case 'proudNerds':
                newTheme = proudNerdsTheme
                break
            default:
                newTheme = lightTheme
        }

        return newTheme
    })

    const { state: errorState, setErrorState } = useContext(ErrorContext)

    return (
        <Layout {...props} appBar={AppBar} menu={Menu} theme={theme}>
            {props.children}

            <Snackbar
                open={errorState.hasError}
                onClose={() => setErrorState({ ...errorState, hasError: false })}
                key={Date.now()}
            >
                <Alert
                    onClose={() => setErrorState({ ...errorState, hasError: false })}
                    severity="error"
                >
                    {errorState.message}
                </Alert>
            </Snackbar>

            <GalleryPopup />
        </Layout>
    )
}

export default CustomLayout
