import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConfiguratorGetAttributesTreeByModelNodeTypeQueryVariables = Types.Exact<{
  modelNodeType: Types.Scalars['String'];
}>;


export type ConfiguratorGetAttributesTreeByModelNodeTypeQuery = { __typename?: 'Query', configuratorGetAttributesTreeByModelNodeType?: string | null | undefined };


export const ConfiguratorGetAttributesTreeByModelNodeTypeDocument = gql`
    query ConfiguratorGetAttributesTreeByModelNodeType($modelNodeType: String!) {
  configuratorGetAttributesTreeByModelNodeType(modelNodeType: $modelNodeType)
}
    `;

/**
 * __useConfiguratorGetAttributesTreeByModelNodeTypeQuery__
 *
 * To run a query within a React component, call `useConfiguratorGetAttributesTreeByModelNodeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguratorGetAttributesTreeByModelNodeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguratorGetAttributesTreeByModelNodeTypeQuery({
 *   variables: {
 *      modelNodeType: // value for 'modelNodeType'
 *   },
 * });
 */
export function useConfiguratorGetAttributesTreeByModelNodeTypeQuery(baseOptions: Apollo.QueryHookOptions<ConfiguratorGetAttributesTreeByModelNodeTypeQuery, ConfiguratorGetAttributesTreeByModelNodeTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfiguratorGetAttributesTreeByModelNodeTypeQuery, ConfiguratorGetAttributesTreeByModelNodeTypeQueryVariables>(ConfiguratorGetAttributesTreeByModelNodeTypeDocument, options);
      }
export function useConfiguratorGetAttributesTreeByModelNodeTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfiguratorGetAttributesTreeByModelNodeTypeQuery, ConfiguratorGetAttributesTreeByModelNodeTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfiguratorGetAttributesTreeByModelNodeTypeQuery, ConfiguratorGetAttributesTreeByModelNodeTypeQueryVariables>(ConfiguratorGetAttributesTreeByModelNodeTypeDocument, options);
        }
export type ConfiguratorGetAttributesTreeByModelNodeTypeQueryHookResult = ReturnType<typeof useConfiguratorGetAttributesTreeByModelNodeTypeQuery>;
export type ConfiguratorGetAttributesTreeByModelNodeTypeLazyQueryHookResult = ReturnType<typeof useConfiguratorGetAttributesTreeByModelNodeTypeLazyQuery>;
export type ConfiguratorGetAttributesTreeByModelNodeTypeQueryResult = Apollo.QueryResult<ConfiguratorGetAttributesTreeByModelNodeTypeQuery, ConfiguratorGetAttributesTreeByModelNodeTypeQueryVariables>;