import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolSalesResponsiblesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SchoolSalesResponsiblesQuery = { __typename?: 'Query', schoolsSchoolSalesResponsibles?: { __typename?: 'schoolsSchoolSalesResponsiblePaginator', data: Array<{ __typename?: 'schoolsSchoolSalesResponsible', id: string, name: string, active: boolean }> } | null | undefined };


export const SchoolSalesResponsiblesDocument = gql`
    query SchoolSalesResponsibles {
  schoolsSchoolSalesResponsibles {
    data {
      id
      name
      active
    }
  }
}
    `;

/**
 * __useSchoolSalesResponsiblesQuery__
 *
 * To run a query within a React component, call `useSchoolSalesResponsiblesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolSalesResponsiblesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolSalesResponsiblesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolSalesResponsiblesQuery(baseOptions?: Apollo.QueryHookOptions<SchoolSalesResponsiblesQuery, SchoolSalesResponsiblesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolSalesResponsiblesQuery, SchoolSalesResponsiblesQueryVariables>(SchoolSalesResponsiblesDocument, options);
      }
export function useSchoolSalesResponsiblesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolSalesResponsiblesQuery, SchoolSalesResponsiblesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolSalesResponsiblesQuery, SchoolSalesResponsiblesQueryVariables>(SchoolSalesResponsiblesDocument, options);
        }
export type SchoolSalesResponsiblesQueryHookResult = ReturnType<typeof useSchoolSalesResponsiblesQuery>;
export type SchoolSalesResponsiblesLazyQueryHookResult = ReturnType<typeof useSchoolSalesResponsiblesLazyQuery>;
export type SchoolSalesResponsiblesQueryResult = Apollo.QueryResult<SchoolSalesResponsiblesQuery, SchoolSalesResponsiblesQueryVariables>;