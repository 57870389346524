/* eslint-disable no-unused-vars */
import { Button, DialogActions, DialogTitle, IconButton, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { useCreateDirMutation } from 'apollo/configurator/mutations/CreateDir.generated'
import React, { SyntheticEvent, useState } from 'react'
import { useTranslate } from 'react-admin'
import { ExtendedNodeData, TreeItem } from 'react-sortable-tree'
import { addNode } from '../manager/helpers/TreeHelperFunctions'

type TFsFormNode = { dir: string; open: boolean; rowInfo: ExtendedNodeData | null }

interface IFsNodeForm extends TFsFormNode {
    setForm: ({ dir, open, rowInfo }: TFsFormNode) => void
    treeState: TreeItem[] | null
    setTreeState: (treeState: TreeItem[] | null) => void
}

interface IFormData {
    directory?: string
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& > *': {
                width: '50%'
            }
        },
        invalidInput: {
            border: 'red solid 2px',
            '&:after': {
                borderBottom: '2px solid red'
            }
        },
        content: {
            padding: 20
        },
        titleContainer: {
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20
        },
        errorMessage: {
            color: 'tomato',
            fontSize: '.85rem'
        },
        title: {
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 0,
            paddingRight: 0
        },
        iconButton: {
            height: 50,
            width: 50
        }
    })
)

const AddFSFolderForm = ({ dir, open, rowInfo, setForm, treeState, setTreeState }: IFsNodeForm) => {
    const [formData, setFormData] = useState<IFormData>({})
    const [createDir] = useCreateDirMutation({})
    const [error, setError] = useState<string | null>(null)
    const classes = useStyles()
    const translate = useTranslate()
    const regex = /\s/

    const OnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const { value, name } = e.currentTarget
        if (!regex.test(value)) {
            if (error) {
                setError(null)
            }
            setFormData({ [name]: value })
        } else {
            setError(translate('manager.resources.model_manager.errors.no_spaces_use_underscores'))
        }
    }

    const OnSaveHandler = (e: SyntheticEvent) => {
        e.preventDefault()

        const { directory } = formData

        if (!directory) {
            return setError(translate('manager.resources.model_manager.errors.no_dir_filled_in'))
        }

        if (directory && regex.test(directory)) {
            return setError(
                translate('manager.resources.model_manager.errors.cannot_add_contains_spaces')
            )
        }

        createDir({
            variables: {
                parent_dir: dir,
                dir: directory || '/'
            }
        })
            .then(async (res) => {
                const makeDir = res?.data?.configuratorMakeDir
                if (treeState && rowInfo && res && directory) {
                    // The tree needs to be updated so we don't have to refetch the whole tree and possibly lose changes.
                    const addFolder = await addNode(
                        treeState,
                        {
                            title: directory,
                            children: [],
                            isDirectory: true,
                            expanded: true,
                            id: makeDir?.id as any
                        },
                        rowInfo,
                        rowInfo?.node?.id
                    )

                    addFolder && setTreeState([...addFolder?.treeData])
                }

                setForm({ dir: '', open: false, rowInfo: null })
                setFormData({})
            })
            .catch((err) => {
                setError(err.message)
            })
    }

    const handleClose = () => {
        setForm({ dir: '', open: false, rowInfo: null })
        setFormData({})
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onBackdropClick={handleClose}
            onEscapeKeyDown={handleClose}
        >
            <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <DialogTitle className={classes.title}>
                            {translate('manager.resources.model_manager.folder_form_dialog.title')}
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            className={classes.iconButton}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <DialogContent className={classes.content}>
                <DialogContentText>
                    {translate('manager.resources.model_manager.folder_form_dialog.content')}
                </DialogContentText>
                <form
                    className={classes.root}
                    autoComplete="off"
                    onSubmit={(e: SyntheticEvent) => OnSaveHandler(e)}
                >
                    <TextField
                        id="standard-basic"
                        label="Directory name"
                        name="directory"
                        inputProps={{ className: error ? classes.invalidInput : '', pattern: /\s/ }}
                        autoFocus
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => OnChangeHandler(e)}
                    />
                </form>
                {error && <p className={classes.errorMessage}>Error: {error}</p>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {translate('manager.resources.model_manager.folder_form_dialog.cancel')}
                </Button>
                <Button onClick={OnSaveHandler} color="primary">
                    {translate('manager.resources.model_manager.folder_form_dialog.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddFSFolderForm
