import DateRangeIcon from '@mui/icons-material/DateRange'
import SchoolYearList from './SchoolYearList'
import SchoolYearEdit from './SchoolYearEdit'
import SchoolYearCreate from './SchoolYearCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: SchoolYearList,
    edit: SchoolYearEdit,
    create: SchoolYearCreate,
    icon: DateRangeIcon
}
