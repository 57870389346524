import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { GalleryContext } from 'context/gallery/GalleryContext'
import FolderTree from '../../molecules/gallery/FolderTree'
import ImagesBlock from '../../molecules/gallery/ImagesBlock'

const useStyles = makeStyles(() => ({
    gallery: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr'
    },
    galleryButton: {
        margin: '30px 0 30px auto',
        display: 'block'
    }
}))

const GalleryContent = () => {
    const classes = useStyles()
    const { state, setGalleryState } = useContext(GalleryContext)
    return (
        <section className={classes.gallery}>
            {!state.openGallery && (
                <React.Fragment>
                    <div>
                        <FolderTree />
                    </div>
                    <div>
                        <Button
                            onClick={() =>
                                setGalleryState({ ...state, isUploading: !state.isUploading })
                            }
                            variant="contained"
                            color="primary"
                            className={classes.galleryButton}
                        >
                            {state.isUploading ? 'Close' : 'Upload media'}
                        </Button>
                        <ImagesBlock />
                    </div>
                </React.Fragment>
            )}
        </section>
    )
}

export default GalleryContent
