import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ConfiguratorGetAttributesTreeByModelNodeQueryVariables = Types.Exact<{
  model: Types.Scalars['String'];
  modelNode: Types.Scalars['String'];
}>;


export type ConfiguratorGetAttributesTreeByModelNodeQuery = { __typename?: 'Query', configuratorGetAttributesTreeByModelNode?: string | null | undefined };


export const ConfiguratorGetAttributesTreeByModelNodeDocument = gql`
    query ConfiguratorGetAttributesTreeByModelNode($model: String!, $modelNode: String!) {
  configuratorGetAttributesTreeByModelNode(model: $model, modelNode: $modelNode)
}
    `;

/**
 * __useConfiguratorGetAttributesTreeByModelNodeQuery__
 *
 * To run a query within a React component, call `useConfiguratorGetAttributesTreeByModelNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguratorGetAttributesTreeByModelNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguratorGetAttributesTreeByModelNodeQuery({
 *   variables: {
 *      model: // value for 'model'
 *      modelNode: // value for 'modelNode'
 *   },
 * });
 */
export function useConfiguratorGetAttributesTreeByModelNodeQuery(baseOptions: Apollo.QueryHookOptions<ConfiguratorGetAttributesTreeByModelNodeQuery, ConfiguratorGetAttributesTreeByModelNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfiguratorGetAttributesTreeByModelNodeQuery, ConfiguratorGetAttributesTreeByModelNodeQueryVariables>(ConfiguratorGetAttributesTreeByModelNodeDocument, options);
      }
export function useConfiguratorGetAttributesTreeByModelNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfiguratorGetAttributesTreeByModelNodeQuery, ConfiguratorGetAttributesTreeByModelNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfiguratorGetAttributesTreeByModelNodeQuery, ConfiguratorGetAttributesTreeByModelNodeQueryVariables>(ConfiguratorGetAttributesTreeByModelNodeDocument, options);
        }
export type ConfiguratorGetAttributesTreeByModelNodeQueryHookResult = ReturnType<typeof useConfiguratorGetAttributesTreeByModelNodeQuery>;
export type ConfiguratorGetAttributesTreeByModelNodeLazyQueryHookResult = ReturnType<typeof useConfiguratorGetAttributesTreeByModelNodeLazyQuery>;
export type ConfiguratorGetAttributesTreeByModelNodeQueryResult = Apollo.QueryResult<ConfiguratorGetAttributesTreeByModelNodeQuery, ConfiguratorGetAttributesTreeByModelNodeQueryVariables>;