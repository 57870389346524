import { forwardRef, useState } from 'react'
import { AppBar, UserMenu, MenuItemLink, useTranslate, useRefresh } from 'react-admin'
import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/core/styles'
import Logo from './Logo'
import { useAuth0 } from '@auth0/auth0-react'
import { IconButton, Tooltip, Snackbar } from '@material-ui/core'
import RestoreIcon from '@mui/icons-material/Restore'

import { useFlushProductsCacheMutation } from 'apollo/personalization/mutations/FlushProductsCache.generated'
import { useFlushActionsCacheMutation } from 'apollo/personalization/mutations/FlushActionsCache.generated'

const SNACKBAR_TIMEOUT = 4000

const useStyles = makeStyles({
    spacer: {
        flex: 1
    },
    nickname: {
        marginRight: '0.5em'
    }
})

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate()
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('manager.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    )
})

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
)

const CustomAppBar = (props: any) => {
    const { user } = useAuth0()
    const [isFlushed, setIsFlushed] = useState<boolean>(false)
    const reload = useRefresh()
    const translate = useTranslate()

    const [flushProductsCache, { loading: flushProductsCacheLoading }] =
        useFlushProductsCacheMutation()
    const [flushActionsCache, { loading: flushActionsCacheLoading }] =
        useFlushActionsCacheMutation()

    const handleFlushButtonClick = () => {
        setIsFlushed(true)
        Promise.all([flushProductsCache(), flushActionsCache()]).then(() => {
            reload()
        })
    }

    const classes = useStyles()
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <div className={classes.spacer} />
            <Logo />
            <div className={classes.spacer} />
            {user && <p className={classes.nickname}>{user?.nickname}</p>}
            <Tooltip title="Flush cache">
                <IconButton
                    aria-label="flush"
                    disabled={flushProductsCacheLoading || flushActionsCacheLoading}
                    onClick={handleFlushButtonClick}
                >
                    <RestoreIcon />
                </IconButton>
            </Tooltip>

            <Snackbar
                open={isFlushed}
                onClose={() => setIsFlushed(false)}
                autoHideDuration={SNACKBAR_TIMEOUT}
                message={translate('manager.cache.cacheFlushedSuccessfully')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </AppBar>
    )
}

export default CustomAppBar
