import { useTranslate } from 'react-admin'
import { Record } from 'ra-core'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { COLORS } from 'layout/theme/colors'

interface DatagridPublishedColumnContentProps {
    record?: Record | undefined
}

const styles = makeStyles({
    published: {
        color: COLORS.theme.success_green.main
    },
    notPublished: {
        color: COLORS.theme.error_red.main
    }
})

const DatagridPublishedColumnContent = ({ record }: DatagridPublishedColumnContentProps) => {
    const translate = useTranslate()
    const classes = styles()

    return record?.active ? (
        <Typography variant="body2" className={classes.published}>
            {translate('manager.resources.general.published')}
        </Typography>
    ) : (
        <Typography variant="body2" className={classes.notPublished}>
            {translate('manager.resources.general.not_published')}
        </Typography>
    )
}
export default DatagridPublishedColumnContent
