import { useEffect, useState } from 'react'
import { AutocompleteArrayInput } from 'react-admin'
import { useForm } from 'react-final-form'

import { ChoiceType } from 'types'

const TagsAutocompleteInput = ({ source, initialValues, initialChoices, onCreate }) => {
    const { change } = useForm()
    const [choices, setChoices] = useState<ChoiceType[]>()
    const [values, setValues] = useState<ChoiceType[]>()

    useEffect(() => {
        setValues(initialValues)
    }, [initialValues])

    useEffect(() => {
        setChoices(initialChoices)
    }, [initialChoices])

    // Update main form source when values change
    useEffect(() => {
        // Disabled in order to make <FormSpy /> works in forms
        // change(source, values)
    }, [source, values, change])

    return (
        <AutocompleteArrayInput
            fullWidth
            source={source}
            variant="outlined"
            format={(value) => value && value.map((v) => v.id)}
            parse={(value) => value && value.map((v) => ({ id: v }))}
            allowDuplicates={false}
            onCreate={onCreate}
            choices={choices}
        />
    )
}

export default TagsAutocompleteInput
