import { useState } from 'react'
import CheckboxTree, { Node } from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { Box, Divider, LinearProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from '@rjsf/utils/dist'
import { FormSpy, useForm } from 'react-final-form'

import { useConfiguratorModelTreeStructureQuery } from 'apollo/package_manager/queries/ConfiguratorModelTreeStructure.generated'

const STRING_SEPARATOR: string = ','

const useStyles = makeStyles(() => ({
    wordBreadAll: {
        wordBreak: 'break-all'
    }
}))

const ConfiguratorOptionTreeMultiple = (props: FieldProps) => {
    const classes = useStyles()
    const { getState } = useForm()
    const parsedPropsValue = props.value ? props.value.split(STRING_SEPARATOR) : []
    const [selectedRootModelCode, setSelectedRootModelCode] = useState(getState().values.root_model)
    const [checked, setChecked] = useState(parsedPropsValue)
    const [expanded, setExpanded] = useState([])

    const onCheck = (checked) => {
        setChecked(checked)
        props.onChange(checked.join(STRING_SEPARATOR))
    }

    const onExpand = (expanded) => {
        setExpanded(expanded)
    }

    const { data, loading } = useConfiguratorModelTreeStructureQuery({
        variables: { code: selectedRootModelCode }
    })

    let parsedConfiguratorModelTreeStructure: Node[] = []
    if (data?.configuratorModelTreeStructure) {
        parsedConfiguratorModelTreeStructure = JSON.parse(data?.configuratorModelTreeStructure)
    }

    return (
        <>
            {loading ? (
                <LinearProgress />
            ) : (
                <>
                    <CheckboxTree
                        showExpandAll={true}
                        noCascade={true}
                        checkModel="all"
                        nodes={parsedConfiguratorModelTreeStructure ?? []}
                        checked={checked}
                        expanded={expanded}
                        onCheck={onCheck}
                        onExpand={onExpand}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Divider />
                    </Box>
                    {parsedPropsValue &&
                        parsedPropsValue.map((element) => (
                            <Typography variant="caption" className={classes.wordBreadAll}>
                                <strong>Path:</strong> {element}
                            </Typography>
                        ))}
                </>
            )}
            <FormSpy
                onChange={(props) => {
                    setSelectedRootModelCode(props.values.root_model)
                }}
            />
        </>
    )
}

export default ConfiguratorOptionTreeMultiple
