import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import SchoolTagList from './SchoolTagList'
import SchoolTagEdit from './SchoolTagEdit'
import SchoolTagCreate from './SchoolTagCreate'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: SchoolTagList,
    edit: SchoolTagEdit,
    create: SchoolTagCreate,
    icon: LocalOfferIcon
}
