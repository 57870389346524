import * as React from 'react'
import { ReactElement, ReactNode } from 'react'
import { List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useAuthProvider } from 'context/auth/AuthProvider'

interface SubMenuProps {
    dense: boolean
    handleToggleCallback: () => void
    icon: ReactElement
    isOpen: boolean
    name: string
    children: ReactNode
}

const SubMenu = ({ handleToggleCallback, isOpen, name, icon, children, dense }: SubMenuProps) => {
    const { permissions, isLoading } = useAuthProvider()
    let display = false

    if (isLoading) {
        return <></>
    }

    //If none of the submenu items are available, hide the collapsible
    if (Array.isArray(children) && children?.length) {
        children?.forEach((element: any) => {
            if (permissions?.[element?.props?.name]?.length) {
                display = true
            }
        })
    }

    const header = (
        <MenuItem dense={dense} onClick={handleToggleCallback}>
            <ListItemIcon sx={{ minWidth: 5 }}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {name}
            </Typography>
        </MenuItem>
    )

    return display ? (
        <div>
            {isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    sx={{
                        '& a': {
                            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                            paddingLeft: 4
                        }
                    }}
                >
                    {children}
                </List>
            </Collapse>
        </div>
    ) : null
}

export default SubMenu
