import { Grid, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { useRedirect, useTranslate } from 'react-admin'

import React from 'react'
import { PersonalizationRule } from './utils/getSchoolTagOccurrences'

export interface OccurencesListProps {
    filteredPersonalizationRules?: PersonalizationRule[] | undefined
}

export const OccurencesList: React.FC<OccurencesListProps> = ({ filteredPersonalizationRules }) => {
    const redirect = useRedirect()
    const translate = useTranslate()

    return (
        <Grid item md={7}>
            <Typography variant="h6" gutterBottom>
                {translate('manager.resources.tag.occurrences')}
            </Typography>

            <List>
                {filteredPersonalizationRules?.map((filteredPersonalizationRule, index) => {
                    return (
                        <ListItem
                            disableGutters
                            key={index}
                            onClick={() =>
                                redirect('edit', `/Rule/${filteredPersonalizationRule.id}`, '')
                            }
                        >
                            <ListItemButton
                                component="a"
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)'
                                }}
                            >
                                <ListItemText primary={filteredPersonalizationRule.label} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </Grid>
    )
}
