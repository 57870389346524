import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PackagesPackageGroupCollectionGalleryItemQueryVariables = Types.Exact<{
  packagesPackageGroupCollectionGalleryItemId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type PackagesPackageGroupCollectionGalleryItemQuery = { __typename?: 'Query', packagesPackageGroupCollectionGalleryItem?: { __typename?: 'packagesPackageGroupCollectionGalleryItem', alt: string, collection_id: number, id: string, path: string, title: string } | null | undefined };


export const PackagesPackageGroupCollectionGalleryItemDocument = gql`
    query PackagesPackageGroupCollectionGalleryItem($packagesPackageGroupCollectionGalleryItemId: ID) {
  packagesPackageGroupCollectionGalleryItem(
    id: $packagesPackageGroupCollectionGalleryItemId
  ) {
    alt
    collection_id
    id
    path
    title
  }
}
    `;

/**
 * __usePackagesPackageGroupCollectionGalleryItemQuery__
 *
 * To run a query within a React component, call `usePackagesPackageGroupCollectionGalleryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesPackageGroupCollectionGalleryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesPackageGroupCollectionGalleryItemQuery({
 *   variables: {
 *      packagesPackageGroupCollectionGalleryItemId: // value for 'packagesPackageGroupCollectionGalleryItemId'
 *   },
 * });
 */
export function usePackagesPackageGroupCollectionGalleryItemQuery(baseOptions?: Apollo.QueryHookOptions<PackagesPackageGroupCollectionGalleryItemQuery, PackagesPackageGroupCollectionGalleryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesPackageGroupCollectionGalleryItemQuery, PackagesPackageGroupCollectionGalleryItemQueryVariables>(PackagesPackageGroupCollectionGalleryItemDocument, options);
      }
export function usePackagesPackageGroupCollectionGalleryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesPackageGroupCollectionGalleryItemQuery, PackagesPackageGroupCollectionGalleryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesPackageGroupCollectionGalleryItemQuery, PackagesPackageGroupCollectionGalleryItemQueryVariables>(PackagesPackageGroupCollectionGalleryItemDocument, options);
        }
export type PackagesPackageGroupCollectionGalleryItemQueryHookResult = ReturnType<typeof usePackagesPackageGroupCollectionGalleryItemQuery>;
export type PackagesPackageGroupCollectionGalleryItemLazyQueryHookResult = ReturnType<typeof usePackagesPackageGroupCollectionGalleryItemLazyQuery>;
export type PackagesPackageGroupCollectionGalleryItemQueryResult = Apollo.QueryResult<PackagesPackageGroupCollectionGalleryItemQuery, PackagesPackageGroupCollectionGalleryItemQueryVariables>;