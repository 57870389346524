import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PackagesCreatePackageGroupPackageMutationVariables = Types.Exact<{
  groupId: Types.Scalars['Int'];
  packageId: Types.Scalars['Int'];
}>;


export type PackagesCreatePackageGroupPackageMutation = { __typename?: 'Mutation', packagesCreatePackageGroupPackage?: { __typename?: 'packagesPackageGroupPackage', id: string, group_id: number, package_id: number } | null | undefined };


export const PackagesCreatePackageGroupPackageDocument = gql`
    mutation PackagesCreatePackageGroupPackage($groupId: Int!, $packageId: Int!) {
  packagesCreatePackageGroupPackage(group_id: $groupId, package_id: $packageId) {
    id
    group_id
    package_id
  }
}
    `;
export type PackagesCreatePackageGroupPackageMutationFn = Apollo.MutationFunction<PackagesCreatePackageGroupPackageMutation, PackagesCreatePackageGroupPackageMutationVariables>;

/**
 * __usePackagesCreatePackageGroupPackageMutation__
 *
 * To run a mutation, you first call `usePackagesCreatePackageGroupPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackagesCreatePackageGroupPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packagesCreatePackageGroupPackageMutation, { data, loading, error }] = usePackagesCreatePackageGroupPackageMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function usePackagesCreatePackageGroupPackageMutation(baseOptions?: Apollo.MutationHookOptions<PackagesCreatePackageGroupPackageMutation, PackagesCreatePackageGroupPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PackagesCreatePackageGroupPackageMutation, PackagesCreatePackageGroupPackageMutationVariables>(PackagesCreatePackageGroupPackageDocument, options);
      }
export type PackagesCreatePackageGroupPackageMutationHookResult = ReturnType<typeof usePackagesCreatePackageGroupPackageMutation>;
export type PackagesCreatePackageGroupPackageMutationResult = Apollo.MutationResult<PackagesCreatePackageGroupPackageMutation>;
export type PackagesCreatePackageGroupPackageMutationOptions = Apollo.BaseMutationOptions<PackagesCreatePackageGroupPackageMutation, PackagesCreatePackageGroupPackageMutationVariables>;