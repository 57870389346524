import {
    List,
    Datagrid,
    FunctionField,
    ListProps,
    TextField,
    Pagination,
    useRedirect
} from 'react-admin'
import { Record, useNotify, useTranslate } from 'ra-core'

import datagridStyles from 'layout/styles/datagridStyles'
import DatagridPublishedColumnContent from 'components/common/DatagridPublishedColumnContent'
import PersonalizationRuleListFilter from './PersonalizationRuleListFilters'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globals'
import { useDuplicateRuleMutation } from '../../apollo/personalization/mutations/DuplicateRule.generated'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { MouseEvent } from 'react'

const styles = makeStyles({
    fileIcon: {
        '&:hover': {
            transform: `scale(1.1)`
        }
    }
})

const PersonalizationRuleList = (props: ListProps) => {
    const datagridClasses = datagridStyles()
    const translate = useTranslate()
    const [duplicateRuleMutation] = useDuplicateRuleMutation()
    const redirect = useRedirect()
    const notify = useNotify()
    const classes = styles()

    const handleDuplicateClick = (e: MouseEvent<SVGSVGElement>, id: string) => {
        e?.stopPropagation() // Stops rowClick from firing
        duplicateRuleMutation({
            variables: { id: id }
        })
            .then((result) => {
                redirect('edit', '/Rule', result.data?.duplicateRule?.id)
            })
            .catch((error) => notify(error.message, 'error'))
    }

    return (
        <List
            {...props}
            sort={{ field: 'label', order: 'DESC' }}
            perPage={DEFAULT_PER_PAGE}
            filters={PersonalizationRuleListFilter}
            bulkActionButtons={<BulkActionButtons />}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
        >
            <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                <TextField source="label" label={translate('manager.resources.rule.label')} />
                <FunctionField
                    source="active"
                    label={translate('manager.resources.general.status')}
                    render={(record?: Record | undefined) => (
                        <DatagridPublishedColumnContent record={record} />
                    )}
                />
                <FunctionField
                    label={translate('manager.resources.general.actions')}
                    render={(record?: Record | undefined) =>
                        record?.id && (
                            <Tooltip title={translate('manager.resources.general.duplicate')}>
                                <FileCopyIcon
                                    className={classes.fileIcon}
                                    onClick={(e: MouseEvent<SVGSVGElement>) =>
                                        handleDuplicateClick(e, record.id.toString())
                                    }
                                />
                            </Tooltip>
                        )
                    }
                />
            </Datagrid>
        </List>
    )
}
export default PersonalizationRuleList
